import React, { useState, useEffect } from "react";
import "./InvoiceGuide.css";
import stepone from "../../assets/step-1.jpg";
import steptwo from "../../assets/step-2.jpg";
import { Link, useNavigate } from "react-router-dom";
import Header from "../Header/Header";
import Footer2 from "../Footer/Footer1";
import { useDispatch } from "react-redux";
import { newInvoiceTableData } from "../../InvoiceRedux/action/action";
import { Helmet } from "react-helmet";

const InvoiceGuide = () => {
  const dispatch = useDispatch();
  let newNumberList;
  const navigate = useNavigate();
  const [id, setId] = useState(increaseNumber());
  const [number, setNumber] = useState(newNumbers());

  function increaseNumber() {
    return JSON.parse(localStorage.getItem("ids")) || 0;
  }

  function newNumbers() {
    return (newNumberList =
      JSON.parse(localStorage.getItem("idCollection")) || []);
  }

  const CreateInvoice = () => {
    const invoiceitems = JSON.parse(localStorage.getItem(`adminProfile${id}`));
    let id2;
    if (invoiceitems?.invoiceGenerator?.billTo?.value === "") {
      id2 = id;
      setId(id2);
      localStorage.setItem("ids", id2);
    } else {
      id2 = id + 1;
      setId(id2);
      localStorage.setItem("ids", id2);
    }

    let newInvoiceArray = [...newNumberList, id2];
    localStorage.setItem("idCollection", JSON.stringify(newInvoiceArray));
    setNumber(newInvoiceArray);
    dispatch(newInvoiceTableData());
    navigate(`/invoice-downloader/${id2}`);
  };

  const signUp = () => {
    localStorage.removeItem("auth");
    localStorage.removeItem("token");
    navigate("/signup");
  };

  const [seo, setSeo] = useState([]);
  useEffect(() => {
    const seoApi = async () => {
      await fetch(`${process.env.REACT_APP_BASE_URL}api/util/get-meta`, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: JSON.parse(localStorage.getItem("token")),
        },
      })
        .then((res) => res.json())
        .then((res) => {
          setSeo(res?.data);
        });
    };
    seoApi();
  }, []);

  return (
    <>
      <div className="flex-wrapper-invoice-guide">
      {seo?.map((item) => {
        if (item?.pagename === "Invoice Guide") {
          return (
            <Helmet>
              <title>Invoice Guide | Easy Invoice</title>
              <meta name="twitter:description" content={item?.description} />
              <meta name="twitter:title" content={item?.title} />
              <meta name="twitter:image" content={item?.image} />
              <script
                crossorigin="anonymous"
                async
              >
                {`${item?.headerScript}`}
              </script>
              <script
               
                crossorigin="anonymous"
                async
              >
                {`${item?.footerScript}`}
              </script>
            </Helmet>)
        }
      })}
        {/* <Helmet>
          <title>Invoice Guide | Easy Invoice</title>
          <meta name="description" content={seo[4]?.content} />
        </Helmet> */}
        <Header></Header>
        <section className="invoice-guide" style={{ marginTop: "6rem" }}>
          <div className="container text-center mt-4">
            <div className="row gx-0 mt-4">
              <div className="col-sm-12">
                <div className="heading">
                  <div className="d-flex justify-content-center align-items-center">
                    <h1>
                      <span className="heading-text">Invoice </span>{" "}
                      <span className="heading-text2">Guide</span>
                    </h1>
                  </div>
                  <p className="extra-font">
                    Create & download invoices for free, If you want to save
                    your invoice data on cloud just create your account.
                  </p>
                  {/* <Link to="/invoice-downloader"> */}
                  <button
                    className="btn btn-primary button1"
                    onClick={CreateInvoice}
                  >
                    Create invoice now
                  </button>
                  {/* </Link> */}
                </div>
              </div>

              <div className="step-1 d-flex flex-column justify-content-center">
                <div>
                  <h1 className="step-text">Step 1</h1>
                </div>
                <div>
                  <p className="para-text">
                    To start the invoice generate process, you will need to
                    create an account.{" "}
                    <span onClick={signUp} className="link-primary signup-text">
                      Sign Up!
                    </span>
                  </p>
                </div>
                <div>
                  <img className="w-50 " src={stepone} alt="Step-1" />
                </div>
              </div>

              <div className="step-2 mt-5 d-flex flex-column justify-content-center">
                <div>
                  <h1 className="step-text">Step 2</h1>
                </div>
                <div>
                  <p className="para-text">
                    Creating an invoice using the Easy Invoice Generator is
                    quick and easy. Simply fill out the fields on the form, and
                    choose to either send the invoice directly or download it to
                    send later.
                  </p>
                </div>
                <div className=" ">
                  <img className="w-50 " src={steptwo} alt="Step-1" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer2></Footer2>
      </div>
    </>
  );
};
export default InvoiceGuide;
