import React, { useState, useEffect } from "react";
import "./Trash.css";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { TablePagination } from "@mui/material";
import { toast } from "react-toastify";
import restoreIcon from "../../assets/Restore.svg";
import DeleteIcon from "../../assets/DeleteIcon.svg";
import SearchComponents from "./SearchComponent";
import moment from "moment";
import { debounce } from "lodash";
import ModalBootrap from "react-bootstrap/Modal";


const Trash = (props) => {
  const [loading, setLoading] = useState(false);
  const [input, setInput] = useState();
  const [trying, setTrying] = useState();
  const [count, setCount] = useState(0);
  const [dataUpdate, setDataUpdate] = useState(0);
  const [restoreLoading, setRestoreloading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [restoreError, setRestoreError]=useState(false);
  const [searchInput, setSearchInput] = useState({
    input: "",
    month: "",
  });
  const [controller, setController] = useState({
    currentpage: 0,
    rowsPerPage: 10,
  });
  const [hideCrossIcon , setHideCrossIcon] = useState(false);

  const handlePageChange = (event, newPage) => {
    setController({
      ...controller,
      currentpage: newPage,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setController({
      ...controller,
      rowsPerPage: parseInt(+event.target.value, 10),
      currentpage: 0,
    });
  };
  const datasave = async () => {
    setLoading(true);
    await fetch(
      `${process.env.REACT_APP_BASE_URL}api/invoice/get-trash-invoice?offset=${controller.currentpage}&limit=${controller.rowsPerPage}`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: localStorage.getItem(`token`).replaceAll('"', ""),
        },
      }
    )
      .then((response) => response.json())
      .then((response) => {
        setDeleteLoading(false);
        setRestoreloading(false);
        setLoading(false);
        const newInput = response?.data?.map((val) => {
          return { ...val, restoreLoader: false, deleteLoader: false };
        });
        setInput(response);
        const createdData = JSON.parse(localStorage.getItem("created"));
        const sentData = JSON.parse(localStorage.getItem("sent"));

        props.notificationFunction(createdData, response?.total, sentData);
        props.trashChild(response);
        setCount(response?.total ? response?.total : 0);
      })
      .catch((error) => {
        setLoading(true);
        // console.log(error, "joih");
      });
  };
  useEffect(() => {
    if (searchInput?.input || searchInput?.month) {
      searchInputApi(searchInput?.input, searchInput?.month);
    } else {
      datasave();
    }
  }, [controller, dataUpdate]);

  const restoreInvoice = async (id, loggedUser) => {
    setRestoreloading(true);

    await fetch(
      `${process.env.REACT_APP_BASE_URL}api/invoice/restore-invoice`,
      {
        method: "PUT",
        headers: {
          "content-type": "application/json",
          Authorization: localStorage.getItem(`token`).replaceAll('"', ""),
        },

        body: JSON.stringify({
          id: id,
          userId:loggedUser
        }),
      }
    )
      .then((response) => response.json())
      .then((response) => {
        if (response?.status === true) {
          const createdData = JSON.parse(localStorage.getItem("created"));

          const newCreatedData = createdData + 1;
          localStorage.setItem("created", JSON.stringify(newCreatedData));

          setDataUpdate(dataUpdate + 1);
          setRestoreloading(false);
          toast.success(response.message);
        } else {
          setRestoreloading(false);
          throw response.message;
        }

        // debugger;
        // setMessage(response.error);

        // localStorage.setItem('auth', JSON.stringify(response.data[0]))
        // window.location.reload();
      })
      .catch((error) => {
        // console.log(error, "joih");
if(error==="invoice id already in use"){
  setRestoreError(true)
}else{
        toast.error(error);

}

      });
  };

  const deleteTrashInvoice = async (id, loggedUser) => {
    const sure = window.confirm(
      "Are you sure you want to permanently delete your invoices?"
    );

    sure === true && setDeleteLoading(true);
    sure === true &&
      (await fetch(
        `${process.env.REACT_APP_BASE_URL}api/invoice/delete-invoice`,
        {
          method: "DELETE",
          headers: {
            "content-type": "application/json",
            Authorization: localStorage.getItem(`token`).replaceAll('"', ""),
          },

          body: JSON.stringify({
            invoiceId: id,
            userId: loggedUser,
          }),
        }
      )
        .then((response) => response.json())
        .then((response) => {
          if (response.success === true) {
            setDataUpdate(dataUpdate + 1);
            setDeleteLoading(false);
            toast.success(response?.message);
          } else {
            setDeleteLoading(false);
            throw response?.message;
          }
        })
        .catch((error) => {
          // console.log(error, "joih");
          toast.error(error);
        }));
  };

  const handleSearchInput = debounce((text) => {
    let input = text;
    let month = searchInput?.month;
    setSearchInput({ ...searchInput, input: text });
    setController({...controller, currentpage: 0, rowsPerPage: 10})
    searchInputApi(input, month);
  }, 500);

  async function searchInputApi(input, month) {
    if (month?.length || input.length > 0) {
      setLoading(true);
      await fetch(
        `${process.env.REACT_APP_BASE_URL}api/invoice/search-trash-invoice?offset=${controller.currentpage}&limit=${controller.rowsPerPage}`,
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
            Authorization: localStorage.getItem(`token`).replaceAll('"', ""),
          },
          body: JSON.stringify({
            input: input,
            month: month,
          }),
        }
      )
        .then((response) => response.json())
        .then((response) => {
          if (!response?.success) {
            throw Error(response.message);
          }
          if (response?.success === true) {
            setInput(response);
            setCount(response?.total ? response?.total : 0);
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          // console.log(error, "thisIsError");
        });
    } else {
      datasave();
    }
  }

  const handleSelectDates = (date) => {
    var currMonthName = moment(date).format("MMM");
    var prevYearName = moment(date).format("YYYY");
    let changesInYear = currMonthName + "," + prevYearName;
    setSearchInput({ ...searchInput, month: changesInYear });
  };

  useEffect(() => {
    if(searchInput.month <= 0){
      setHideCrossIcon(true)
    }else{
      setHideCrossIcon(false)
    }
  } , [searchInput.month])
  

  const fetchSearchData = () => {
    let input = searchInput?.input;
    let month = searchInput?.month;
    setController({...controller, currentpage: 0, rowsPerPage: 10})
    searchInputApi(input, month);
  };

  const handleClearFilter = () => {
    let input = searchInput?.input;
    let month = "";
    setSearchInput({ month: "" , input : input });
    searchInputApi(input, month);
  };

  return (
    <>

<ModalBootrap
					show={restoreError}
					aria-labelledby="contained-modal-title-vcenter"
					centered
				>
					<ModalBootrap.Body className="text-center">
						<span style={{ color: "red", fontWeight: "700" }}>Warning:</span>{" "}
						Invoice Id Already Used. If you want to restore this invoice Please change Invoice Id in your created invoice.
						
						<div className="d-flex justify-content-end">
							<button className="btn btn-primary" onClick={()=>setRestoreError(false)}>
								Close
							</button>
						</div>
					</ModalBootrap.Body>
				</ModalBootrap>


      <SearchComponents
        handleSearchInput={(e) => handleSearchInput(e)}
        handleSelectDates={(date) => handleSelectDates(date)}
        searchInput={searchInput}
        fetchSearchData={fetchSearchData}
        handleClearFilter={handleClearFilter}
        hideCrossIcon = {hideCrossIcon}
      />
      <div className="trash-section">
        <p className="below-tab-fonts">Trash</p>

        <div className="table-responsive ">
          <table className="table table-striped table-hover">
            {restoreLoading === true && (
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            )}

            {deleteLoading === true && (
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            )}

            {input?.data?.length > 0 ? (
              <>
                <thead className="table-head">
                  <tr>
                    <th>#Invoice</th>
                    <th>Recepient</th>
                    <th>Bill&nbsp;Date</th>
                    <th>Amount</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <>
                  <tbody>
                    {input?.data?.map((val, index) => {
                      return (
                        <>
                          <tr>
                            <td>{val?.invoiceId?.value}</td>
                            <td>{val?.billTo?.value} </td>
                            <td>{val?.date?.value}</td>
                            <td>
                              {val?.symbol} {val?.total?.value}
                            </td>
                            <td>
                              <span className="d-flex align-items-center">
                                {" "}
                                <img
                                  src={restoreIcon}
                                  alt="restore"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Restore"
                                  style={{
                                    cursor: "pointer",
                                    height: "14px",
                                    width: "14px",
                                  }}
                                  className="editinTrashClass"
                                  onClick={() => restoreInvoice(val?._id, val?.loggedUser)}
                                ></img>
                                <img
                                  src={DeleteIcon}
                                  alt="delete"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Delete"
                                  style={{
                                    cursor: "pointer",
                                    height: "14px",
                                    width: "14px",
                                  }}
                                  className="deleteinTrashClass"
                                  onClick={() =>
                                    deleteTrashInvoice(
                                      val?._id,
                                      val?.loggedUser
                                    )
                                  }
                                ></img>
                              </span>{" "}
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </>
              </>
            ) : (
              <>
                <thead className="table-head">
                  <tr>
                    <th>#Invoice</th>
                    {/* <th >Email</th> */}
                    <th>Recepient</th>
                    <th>Bill&nbsp;Date</th>
                    {/* <th>Sent&nbsp;Date</th> */}
                    <th>Amount</th>
                    <th>Action</th>
                    {/* <th>Bill&nbsp;To</th> */}
                  </tr>
                </thead>
                <>
                  <tbody>
                    <tr>
                      <td>&nbsp;</td>
                      {/* <td>{val?.recieversMail}</td> */}
                      <td>&nbsp;</td>
                      {/* <td>{val?.date} </td> */}
                      <td style={{ textAlign: "center" }}>
                        No Invoices Found{" "}
                      </td>
                      <td>&nbsp;</td>
                      <td>&nbsp; </td>
                    </tr>
                  </tbody>
                </>
              </>
            )}

            {loading && (
              <>
                <tbody></tbody>
                <tfoot>
                  <tr>
                    <td colSpan="3"></td>
                  </tr>
                </tfoot>

                <Backdrop
                  sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
              </>
            )}
          </table>

          <hr></hr>
          <TablePagination
            component="div"
            onPageChange={handlePageChange}
            page={controller?.currentpage}
            count={count}
            rowsPerPage={controller?.rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </>
  );
};

export default Trash;