import React, { useState, useEffect } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import Footer from "../Footer/Footer";

import Header from "../Header/Header";
import Footer2 from "../Footer/Footer1";

import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import DeleteIcon from "@mui/icons-material/Delete";
import "./History.css";
import {
  logoUpload,
  newInvoiceTableData,
} from "../../InvoiceRedux/action/action";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { HiDownload } from "react-icons/hi";
import { invoiceDataAction } from "../../InvoiceRedux/action/action";
import { debounce } from "lodash";

const History = (props) => {
  // console.log(props, "dekho");



  const dispatch = useDispatch();
  const [showFilter, setShowFilter] = useState(false);
  const [filterData, setFilterData] = useState();
  const [searchState, setSearchState] = useState("");
  let newNumberList;
  const [updateTotalInvoices, setUpdateTotalInvoices] = useState();
  const navigate = useNavigate();
  const [id, setId] = useState(increaseNumber());
  const [number, setNumber] = useState(newNumbers());
  const [idstore, setIdStore] = useState();
  const [historyData, setHistoryData] = useState(1);


  console.log(showFilter, "kuy")

  useEffect(() => {
    //   if(JSON.parse(localStorage.getItem("idCollection"))  ){

    //   }else{
    //     let firstId = JSON.parse(localStorage.getItem("ids"))
    // let newId=[firstId]
    //     localStorage.setItem("idCollection" ,newId )
    //   }
  }, [])



  useEffect(() => {
    let newNumberLists = JSON.parse(localStorage.getItem("idCollection")) || [];
    let lastID = newNumberLists?.length <= 0 ? 0 : newNumberLists?.length - 1
    // console.log(lastID , "lastID");
    let lastCreatedInvoiceID = newNumberLists[lastID];
    let ids = JSON.parse(localStorage.getItem("ids"))
    let currentAdminProfile = JSON.parse(
      localStorage.getItem(`adminProfile${ids}`)
    );


    if (currentAdminProfile?.invoiceGenerator?.billTo?.value !== "") {


      let newArray = JSON.parse(localStorage.getItem("idCollection"))
      if (newArray?.length === 0) {
        let addNewId = [ids]
        localStorage.setItem("idCollection", JSON.stringify(addNewId))
      }


    } else {

      // let newArray = JSON.parse(localStorage.getItem("idCollection"))
      // if (newArray?.length === 0) {
      //   let addNewId = [ids]
      //   localStorage.setItem("idCollection", JSON.stringify(addNewId))
      // }

      newNumberLists.pop();
      localStorage.removeItem(`adminProfile${lastCreatedInvoiceID}`);
      localStorage.setItem("idCollection", JSON.stringify(newNumberLists));
      let idcollectionAfterpop =
        JSON.parse(localStorage.getItem("idCollection")) || [];
      const index =
        idcollectionAfterpop.length <= 0 ? 0 : idcollectionAfterpop.length - 1;
      const indexvalue = idcollectionAfterpop[index] || 1;
      localStorage.setItem("ids", JSON.stringify(indexvalue));
      return
    }
    dispatch(newInvoiceTableData());
  }, []);

  useEffect(() => {
    let totalInvoices = JSON.parse(localStorage.getItem("idCollection")) || 0;
    localStorage.setItem(
      "totalInvoices",
      JSON.stringify(totalInvoices?.length ? totalInvoices?.length : 0)
    );
  }, [updateTotalInvoices]);

  function newNumbers() {
    return (newNumberList =
      JSON.parse(localStorage.getItem("idCollection")) || []);
  }

  function increaseNumber() {
    return JSON.parse(localStorage.getItem("ids"))
      ? JSON.parse(localStorage.getItem("ids"))
      : 0;
  }

  function listOfInvoice() {
    let updateddeletearray = JSON.parse(localStorage.getItem("idCollection"));

    let idcollectionAfterpop =
      JSON.parse(localStorage.getItem("idCollection")) || [];

    const idcollectionLength =
      idcollectionAfterpop.length <= 0 ? 0 : idcollectionAfterpop.length - 1;

    const y = idcollectionAfterpop[idcollectionLength] || 1;
    localStorage.setItem("ids", JSON.stringify(y));

    if (updateddeletearray?.length === 0) {
      setId(0);
    }
    newNumbers();
    const adminProfileList = newNumberList.map((item) => {
      return JSON.parse(localStorage.getItem(`adminProfile${item}`));
    });
    return setIdStore(adminProfileList);
  }
  useEffect(() => {
    listOfInvoice();
  }, []);

  const newInvoice = () => {
    const invoiceitems = JSON.parse(localStorage.getItem(`adminProfile${id}`));
    let id2;
    if (invoiceitems?.invoiceGenerator?.billTo?.value === "") {
      id2 = id;
      setId(id2);
      localStorage.setItem("ids", id2);
    } else {
      id2 = id + 1;
      setId(id2);
      localStorage.setItem("ids", id2);
    }
    let newNumber = [...newNumberList, id2];
    setNumber(newNumber);
    dispatch(newInvoiceTableData());
    localStorage.setItem("idCollection", JSON.stringify(newNumber));
    navigate(`/invoice-downloader/${id2}`);
  };

  const deleteInvoice = async (id) => {

    const sure = window.confirm(
      "Are you sure you want to delete this invoice?"
    );
    if (sure) {


      localStorage.removeItem(`adminProfile${id}`);
      let newInvoiceItem = JSON.parse(localStorage.getItem("idCollection"));
      let newinvoiceFilter = newInvoiceItem.filter((item) => item != id);
      localStorage.setItem("idCollection", JSON.stringify(newinvoiceFilter));
      setUpdateTotalInvoices(updateTotalInvoices + 1);
      listOfInvoice();
      let totalInviocesdata = JSON.parse(localStorage.getItem('totalInvoices')) - 1
      localStorage.setItem('totalInvoices', JSON.stringify(totalInviocesdata))


    }
  };

  const deleteSearchedInvoice = (id) => {

    const sure = window.confirm(
      "Are you sure you want to delete this invoice?"
    );
    if (sure) {

      const deleteData = filterData?.filter((val, index) => {
        return id !== val?.invoiceGenerator?.id
      })

      setFilterData(deleteData)
      localStorage.removeItem(`adminProfile${id}`);
      let newInvoiceItem = JSON.parse(localStorage.getItem("idCollection"));
      let newinvoiceFilter = newInvoiceItem.filter((item) => item != id);
      localStorage.setItem("idCollection", JSON.stringify(newinvoiceFilter));
      setUpdateTotalInvoices(updateTotalInvoices + 1);
      listOfInvoice();
      let totalInviocesdata = JSON.parse(localStorage.getItem('totalInvoices')) - 1
      localStorage.setItem('totalInvoices', JSON.stringify(totalInviocesdata))


    }


  }


  // const searchInputHandler = debounce((text) => {
  //   let input = text;
  //   let month = searchInput?.month;
  //   setSearchInput({ ...searchInput, input: input });
  //   setController({ ...controller, currentpage: 0, rowsPerPage: 10 })
  //   searchInputApi(input, month);
  // }, 500);

  let show = debounce((text) => {
    // console.log(text, "chnara");
let inputValue=text
    setSearchState(inputValue);
    if (inputValue !== "") {
      setShowFilter(true);
    } else {
      setShowFilter(false);
    }
    searchApi(inputValue)
    // console.log(inputValue==="", "ijor")
  }, 500)



  // useEffect(() => {
  //   searchApi();
  // }, [searchState, idstore]);

  const searchApi = async (s) => {
    const Idstore = idstore;
    const searchTerm = s;
    searchTerm === "" && setShowFilter(false)
    searchTerm !== "" &&
      (await fetch(
        `${process.env.REACT_APP_BASE_URL}api/invoice/search-invoice`,
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
            // Authorization: localStorage.getItem(`token`).replaceAll('"', ""),
          },
          body: JSON.stringify({ searchTerm, Idstore }),
        }
      )
        .then((response) => response.json())
        .then((result) => {
          setFilterData(result?.data);

          // result?.success && setShowFilter(true);
        })

        .catch((error) => {
          console.log(error, "error");
        }));
  };

  const deleteAllInvoice = () => {
    const sure = window.confirm(
      "Are you sure you want to permanently delete all of your invoices?"
    );
    if (sure) {
      newNumberList.map((item) => {
        return localStorage.removeItem(`adminProfile${item}`);
      });
      localStorage.removeItem(`idCollection`);
      localStorage.removeItem(`ids`);
      setId(0);
      setUpdateTotalInvoices(updateTotalInvoices + 1);
      return setIdStore([]);
    }
  };

  const csvapi = async () => {
    const Idstore = idstore;
    const data = {
      Idstore: Idstore,
    };
    await fetch(
      `${process.env.REACT_APP_BASE_URL}api/invoice/get-invoice-csv`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(data),
      }
    )
      .then((res) => res.text())
      .then((res) => {
        const blob = new Blob(["\ufeff", res]);

        let a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        a.download = `report_${new Date().getTime()}.csv`;
        a.click();
      })

      .catch((error) => {
        // console.log(error, "errorDDDDDDDDDDDDDDDD");
      });
  };

  const downloadInvoice = (id) => {
    const apiSelector = JSON.parse(localStorage.getItem(`adminProfile${id}`));
    let invoiceDownloadNumber = apiSelector?.invoiceGenerator?.invoiceId?.value

    localStorage.setItem("pdfNum", invoiceDownloadNumber);
    const color = apiSelector?.selectedColor;
    const invoiceItems = apiSelector?.selector?.invoiceItems;
    const balanceDue = apiSelector?.invoiceGenerator?.balance;
    const discountAmount = apiSelector?.discountValueTotal;
    const discountPercent = apiSelector?.invoiceGenerator?.discount;
    const hideDiscountBox = apiSelector?.hideDiscountBox;
    const hideShipBox = apiSelector?.hideShipBox;
    const isDiscountPercent = apiSelector?.discountState;
    const isTaxPercent = apiSelector?.taxState;
    const logoLink = apiSelector?.selector?.logoLink;
    const symbol = apiSelector?.countrySymbol;
    const taxAmount = apiSelector?.taxvalueTotal;
    const taxPercent = apiSelector?.invoiceGenerator?.tax;
    const taxStatus = apiSelector?.taxStatus;

    const invoiceGenerator = apiSelector?.invoiceGenerator;
    const datas = {
      invoiceItems: invoiceItems,
      ...invoiceGenerator,
      balanceDue: balanceDue,
      color: color,
      discountAmount: discountAmount,
      discountPercent: discountPercent,
      hideDiscountBox: hideDiscountBox,
      hideShipBox: hideShipBox,
      isDiscountPercent: isDiscountPercent,
      isTaxPercent: isTaxPercent,
      logoLink: logoLink,
      symbol: symbol,
      taxAmount: taxAmount,
      taxPercent: taxPercent,
      taxStatus: taxStatus,
    };

    dispatch(
      invoiceDataAction(
        datas,
        localStorage.setItem("document_type", JSON.stringify("pdfContent"))
      )
    );
  };

  return (
    <>
      <div className="flex-wrapper-history">
        <Helmet>
          <title>History | Easy Invoice</title>
          <meta name="description" content="" />
        </Helmet>
        <Header></Header>
        <div className="container history mt-4">
          <div
            className="row col-md-10 offset-md-1 all p-4 "
            style={{ marginTop: "5rem" }}
          >
            <h1 className="mb-4">History</h1>
            <p className="">
              We automatically save invoices that you created recently to your
              device. This is useful when you need to quickly make an edit to an
              invoice.
            </p>
            <div className="d-flex flex-wrap justify-content-between mb-3">
              <div className="form-group has-search searchDiv mt-2 ">
                <SearchRoundedIcon className="src" />
                <input
                  type="search"
                  id="form1"
                  className="form-control"
                  style={{ paddingLeft: "2.7rem" }}
                  aria-label="Search"
                  // value={searchState}
                  onChange={(e) => show(e.target.value)}
                />
              </div>
              <div className="d-flex mt-2">
                <div className="col-sm-6 ">
                  <button
                    className="btn btn-default me-3 "
                    onClick={csvapi}
                    disabled={idstore?.length === 0}
                  >
                    <svg
                      class="svg-inline--fa fa-arrow-to-bottom"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="arrow-to-bottom"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 384 512"
                      data-fa-i2svg=""
                    >
                      <path
                        fill="currentColor"
                        d="M297.4 169.4L224 242.8V64c0-17.69-14.31-32-32-32S160 46.31 160 64v178.8L86.63 169.4C80.38 163.1 72.19 160 64 160S47.63 163.1 41.38 169.4c-12.5 12.5-12.5 32.75 0 45.25l128 128c12.5 12.5 32.75 12.5 45.25 0l128-128c12.5-12.5 12.5-32.75 0-45.25S309.9 156.9 297.4 169.4zM352 416H32c-17.69 0-32 14.31-32 32s14.31 32 32 32h320c17.69 0 32-14.31 32-32S369.7 416 352 416z"
                      ></path>
                    </svg>
                    Export
                  </button>
                </div>
                <div className="col-sm-6">
                  <button
                    className="btn px-1"
                    style={{ backgroundColor: "#2155CD", color: "#fff" }}
                    onClick={newInvoice}
                  >
                    <span>New invoice</span>
                  </button>
                </div>
              </div>
            </div>

            {idstore?.length === 0 ? (
              <div class="empty-message">No invoices found.</div>
            ) : (
              ""
            )}

            {!showFilter
              ? idstore?.map((item, id) => {
                return (
                  <div
                    className="data col-sm-4  m-2  "
                    style={{ width: "10rem", height: "8rem", margin: "5px" }}
                  >
                    <span className="draft">{item?.draftState}</span>
                    <div className="">
                      <div className="d-flex justify-content-end">
                        <HiDownload
                          className=" me-1"
                          style={{
                            fontSize: "20px",
                            marginTop: "3px",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            downloadInvoice(item?.invoiceGenerator?.id)
                          }
                        />
                        {/* <button className="btn btn-link trash btnchange"> */}
                        <DeleteIcon
                          className="btncg"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            deleteInvoice(item?.invoiceGenerator?.id)
                          }
                        />
                        {/* </button> */}
                      </div>

                      <Link
                        to={`/invoice-downloader/${item?.invoiceGenerator?.id}`}
                        style={{ textDecoration: "none", color: "black" }}
                        state={historyData}
                      >
                        <ul
                          className="list-inline  items px-4"
                          style={{ paddingBottom: "5px" }}
                        >
                          <li className="pb-1 " style={{ fontSize: "20px" }}>
                            {item?.invoiceGenerator?.amountPaid?.value}
                          </li>
                          <li style={{ fontSize: "13px" }}>
                            {item?.invoiceGenerator?.billTo?.value}
                          </li>

                          <li
                            className=""
                            style={{ fontSize: "11px", paddingTop: "2px" }}
                          >
                            {item?.invoiceGenerator?.date?.value}
                          </li>
                        </ul>
                      </Link>
                    </div>
                  </div>
                );
              })
              : filterData?.map((item, id) => {
                return (
                  <div
                    className="data col-sm-4  m-2  "
                    style={{ width: "10rem", height: "8rem", margin: "5px" }}
                  >
                    <span className="draft">{item?.draftState}</span>
                    <div className="">
                      <div className="d-flex justify-content-end">
                        <HiDownload
                          className=" me-1"
                          style={{
                            fontSize: "20px",
                            marginTop: "3px",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            downloadInvoice(item?.invoiceGenerator?.id)
                          }
                        />
                        {/* <button className="btn btn-link trash btnchange"> */}
                        <DeleteIcon
                          className="btncg"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            deleteSearchedInvoice(item?.invoiceGenerator?.id)
                          }
                        />
                        {/* </button> */}
                      </div>

                      <Link
                        to={`/invoice-downloader/${item?.invoiceGenerator?.id}`}
                        style={{ textDecoration: "none", color: "black" }}
                        state={historyData}
                      >
                        <ul
                          className="list-inline  items px-4"
                          style={{ paddingBottom: "5px" }}
                        >
                          <li className="pb-1 " style={{ fontSize: "20px" }}>
                            {item?.invoiceGenerator?.amountPaid?.value}
                          </li>
                          <li style={{ fontSize: "13px" }}>
                            {item?.invoiceGenerator?.billTo?.value}
                          </li>

                          <li
                            className=""
                            style={{ fontSize: "11px", paddingTop: "2px" }}
                          >
                            {item?.invoiceGenerator?.date?.value}
                          </li>
                        </ul>
                      </Link>
                    </div>
                  </div>
                );
              })}

            <hr className="mt-4" />
            <p style={{ color: "#c00", fontSize: "1rem" }} className="p-second">
              <ReportProblemIcon
                className="pb-1 "
                style={{ width: "1.2rem", marginRight: "2px" }}
              />
              If you did not use the Send Invoice option then these invoices are
              stored on your device only. Clearing your browser's history will
              erase these invoices. If you use the Download Invoice option then
              we recommend hanging on to a copy of each invoice you generate.
            </p>

            {idstore?.length === 0 ? (
              ""
            ) : (
              <div className="d-flex justify-content-center mt-2 red">
                <inpute
                  type="button"
                  className="btn btn-danger"
                  style={{ fontSize: ".82rem" }}
                  onClick={() => deleteAllInvoice()}
                >
                  Erase Everything
                </inpute>
              </div>
            )}
          </div>
        </div>
        <Footer2></Footer2>
      </div>
    </>
  );
};

export default History;
