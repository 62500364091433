import React from "react";
import { useState, useEffect } from "react";
import "./Documents.css";
import { TablePagination } from "@mui/material";
import loader from "../../assets/Loading.gif";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { TbChevronDown, TbSend } from "react-icons/tb";
import { MdEdit } from "react-icons/md";
import Modal from "react-bootstrap/Modal";
import makeAnimated from "react-select/animated";
import Select from "react-select";
import { toast } from "react-toastify";
import EditIcon from "../../assets/EditIcon.svg";
import DeleteIcon from "../../assets/DeleteIcon.svg";
import SendIcon from "../../assets/SendIcon.svg";
import SearchComponents from "./SearchComponent";
import moment from "moment";
import { debounce } from "lodash";

const SentDocument = (props) => {
  const [loading, setLoading] = useState(false);
  const animatedComponents = makeAnimated();
  const [newState, setNewState] = useState([]);
  const [show, setShow] = useState(false);
  const [input, setInput] = useState();
  const [count, setCount] = useState(0);
  const [modalstate, setModalstate] = useState(false);
  const [deleteState, setDeleteState] = useState(0);
  const [updateState, setUpdateState] = useState(0);
  const [searchInput, setSearchInput] = useState({
    input: "",
    month: "",
  });
  const [controller, setController] = useState({
    currentpage: 0,
    rowsPerPage: 10,
  });

  const [formvalue, setFormvalue] = useState({
    subject: "",
    receiverEmail: "",
    invoiceId: [],
    notes: "",
  });

  const [hideCrossIcon , setHideCrossIcon] = useState(false);

  const handleClose = () => {
    setNewState([]);
    setShow(false);
  };

  const handleShow = (id, labelId) => {
    setNewState([{ value: id, label: labelId }]);
    setShow(true);
  };

  let name1, val;
  const save = (e) => {
    setFormvalue({ ...formvalue, invoiceId: [newState?.[0]?.value] });
    name1 = e.target.name;
    val = e.target.value;
    setFormvalue({ ...formvalue, [name1]: val });
  };

  useEffect(() => {
    setFormvalue({ ...formvalue, invoiceId: [newState?.[0]?.value] });
  }, [newState]);

  const datasave = async () => {
    setLoading(true);
    await fetch(
      `${process.env.REACT_APP_BASE_URL}api/invoice/sent-invoices?offset=${controller.currentpage}&limit=${controller.rowsPerPage}`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: localStorage.getItem(`token`).replaceAll('"', ""),
        },
      }
    )
      .then((response) => response.json())
      .then((response) => {
        const createdGet = JSON.parse(localStorage.getItem("created"));
        const trashGet = JSON.parse(localStorage.getItem("trash"));
        props?.notificationFunction(
          createdGet,
          trashGet,
          response?.totalSentInvoice ? response?.totalSentInvoice : 0
        );



        props?.sentChild(response);
        setInput(response?.data);
        setCount(response?.totalSentInvoice ? response?.totalSentInvoice : 0);

        setLoading(false);
      })
      .catch((error) => {
        // console.log(error, "joih");
        setLoading(false);
      });
  };

  useEffect(() => {
    // if (searchInput?.input || searchInput?.month) {
      // searchInputApi(searchInput?.input, searchInput?.month);
      // setController({...controller, currentpage:0, rowsPerPage:10})
    // } else {
      datasave();
    // }
  }, [deleteState, updateState]);


  // useEffect(() => {
  //   if (searchInput?.input || searchInput?.month) {
  //     searchInputApi(searchInput?.input, searchInput?.month);
  //     setController({...controller, currentpage:0, rowsPerPage:10})
  //   } else {
  //     datasave();
  //   }
  // }, [controller]);

  const handlePageChange = (event, newPage) => {
    setController({
      ...controller,
      currentpage: newPage,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setController({
      ...controller,
      rowsPerPage: parseInt(event.target.value, 10),
      currentpage: 0,
    });
  };

  const moadalDataPost = async (e) => {
    e.preventDefault();

    const { subject, receiverEmail, invoiceId, notes } = formvalue;

    if (receiverEmail.length <= 2) {
      toast.error("Please Enter Your Email");
    }
    receiverEmail.length >= 3 && setShow(false);
    receiverEmail.length >= 3 &&
      (await fetch(
        `${process.env.REACT_APP_BASE_URL}api/invoice/send-invoice`,
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
            Authorization: localStorage.getItem(`token`).replaceAll('"', ""),
          },

          body: JSON.stringify({
            subject,
            receiverEmail,
            invoiceId,
            notes,
          }),
        }
      )
        .then((response) => response.json())
        .then((response) => {
          if (response.success === true) {
            setUpdateState(updateState + 1);
            const createdGet = JSON.parse(localStorage.getItem("created"));
            const trashGet = JSON.parse(localStorage.getItem("trash"));
            const sentGet = JSON.parse(localStorage.getItem("sent"));
            const newSentData = sentGet + 1;
            localStorage.setItem("sent", JSON.stringify(newSentData));
            props?.notificationFunction(createdGet, trashGet, newSentData);
            toast.success(response.message);
          } else {
            throw response.message;
          }

          // debugger;
          // setMessage(response.error);

          // localStorage.setItem('auth', JSON.stringify(response.data[0]))
          // window.location.reload();
        })
        .catch((error) => {
          // console.log(error, "joih");
          toast.error(error);
        }));
  };

  const deleteInvoice = async (invoiceId, index) => {
    const sure = window.confirm(
      "Are you sure you want to delete your invoices?"
    );
    sure === true &&
      (await fetch(
        `${process.env.REACT_APP_BASE_URL}api/invoice/soft-delete-invoice-sent`,
        {
          method: "PUT",
          headers: {
            "content-type": "application/json",
            Authorization: localStorage.getItem(`token`).replaceAll('"', ""),
          },

          body: JSON.stringify({
            id: invoiceId,
            // userId: userId,
          }),
        }
      )
        .then((response) => response.json())
        .then((response) => {
          if (response?.status === true) {
            setDeleteState(deleteState + 1);
            toast.success(response?.message);
          } else {
            throw response?.message;
          }
        })
        .catch((error) => {
          toast.error(error);
        }));
  };

  // const handleSearchInput = async (e) => {
  //   let input = e.target.value;
  //   let month = searchInput?.month;
  //   setSearchInput({ ...searchInput, input: e.target.value });
  //   searchInputApi(input, month);
  // };

  async function searchInputApi(input, month) {
    if (month?.length || input.length > 0) {
      setLoading(true);
      await fetch(
        `${process.env.REACT_APP_BASE_URL}api/invoice/filter-send-invoice?offset=${controller.currentpage}&limit=${controller.rowsPerPage}`,
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
            Authorization: localStorage.getItem(`token`).replaceAll('"', ""),
          },
          body: JSON.stringify({
            input: input,
            month: month,
          }),
        }
      )
        .then((response) => response.json())
        .then((response) => {
          if (!response?.success) {
            throw Error(response.message);
          }

          if (response?.success === true) {
            setInput(response?.data);
            setCount(response?.total ? response?.total : 0);
            // setController({...controller, currentpage:0})
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          // console.log(error, "thisIsError");
        });
    } else {
      datasave();
    }
  }

  const handleSelectDates = (date) => {
    var currMonthName = moment(date).format("MMM");
    var prevYearName = moment(date).format("YYYY");
    let changesInYear = currMonthName + "," + prevYearName;
    setSearchInput({ ...searchInput, month: changesInYear });
  };

  useEffect(() => {
    if(searchInput.month <= 0){
      setHideCrossIcon(true)
    }else{
      setHideCrossIcon(false)
    }
  } , [searchInput.month])

  const fetchSearchData = () => {
    let input = searchInput?.input;
    let month = searchInput?.month;
    setController({...controller, currentpage: 0, rowsPerPage: 10})
    searchInputApi(input, month);
  };

  const handleClearFilter = () => {
    let input = searchInput?.input;
    let month = "";
    setSearchInput({ ...searchInput, month: "" });
    searchInputApi(input, month);
  };

  const searchInputHandler = debounce((text) => {
    let input = text;
    let month = searchInput?.month;
    setSearchInput({ ...searchInput, input: input });
    setController({...controller, currentpage: 0, rowsPerPage: 10})
    searchInputApi(input, month);
  }, 500);

  return (
    <>
      <SearchComponents
        handleSearchInput={(e) => searchInputHandler(e)}
        handleSelectDates={(date) => handleSelectDates(date)}
        searchInput={searchInput}
        fetchSearchData={fetchSearchData}
        handleClearFilter={handleClearFilter}
        hideCrossIcon = {hideCrossIcon}
      />
      <div>
        <p className="below-tab-fonts">Sent</p>
        <div className="table-responsive ">
          <table className="table table-striped table-hover">
            {input?.length > 0 ? (
              <>
                {" "}
                <thead className="table-head">
                  <tr>
                    <th>#Invoice</th>
                    <th>Recipient</th>
                    <th>Email</th>
                    {/* <th>Due&nbsp;Date</th> */}
                    <th>Bill&nbsp;Date</th>
                    {/* <th>Payment&nbsp;Type</th> */}
                    <th>Amount</th>
                    {/* <th>Bill&nbsp;To</th> */}
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {input?.map((val, index) => {
                    return (
                      <>
                        <tr>
                          <td>{val?.invoiceDetail?.invoiceId?.value}</td>
                          <td>{val?.invoiceDetail?.billTo.value}</td>
                          <td>{val?.recieversMail}</td>
                          {/* <td>{val?.invoiceDetail?.dueDate?.value}</td> */}
                          <td>{val?.invoiceDetail?.date?.value} </td>
                          {/* <td>{val?.invoiceDetail?.paymentTerms?.value}</td> */}
                          <td>
                            {val?.invoiceDetail?.symbol}{" "}
                            {val?.invoiceDetail?.total?.value}
                          </td>
                          {/* <td>{val?.invoiceDetail?.billTo.value} </td> */}
                          <td>
                            <span className="d-flex align-items-center">
                              {" "}
                              <img
                                src={SendIcon}
                                data-toggle="tooltip"
                                data-placement="top"
                                alt="sent"
                                title="Send"
                                style={{
                                  cursor: "pointer",
                                  height: "14px",
                                  width: "14px",
                                }}
                                className="sendIconClass"
                                onClick={() =>
                                  handleShow(
                                    val?.invoiceId,
                                    val?.invoiceDetail?.invoiceId?.value
                                  )
                                }
                              ></img>
                              {/* <img src={EditIcon} className="editIconClass" style={{cursor:"pointer"}}></img> */}
                              <img
                                src={DeleteIcon}
                                data-toggle="tooltip"
                                alt="delete"
                                data-placement="top"
                                title="Delete"
                                className="deleteIconClass"
                                style={{
                                  cursor: "pointer",
                                  height: "14px",
                                  width: "14px",
                                }}
                                onClick={() => deleteInvoice(val?._id, index)}
                              ></img>{" "}
                            </span>{" "}
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </>
            ) : (
              <>
                <thead className="table-head">
                  <tr>
                    <th>#Invoice</th>
                    <th>Recepient</th>
                    <th>Email</th>
                    <th>Bill&nbsp;Date</th>
                    <th>Amount</th>
                    <th>Action</th>
                    {/* <th>Email</th> */}
                    {/* <th>Sent&nbsp;Date</th>
                    <th>Payment&nbsp;Type</th> */}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td style={{ textAlign: "center" }}>No Invoices Found </td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                </tbody>
              </>
            )}

            {/* {input?.length === 0 && (
              <>
                <tbody></tbody>
                <tfoot>
                  <tr>
                    <td colSpan="3">You have not sent any documents yet.</td>
                  </tr>
                </tfoot>
              </>
            )} */}

            {loading && (
              <>
                <tbody></tbody>
                <tfoot>
                  <tr>
                    <td colSpan="3"></td>
                  </tr>
                </tfoot>
                {/* <hr></hr> */}
                <Backdrop
                  sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
              </>
            )}
          </table>
          <hr />

          <TablePagination
            component="div"
            onPageChange={handlePageChange}
            page={controller?.currentpage}
            count={count}
            rowsPerPage={controller?.rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>

        {/* =========== Modal Start============= */}

        <Modal
          show={show}
          onHide={handleClose}
          scrollable={true}
          className="main-modal"
        >
          <Modal.Header>
            <div className="text-center mt-4">
              <h2 className="modal-send-document-text">Send Documents</h2>
              <p className="modal-green-text ">
                Send a invoice like a purchase order or invoice to businesses
                in your network.
              </p>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className="">
                <div className=" modal-radius">
                  <div className="modal-body">
                    <form>
                      <div className="mt-3">
                        <h6 className="modal-who-is-receipent">
                          What is the Subject?
                        </h6>
                        <label className="email-address-class">SUBJECT</label>

                        <input
                          class="form-control form-control-md button-focus input-field1"
                          type="text"
                          placeholder=" "
                          aria-label=".form-control-lg example"
                          name="subject"
                          value={formvalue?.subject}
                          onChange={save}
                          minLength={3}
                          required
                        />
                      </div>

                      <div className="mt-4">
                        <h6 className="modal-who-is-receipent">
                          Who is the recipient?
                        </h6>
                        <label className="email-address-class">
                          EMAIL ADDRESS
                        </label>

                        <input
                          class="form-control form-control-md button-focus input-field1"
                          type="text"
                          placeholder=" "
                          aria-label=".form-control-lg example"
                          name="receiverEmail"
                          value={formvalue?.receiverEmail}
                          onChange={save}
                          minLength={3}
                          required
                        />
                      </div>

                      <div className="mt-4">
                        <h6 className="which-document-like-send">
                          Which invoice would you like to send?
                        </h6>
                        <label className="email-address-class">INVOICE</label>

                        <Select
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          onChange={setNewState}
                          value={newState?.[0]}
                          defaultValue={[newState?.[0]]}
                          isMulti
                          options={newState}
                        />
                      </div>

                      <div className="mt-4">
                        <h6 className="modal-who-is-receipent">
                          Any Specific Custom Notes?
                        </h6>
                        <label className="email-address-class">NOTES</label>

                        <textarea
                          class="form-control form-control-md button-focus input-field1"
                          type="text"
                          placeholder=" "
                          aria-label=".form-control-lg example"
                          name="notes"
                          value={formvalue?.notes}
                          onChange={save}
                          minLength={3}
                          required
                        />
                        <div>
                          <p className="modal-bottom-blue-text">
                            Invoices must be formatted using UBL (Universal
                            Business Language). If you need a tool to help you
                            generate UBL documents try easyinvoice.com{" "}
                          </p>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <div className="modal-footer mb-3">
            <button
              className="btn button-focus cancel-button-modal"
              type="submit"
              onClick={handleClose}
            >
              Cancel
            </button>

            <button
              type="button"
              className="btn btn-primary px-5 button-focus"
              onClick={moadalDataPost}
              data-bs-dismiss={modalstate === false ? "koi" : "modal"}
            >
              Send
            </button>
          </div>
        </Modal>

        {/* ========================= Modal Ends ===================== */}
      </div>
    </>
  );
};
export default SentDocument;