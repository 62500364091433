import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
// import Footer from "../Footer/Footer";
import Footer2 from "../Footer/Footer1";
import Header from "../Header/Header";
import { Helmet } from "react-helmet";

function Dashboard() {
  const [invoice, setInvoice] = useState();
  const [getinvoice, setGetinvoice] = useState();
  const [inputdata, setInputData] = useState();

  useEffect(() => {
    const datasave = async () => {
      await fetch(`${process.env.REACT_APP_BASE_URL}api/invoice/get-invoice?offset=0&limit=10`, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: localStorage.getItem(`token`).replaceAll('"', ""),
        },
      })
        .then((response) => response.json())
        .then((response) => {
          setInvoice(response);
        })
        .catch((error) => {
          // console.log(error, "joih");
        });
    };
    datasave();
  }, []);

  useEffect(() => {
    const datasave = async () => {
      await fetch(`${process.env.REACT_APP_BASE_URL}api/auth/user-profile`, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: localStorage.getItem(`token`).replaceAll('"', ""),
        },
      })
        .then((response) => response.json())
        .then((response) => {
          // setInput(response);
          setInputData(response?.data);

          // localStorage.setItem('auth', JSON.stringify(response.data[0]))
          // navigate('/invoice-guide')
        })
        .catch((error) => {});
    };
    datasave();
  }, []);

  useEffect(() => {
    const datasave = async () => {
      await fetch(
        `${process.env.REACT_APP_BASE_URL}api/invoice/sent-invoices`,
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: localStorage.getItem(`token`).replaceAll('"', ""),
          },
        }
      )
        .then((response) => response.json())
        .then((response) => {
          setGetinvoice(response);
        })
        .catch((error) => {
          // console.log(error, "joih");
        });
    };
    datasave();
  }, []);

  const [seo, setSeo] = useState([]);
  useEffect(() => {
    const seoApi = async () => {
      await fetch(`${process.env.REACT_APP_BASE_URL}api/util/get-meta`, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: JSON.parse(localStorage.getItem("token")),
        },
      })
        .then((res) => res.json())
        .then((res) => {
          setSeo(res?.data);
          console.log(res?.data, "dashboard");
        });
    };
    seoApi();
  }, []);

  return (
    <div className="flex-wrapper-dashboard">
      {seo?.map((item) => {
        if (item?.pagename === "Dashboard") {
          return (
            <Helmet>
              <title>Dashboard | Easy Invoice</title>
              <meta name="twitter:description" content={item?.description} />
              <meta name="twitter:title" content={item?.title} />
              <meta name="twitter:image" content={item?.image} />
              <script
                crossorigin="anonymous"
                async
              >
                {`${item?.headerScript}`}
              </script>
              <script
               
                crossorigin="anonymous"
                async
              >
                {`${item?.footerScript}`}
              </script>
            </Helmet>
          );
        }
      })}
      {/* <Helmet>
          <title>Dashboard | Easy Invoice</title>
          <meta name="description" content={seo[6]?.content} />
        </Helmet> */}
      <Header></Header>
      <div className="container">
        <div className="row col-sm-12" style={{ marginTop: "5rem" }}>
          {!getinvoice && (
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}

          <div className="mb-3 mt-5 ">
            <p className="org-name home mb-1">DASHBOARD</p>
            <p className="organization-title">{inputdata?.organisationName}</p>
          </div>
          <p className="welcome-note mb-5">
            Welcome to Easy Invoice Business Network — a new way create and
            send invoices
          </p>
          <hr className="mb-4" />
          <div className="my-2 d-flex align-items-center justify-content-between">
            <label
              htmlFor="colFormLabelLg"
              className="col-sm-5 col-form-label col-form-label-lg my_todo"
            >
              My To Do
            </label>
            <div className="col-sm-7 px-0 py-2 my-to-do ">
              <a
                href="/setting"
                className="text-decoration-none d-flex justify-content-between align-items-center border py-3 px-3 rounded"
                style={{ position: "relative", color: "#36AE7C" }}
              >
                <div className="businessProfile-text  ">
                  Complete your business profile
                </div>

                <div className="businessProfile-icon">
                  <BsFillArrowRightCircleFill className="arrow_icon" />
                </div>
              </a>
            </div>
          </div>
          <hr className="my-4" />
          <div className="my-2 d-flex align-items-center justify-content-between">
            <label
              htmlFor="colFormLabelLg"
              className="col-sm-5 col-form-label col-form-label-lg my_documents"
            >
              My Invoices
            </label>
            <div className="col-sm-7 my-document px-0 border d-flex justify-content-between align-items-center px-3 rounded">
              <div className="d-flex align-items-center justify-content-between pt-2 pb-3 receive_send_box">
                <div className=" d-flex flex-column me-3 ">
                  <p className="m-0 px-0 py-0 text-muted ">
                    <small className="received_heading">CREATED</small>
                  </p>

                  <span className="received ">
                    {invoice ? invoice?.total : 0}
                  </span>
                </div>
                <div className=" d-flex flex-column ms-3">
                  <p className="m-0 px-0 text-muted">
                    <small className="sent_heading">SENT</small>
                  </p>
                  <span className="sent ">
                    {getinvoice ? getinvoice?.data?.length : 0}
                  </span>
                </div>
              </div>
              <div className="see-all-btn text-center py-3">
                <span className="see_all_container">
                  <Link className="link-underline" to={"/invoice"}>
                    SEE ALL
                  </Link>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer2></Footer2>
    </div>
  );
}

export default Dashboard;
