import React from "react";
import DatePicker from "react-datepicker";
import { BiX } from "react-icons/bi";
import { FaSistrix } from "react-icons/fa";
import "./Documents.css";

const SearchComponents = (props) => {
  console.log(props , "props")
  const {hideCrossIcon} = props

  return (
    <>
      <div
        className=""
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          margin: "0px",
          padding: "0px",
        }}
      >
        <div className="search-main-container">
          <div className="wrapper-calender-picker position-relative">
            <DatePicker
              // selected={startDate}
              value={props?.searchInput?.month}
              // onSelect={(date) => handleSelectDates(date)}
              onChange={(date) => props.handleSelectDates(date)}
              dateFormat="MM/yyyy"
              showMonthYearPicker
              showFullMonthYearPicker
              className="custom-input  select-filter-year"
              popperPlacement="top-start"
              placeholderText="Select Date "
            />
            <div
              className="clear-icon"
              style={{
                position: "absolute",
                right: "13px",
                top: "1px",
                color: "blue",
                height: "12px",
                width: "10px",
                backgroundClip: "red",
                cursor: "pointer",
              }}
            >
             {!hideCrossIcon &&  <BiX
                onClick={() => props.handleClearFilter()}
                data-toggle="tooltip"
                title="Clear Filter"
                data-placement="bottom"
                color="red"
              />}
              {}
            </div>
          </div>

          <div
            className="  search-container "
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "0px",
            }}
          >
            <div className="">
              <button
              // style = {{ opacity: !hideCrossIcon ?  : "" }}
                 disabled = {hideCrossIcon}
                 cursor = "none"
                 type="submit"
                // className="search-button"
                className = {!hideCrossIcon ?  "search-button" :"opacityReduced"}
                onClick={() => props.fetchSearchData()}
              >
                Search
              </button>
            </div>
          </div>
        </div>
        <div
          className="search-icon-inner-container"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <input
            className="search-input-document"
            onChange={(e) => props.handleSearchInput(e.target.value)}
            type="search"
            // value={props?.searchInput?.input}s
            placeholder="Search"
          />
          <FaSistrix className="search-icon" color="#2155cd" />
        </div>
      </div>
    </>
  );
};
export default SearchComponents;
