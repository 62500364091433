import React, { useState } from "react";
import RecivedDocument from "./RecievedDocument";
import SentDocument from "./SentDocument";
import "./Documents.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { TbChevronDown, TbSend } from "react-icons/tb";
import { toast } from "react-toastify";
import { useEffect } from "react";
import Modal from "react-bootstrap/Modal";

export const Tabs = (props) => {
  const [options, setOptions] = useState([]);
  const [show, setShow] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
const [shownData, setShownData]=useState([])
  const [formvalue, setFormvalue] = useState({
    subject: "",
    receiverEmail: "",
    invoiceId: [],
    notes: "",
  });



  const handleClose = () => {
    setShow(false);
    setFormvalue({
      subject: "",
      receiverEmail: "",
      invoiceId: [],
      notes: "",
    })
    setOptions([])
  };

  const handleShow = (id) => {
    setShow(true);
  };

  useEffect(()=>{
  
    activeTab===0&& createdModalApi();
    activeTab===1 && sentModalApi();
  },[activeTab])
  
  const createdModalApi = async () => {
    await fetch(
      `${process.env.REACT_APP_BASE_URL}api/invoice/get-all-invoice`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: localStorage.getItem(`token`).replaceAll('"', ""),
        },
      },
    )
      .then((response) => response.json())
      .then((response) => {
if(response?.success===true){
       
const newData =response?.data?.map((val)=>{
return{label:val?.invoiceId?.value, value:val?.commonId
}
})


  setShownData(newData)


}
  })
      .catch((error) => {
        // console.log(error, "joih");
      });
  };

  const sentModalApi = async () => {
    await fetch(
      `${process.env.REACT_APP_BASE_URL}api/invoice/sent-all-invoices-get`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: localStorage.getItem(`token`).replaceAll('"', ""),
        },
      },
    )
      .then((response) => response.json())
      .then((response) => {
if(response?.success===true){
       


const newSentData =response?.data?.map((val)=>{
  return{label:val?.sendInvoiceDetails?.invoiceDetail?.invoiceId?.value, value:val?.commonId  }
    })
    
  setShownData( newSentData )


}
  })
      .catch((error) => {
        // console.log(error, "joih");
      });
  };


  useEffect(() => {
    const invoiceId = options?.map((val, ind) => {
    
      return val?.value;
    });
   
    setFormvalue({ ...formvalue, invoiceId: invoiceId });
    // console.log(invoiceId, "logp");
  }, [options]);

  const animatedComponents = makeAnimated();

  const [modalstate, setModalstate] = useState(false);

  
  const handleActiveTab = (id) => {
    setActiveTab(() => id);
  };

  // Post Data in Modal Section

  let name1, val;
  const save = (e) => {
    setFormvalue({ ...formvalue, invoiceId: options });
    name1 = e.target.name;
    val = e.target.value;
    setFormvalue({ ...formvalue, [name1]: val });
  };

  const postdata = async (e) => {
    e.preventDefault();

    const { subject, receiverEmail, invoiceId, notes } = formvalue;

    if (receiverEmail.length <= 2) {
      toast.error("Please Enter Your Email");
    }

    receiverEmail.length >= 3 && setShow(false);
    receiverEmail.length >= 3 &&
      (await fetch(
        `${process.env.REACT_APP_BASE_URL}api/invoice/send-invoice`,
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
            Authorization: localStorage.getItem(`token`).replaceAll('"', ""),
          },

          body: JSON.stringify({
            subject,
            receiverEmail,
            invoiceId,
            notes,
          }),
        }
      )
        .then((response) => response.json())
        .then((response) => {
          if (response?.success === true) {
            const sentData = JSON.parse(localStorage.getItem("sent"));
            const newSentData = sentData + invoiceId?.length;
            localStorage.setItem("sent", JSON.stringify(newSentData));

            const trashData = JSON.parse(localStorage.getItem("trash"));
            const createdData = JSON.parse(localStorage.getItem("created"));
            props?.notificationFunction(createdData, trashData, newSentData);
          
            toast.success(response?.message);
            handleClose()
          } else {
            handleClose()

            
            throw response.message;
          }

          // debugger;
          // setMessage(response.error);

          // localStorage.setItem('auth', JSON.stringify(response.data[0]))
          // window.location.reload();
        })
        .catch((error) => {
          // console.log(error, "joih");
          toast.error(error);
        }));
  };

  return (
    <div>
      <div className="d-flex justify-content-end mt-4">
        <div className="d-grid gap-2">
          <button
            type="button"
            className="btn btn-primary button-focus"
            onClick={handleShow}
            disabled={activeTab === 2}
          >
            <TbSend /> Send Invoice
          </button>
        </div>
      </div>
      {/* Modal Start  */}

      <div className="mt-5">
        {/* <div
                  className="modal fade "
                  id="exampleModal"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  data-bs-backdrop="static"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable px-4 ">
                    <div className="modal-content modal-radius">
                      <div className="text-center mt-4">
                        <h2 className="modal-send-document-text">
                          Send Documents
                        </h2>
                        <p className="modal-green-text ">
                          Send a document like a purchase order or invoice to
                          businesses in your network.
                        </p>
                      </div>
                      <div className="modal-body">
                        <form>
                        <div className="mt-3">
                            <h6 className="modal-who-is-receipent">
                              What is the Subject?
                            </h6>
                            <label className="email-address-class">
                              SUBJECT
                            </label>
                            <input
                              class="form-control form-control-md button-focus input-field1"
                              type="text"
                              placeholder=" "
                              aria-label=".form-control-lg example"
                              name="subject"
                              value={formvalue?.subject}
                              onChange={save}
                              minLength={3}
                              required
                            />
                          </div>
                          <div className="mt-4">
                            <h6 className="modal-who-is-receipent">
                              Who is the recipient?
                            </h6>
                            <label className="email-address-class">
                              EMAIL ADDRESS
                            </label>
                            <input
                              class="form-control form-control-md button-focus input-field1"
                              type="text"
                              placeholder=" "
                              aria-label=".form-control-lg example"
                              name="receiverEmail"
                              value={formvalue?.receiverEmail}
                              onChange={save}
                              minLength={3}
                              required
                            />
                          </div>
                          <div className="mt-4">
                            <h6 className="which-document-like-send">
                              Which document would you like to send?
                            </h6>
                            <label className="email-address-class">
                              INVOICE
                            </label>
                          
                            <Select
      closeMenuOnSelect={false}
      components={animatedComponents}
      onChange={setOptions}
      value={options}
      isMulti
      options={props?.data?.[activeTab]?.relativedata}
    />
                          
                          </div>
                          <div className="mt-4">
                            <h6 className="modal-who-is-receipent">
                              Any Specific Custom Notes?
                            </h6>
                            <label className="email-address-class">
                              NOTES
                            </label>
                            <textarea
                              class="form-control form-control-md button-focus input-field1"
                              type="text"
                              placeholder=" "
                              aria-label=".form-control-lg example"
                              name="notes"
                              value={formvalue?.notes}
                              onChange={save}
                              minLength={3}
                              required
                            />
                              <div>
                              <p className="modal-bottom-blue-text">
                                Documents must be formatted using UBL (Universal
                                Business Language). If you need a tool to help
                                you generate UBL documents try easyinvoice.com{" "}
                              </p>
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className="modal-footer mb-3">
                        <button
                          className="btn button-focus cancel-button-modal"
                          type="submit"
                          data-bs-dismiss="modal"
                          onClick={()=>setOptions([])}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary px-5 button-focus"
                          onClick={postdata}
                          data-bs-dismiss={
                            modalstate === false ? "koi" : "modal"
                          }
                        >
                          Send
                        </button>
                      </div>
                    </div>
                  </div>
                </div> */}

        <Modal
          show={show}
          onHide={handleClose}
          scrollable={true}
          className="main-modal"
        >
          <Modal.Header>
            <div className="text-center mt-4">
              <h2 className="modal-send-document-text">Send Invoices</h2>
              <p className="modal-green-text ">
                Send a invoice like a purchase order or invoice to businesses
                in your network.
              </p>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className="">
                <div className=" modal-radius">
                  <div className="modal-body">
                    <form>
                      <div className="mt-3">
                        <h6 className="modal-who-is-receipent">
                          What is the Subject?
                        </h6>
                        <label className="email-address-class">SUBJECT</label>

                        <input
                          class="form-control form-control-md button-focus input-field1"
                          type="text"
                          placeholder=" "
                          aria-label=".form-control-lg example"
                          name="subject"
                          value={formvalue?.subject}
                          onChange={save}
                          minLength={3}
                          required
                        />
                      </div>

                      <div className="mt-4">
                        <h6 className="modal-who-is-receipent">
                          Who is the recipient?
                        </h6>
                        <label className="email-address-class">
                          EMAIL ADDRESS
                        </label>

                        <input
                          class="form-control form-control-md button-focus input-field1"
                          type="text"
                          placeholder=" "
                          aria-label=".form-control-lg example"
                          name="receiverEmail"
                          value={formvalue?.receiverEmail}
                          onChange={save}
                          minLength={3}
                          required
                        />
                      </div>

                      <div className="mt-4">
                        <h6 className="which-document-like-send">
                          Which invoice would you like to send?
                        </h6>
                        <label className="email-address-class">INVOICE</label>

                        <Select
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          onChange={setOptions}
                          value={options}
                          isMulti
                          options={shownData}
                        />
                      </div>

                      <div className="mt-4">
                        <h6 className="modal-who-is-receipent">
                          Any Specific Custom Notes?
                        </h6>
                        <label className="email-address-class">NOTES</label>

                        <textarea
                          class="form-control form-control-md button-focus input-field1"
                          type="text"
                          placeholder=" "
                          aria-label=".form-control-lg example"
                          name="notes"
                          value={formvalue?.notes}
                          onChange={save}
                          minLength={3}
                          required
                        />
                        <div>
                          <p className="modal-bottom-blue-text">
                            Invoices must be formatted using UBL (Universal
                            Business Language). If you need a tool to help you
                            generate UBL invoices try easyinvoice.com{" "}
                          </p>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <div className="modal-footer mb-3">
            <button
              className="btn button-focus cancel-button-modal"
              type="submit"
              onClick={handleClose}
            >
              Cancel
            </button>

            <button
              type="button"
              className="btn btn-primary px-5 button-focus"
              onClick={postdata}
              data-bs-dismiss={modalstate === false ? "koi" : "modal"}
            >
              Send
            </button>
          </div>
        </Modal>
      </div>
      {/* Modal Ends  */}

      <div className={`row gx-0 `}>
        <div className=" d-flex align-item-center">
          {props?.data?.map((val) => {
            return (
              <div onClick={() => handleActiveTab(val.id)}>
                <p
                  className={`
                                ${"newCss"}
                                ${val.id === 0 ? "" : "sent-text"} 
                                 ${
                                   val.id === activeTab && "active-tab"
                                 } ${"pb-3"} ${"tab-fonts"} `}
                >
                  {" "}
                  {val.title}{" "}
                  <span className="badge notification-number rounded-circle">
                    {val.notifications}
                  </span>{" "}
                </p>
              </div>
            );
          })}
        </div>
        <hr className="hr-tag" />
      </div>
      {props.data[activeTab].component}
    </div>
  );
};