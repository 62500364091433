import React, { Fragment } from "react";
import "./TextColorBox.css";

const TextColorBox = ({ textColorChangeHandler, selectedTextColor }) => {
	const arr = [
		"black",
		"#CAD8EB",
		"#9BEDF3",
		"#4285f4",
		"#CAF1DE",
		"#fbbc05",
		"#ea4335",
		"#34a853",
	];

	return (
		<>
			<div className="color_main_container">
				<p>Text Color</p>
				<div className="colorBoxContainer">
					{arr.map((col) => {
						return (
							<div className="colorContainer text-color-box">
								<div
									className="colorBox"
									onClick={() => textColorChangeHandler(col)}
									style={{
										backgroundColor: col,
									}}
								></div>
							</div>
						);
					})}
					<input
						type="color"
						value={selectedTextColor?.light}
						onChange={(e) => textColorChangeHandler(e.target.value)}
					/>
				</div>
			</div>
		</>
	);
};

export default TextColorBox;
