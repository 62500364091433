import React from "react";
import Lottie from "react-lottie";
import AnimationImage from "../../assets/invoice_animation.json";

const Animation = () => {
  return (
    <>
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: AnimationImage,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
          },
        }}
        height={800} // old height 520
        width={720} // old weight 600
        // style={{border:"1px solid #c9ccce", borderRadius: "5px"}}
      />
    </>
  );
};
export default Animation;
