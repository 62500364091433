import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import "./Verify.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import checkIcon from "../../assets/check-green.png";
import crossIcon from "../../assets/cross.png";

// let token = localStorage.getItem(`token`).replaceAll('"', "");

const Verify = () => {
  const [serachParams, setSearchParams] = useSearchParams();
  const searchTerm = serachParams.get("token");
  const [response, setResponse] = useState(true);




  
  const navigate = useNavigate();

  useEffect(() => {
    const datasave = async () => {
      await fetch(
        `${process.env.REACT_APP_BASE_URL}api/auth/verify?token=${searchTerm}`,
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
            // Authorization: localStorage.getItem(`token`).replaceAll('"', ""),
          },
        }
      )
        .then((response) => response.json())
        .then((response) => {
          // setInput(response);
          // setInputData(response?.data);

          setResponse(true);

          // localStorage.setItem('auth', JSON.stringify(response.data[0]))
          // navigate('/invoice-guide')
        })
        .catch((error) => {
          setResponse(false);
        });
    };
    datasave();
  }, []);

  return (
    <>
      {/* <div className="flex-wrapper-verify" style = {{backgroundColor:"#fffafa", display:"flex",alignItems:"center",justifyContent:"center",height:"30vh",border:"1px solid red"}}> */}
      {/* <Header /> */}
      <div className="account-verification-container ">
        {response ? (
          <div className="parent-div">
            <div className="child-div p-3">
              <img src={checkIcon} className="right-tick " />
              <h1 className="account-status-heading">
                Your Account Has Been Verified
              </h1>
              <button
                type="button"
                class="btn btn-primary btn-lg mt-3 px-5"
                onClick={(e) => navigate("/sign-in")}
              >
                Sign In
              </button>
            </div>
          </div>
        ) : (
          <div className="parent-div">
            <div className="child-div p-3">
              <img src={crossIcon} className="right-tick " />
              <h1 className="account-status-heading cross-mark-text">
                Your Account Has Been Not Verified
              </h1>
            </div>
          </div>
        )}
        {/* <Footer /> */}
      </div>

      {/* </div> */}
    </>
  );
};

export default Verify;
