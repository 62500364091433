import React, { useEffect, useState } from "react";
import "./Header.css";
import {
  Link,
  useLocation,
  useParams,
  useNavigate,
  // useRoutes,
} from "react-router-dom";
import { FaSignOutAlt, FaRegSun } from "react-icons/fa";
// import { logout } from "../../InvoiceRedux/action/AuthActions";

// import { useDispatch } from "react-redux";

// import { useRoutes } from "react-router-dom";
import Logo from "../../assets/logo.svg";
// import User from "../../assets/user.svg";
// import { useSelector } from "react-redux";
import UserAccount from "../../assets/useraccount.svg";
import { settingLogoUpload } from "../../InvoiceRedux/action/action";
import { useDispatch } from "react-redux";
// import Bell from "../../assets/bell.svg";
import { newInvoiceTableData } from "../../InvoiceRedux/action/action";



function Header() {
  // const [imgurl, setImgurl] = useState("");
  // const router = useRoutes;
  const [userProfile, setUserProfile] = useState()
  const { id } = useParams();
  // let newNumberList;
  const user = localStorage.getItem("Admin");

  const dispatch = useDispatch()
  let newNumberList;
  // const navigate = useNavigate();
  const [id3, setId] = useState(increaseNumber());
  const [number, setNumber] = useState(newNumbers());

// console.log(id3, "ajja")

  function increaseNumber() {
    return JSON.parse(localStorage.getItem("ids")) || 0;
  }

  function newNumbers() {
    return (newNumberList =
      JSON.parse(localStorage.getItem("idCollection")) || []);
  }




  // function isActive(route) {
  //   if (route == router.pathname) {
  //     return "active";
  //   } else {
  //     return "";
  //   }
  // }


  // ========================= User Profile Api Start =============================

  // useEffect(()=> {
  //   const userImage = async() => {
  //     await fetch(`${process.env.REACT_APP_BASE_URL}api/auth/user-profile`,{
  //       method:"GET",
  //       headers:{
  //         "content-type": "application/json",
  //         Authorization: localStorage.getItem(`token`).replaceAll('"', ""),
  //       }
  //     }).then(res => res.json())
  //     .then((res)=> {
  //       if(res.success === true){
  //         setUserProfile(res?.data?.logo)
  //       }
  //     })
  //   }
  //   userImage()
  // },[])

  // ========================= User Profile Api End =============================

  const navigate = useNavigate();
  let location = useLocation();

  // const [number, setNumber] = useState(newNumbers());

  // const dispatch = useDispatch();

  // function newNumbers() {
  //   return (newNumberList =
  //     JSON.parse(localStorage.getItem("idCollection")) || []);
  // }
  const createInvoice = () => {
    // let previousInvoice = JSON.parse(localStorage.getItem("ids")) || 1;
    // const invoiceitems = JSON.parse(
    //   localStorage.getItem(`adminProfile${previousInvoice}`)
    // );
    // let id2;
    // if (invoiceitems?.invoiceGenerator?.billTo?.value === "") {
    //   id2 = previousInvoice;
    //   localStorage.setItem("ids", id2);
    // } else {
    //   id2 = previousInvoice;
    //   localStorage.setItem("ids", id2);
    // }

    // let idCollection = JSON.parse(localStorage.getItem("idCollection"));

    // if (idCollection?.length > 1) {
    //   let newNumbers = [...idCollection];
    //   localStorage.setItem("idCollection", JSON.stringify(newNumbers));
    // } else {
    //   let newNumber = [id2];
    //   localStorage.setItem("idCollection", JSON.stringify(newNumber));
    // }

    // navigate(`/invoice-Downloader/${id2}`);
    // console.log(id, "polo");
  let currentParamId= JSON.parse(localStorage.getItem(`ids`))
    const invoiceitems = JSON.parse(localStorage.getItem(`adminProfile${currentParamId}`));
    let id2;
    let newInvoiceArray; 
    // console.log(invoiceitems, "polo");
    if (invoiceitems?.invoiceGenerator?.billTo?.value == "" || invoiceitems?.invoiceGenerator?.companyName == "") {
      // alert("if")
      // id2 = id3;
      // setId(id2);
      // localStorage.setItem("ids", id2);
      id2= currentParamId
      newInvoiceArray=[...newNumberList ]
    } else {
      // alert("else")
      id2 = id3 + 1;
      setId(id2);
      localStorage.setItem("ids", id2);
     newInvoiceArray =[...newNumberList,id2]
    }
 

    // console.log(newInvoiceArray, "pata")
    localStorage.setItem("idCollection", JSON.stringify(newInvoiceArray));
    setNumber(newInvoiceArray);
    dispatch(newInvoiceTableData());
    navigate(`/invoice-downloader/${id2}`);

    if (location.pathname == `/invoice-downloader/${id3}`) {
     
      window.location.reload(false)
    }
  };

  const isAuthenticate = localStorage.getItem(`token`);
  const click = () => {
    if (state === "collapse") {
      setState("show");
    } else {
      setState("collapse");
    }
  };
  const [state, setState] = useState("collapse");
  const clickee = () => {
    // document.getElementsByClassName("navbar-collapse justify-content-md-center RightNavbar mt-4 collapse ")
    if (state === "collapse") {
      setState("show");
    } else {
      setState("collapse");
    }
  };

  function popIdFromLocalStorage() {
    let newNumberListId =
      JSON.parse(localStorage.getItem("idCollection")) || [];
    let adminProfile = JSON.parse(localStorage.getItem(`adminProfile${id}`));

    if (adminProfile?.invoiceGenerator.billTo.value === "") {
      newNumberListId.pop();

      localStorage.removeItem(`adminProfile${id}`);
      localStorage.setItem("idCollection", JSON.stringify(newNumberListId));
      let idcollectionAfterpop =
        JSON.parse(localStorage.getItem("idCollection")) || [];

      const index =
        idcollectionAfterpop.length <= 0 ? 0 : idcollectionAfterpop.length - 1;

      const indexvalue = idcollectionAfterpop[index] || 1;
      localStorage.setItem("ids", JSON.stringify(indexvalue));
    }
  }

  const invoiceGuide = () => {
    popIdFromLocalStorage();

    if (state === "collapse") {
      setState("show");
    } else {
      setState("collapse");
    }
  };
  function onLogout() {
    localStorage.removeItem("auth");
    localStorage.removeItem("token");
    localStorage.removeItem("Admin");
    navigate("/sign-in");
  }

  // check
  const logOut = (
    <nav className="navbar navbar-expand-lg fixed-top bg-white ">
      <div className="container  ">
        <div className="">
          <ul className="navbar-nav me-3">
            <li className="nav-item">
              <Link className="navbar-brand" to="/" tabIndex="-1">
                <img className="LogoImg" src={Logo} alt="logo-img" />
              </Link>
            </li>
          </ul>
        </div>

        <button
          id="myBtn"
          className="navbar-toggler p-0"
          type="button"
          // data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={clickee}
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div
          className={`collapse navbar-collapse  RightNavbar ${state} justify-content-end `}
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav mt-2 ms-4 ">
            {/* <li className="nav-item  ms-1 mb-2 ">
              <Link
                to="/home"
                className={`btn ${location.pathname === "/home" ? "active" : ""}`}
                tabIndex="-1"
                role="button"
                // aria-disabled="true"
                onClick={invoiceGuide}
              >
                Home
              </Link>
            </li> */}
            <li className="nav-item ms-1 mb-2">
              <Link
                to="/invoice-guide"
                className={`btn py-2 ${location.pathname === "/invoice-guide" ? "active" : ""
                  }`}
                tabIndex="-1"
                role="button"
                // aria-disabled="true"
                onClick={invoiceGuide}
              >
                Invoice Guide
              </Link>
            </li>
            <li className="nav-item ms-1">
              <Link
                to="/about-us"
                className={`btn py-2 ${location.pathname === "/about-us" ? "active" : ""
                  }`}
                tabIndex="-1"
                role="button"
                // aria-disabled="true"
                onClick={invoiceGuide}
              >
                About Us
              </Link>
            </li>
            <li className="nav-item ms-1">
              <Link
                to="/contact-us"
                className={`btn py-2 ${location.pathname === "/contact-us" ? "active" : ""
                  }`}
                tabIndex="-1"
                role="button"
                // aria-disabled="true"
                onClick={invoiceGuide}
              >
                Contact Us
              </Link>
            </li>

            <li className="nav-item ms-1 mb-2">
              <Link
                to="/sign-in"
                className={`btn py-2 ${location.pathname === "/sign-in" ? "active" : ""
                  }`}
                tabIndex="-1"
                role="button"
                // aria-disabled="true"
                onClick={clickee}
              >
                Log In
              </Link>
            </li>

            <li className="nav-item ms-1 mb-2 me-1">
              <button
                className={`btn change`}
                tabIndex="-1"
                role="button"
                aria-disabled="true"
                authorize
                onClick={createInvoice}
                style={{
                  backgroundColor: "#2155cd",
                  color: "#FFFFFF",
                  border: "none",
                  fontSize: "15px",
                  // width: "9rem",
                  height: "40px",
                  // display: "flex",
                  // alignItems: "center",
                  // justifyContent: "center",imp
                  // textAlign: "center",
                  borderRadius: "4px",
                }}
              >
                Create Invoice
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );

  const login = (
    <nav
      className="navbar navbar-expand-lg fixed-top bg-white "
    // style={{ paddingRight: "24px" }}
    >
      <div className="container d-flex  p-lg-0">
        <div>
          <ul className="navbar-nav mb-lg-0 ">
            <li className="nav-item ">
              <Link to="/" className="navbar-brand" tabIndex="-1">
                <img className="LogoImg my-1" src={Logo} alt="logo-img" />
              </Link>
            </li>
          </ul>
        </div>

        <div className="order-md-2 ">
          <button
            id="myButton"
            className="navbar-toggler  p-0"
            type="button"
            // data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={click}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>
        <div
          className={`collapse navbar-collapse justify-content-md-center RightNavbar   ${state} `}
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav ">
            <li className="nav-item mb-0 pb-0  ">
              <Link
                to="/dashboard"
                className={`btn py-2  nav__element ${location.pathname === "/dashboard" ? "active" : ""
                  } ms-2 ms-lg-1 px-lg-1 px-xl-2`}
                // data-bs-toggle="collapse"
                // data-bs-target=".navbar-collapse"
                tabIndex="-1"
                role="button"
                aria-disabled="true"
                onClick={click}
              >
                Dashboard
              </Link>
            </li>
            <li className="nav-item pb-0 ">
              <Link
                to="/invoice"
                className={`btn py-2 nav__element ${location.pathname === "/invoice" ? "active" : ""
                  } ms-2 ms-lg-1 px-lg-1 px-xl-2`}
                tabIndex="-1"
                role="button"
                aria-disabled="true"
                onClick={click}
              >
                Invoice
              </Link>
            </li>
            {/* <li className="nav-item pb-0 ">
              <Link
                to="/setting"
                className={`btn  nav__element ${
                  location.pathname === "/setting" ? "active" : ""
                } ms-2`}
                tabIndex="-1"
                role="button"
                aria-disabled="true"
                onClick={click}
                // style={{ fontWeight: "bold" }}
              >
                Settings
              </Link>
              <div className="dash-nav-container"></div>
            </li> */}
            <li className="nav-item pb-0 ">
              <Link
                to="/invoice-guide"
                className={`btn py-2 nav__element ${location.pathname === "/invoice-guide" ? "active" : ""
                  } ms-2 ms-lg-1 px-lg-1 px-xl-2`}
                tabIndex="-1"
                role="button"
                aria-disabled="true"
                onClick={invoiceGuide}
              >
                Invoice Guide
              </Link>
            </li>
            <li className="nav-item pb-0 ">
              <Link
                to="/about-us"
                className={`btn py-2 nav__element ${location.pathname === "/about-us" ? "active" : ""
                  } ms-2 ms-lg-1 px-lg-1 px-xl-2`}
                tabIndex="-1"
                role="button"
                aria-disabled="true"
                onClick={invoiceGuide}
              >
                About Us
              </Link>
            </li>
            {user !== "1" && (
              <li className="nav-item pb-0 ">
                <Link
                  to="/contact-us"
                  className={`btn py-2 nav__element ${location.pathname === "/contact-us" ? "active" : ""
                    } ms-2 ms-lg-1 px-lg-1 px-xl-2`}
                  tabIndex="-1"
                  role="button"
                  aria-disabled="true"
                  onClick={invoiceGuide}
                >
                  Contact Us
                </Link>
              </li>
            )}

            {user == "1" && (
              <li className="nav-item pb-0 ">
                <Link
                  to="/user-list"
                  className={`btn py-2 nav__element ${location.pathname === "/user-list" ? "active" : ""
                    } ms-2 ms-lg-1 px-lg-1 px-xl-2`}
                  tabIndex="-1"
                  role="button"
                  aria-disabled="true"
                  onClick={invoiceGuide}
                >
                  User List
                </Link>
              </li>
            )}

            {user == "1" && (
              <li className="nav-item pb-0 ">
                <Link
                  to="/seo"
                  className={`btn py-2 nav__element ${location.pathname === "/seo" ? "active" : ""
                    } ms-2 ms-lg-1 px-lg-1 px-xl-2`}
                  tabIndex="-1"
                  role="button"
                  aria-disabled="true"
                >
                  Site Settings
                </Link>
              </li>
            )}
          </ul>
        </div>

        <div className=" my__container d-flex ">
          {/* {user == "1" && (
            <span
              // to="/my-account"
              className="text-decoration-none me-2 rounded-circle"
            >
              <button
                className="order-md-2 my-acount-invoice-button rounded-circle"
                style={{
                  // backgroundColor: "#2155CD1A",
                  color: "#2155CD",
                  border: "none",
                  height: "40px",
                  width: "40px",
                  // borderRadius: "12px",
                  padding: "0.5rem",
                }}
                onClick={click}
              >
                <img
                  src={Bell}
                  style={{ borderBottom: "none", color: "blue" }}
                  alt="create-invoice-image"
                  className="my_account"
                />
              </button>
            </span>
          )} */}

          <span
            // to="/my-account"
            className=" nav___button text-decoration-none me-2 "
          >
            <button
              className="order-md-2 my-acount-invoice-button tooltips tooltips  my__account  "
              style={{
                backgroundColor: "#2155CD1A",
                color: "#2155CD",
                border: "none",
                height: "40px",
                borderRadius: "4px",
                padding: "0.5rem",
              }}
              onClick={click}
            >
              <img
                src={UserAccount}
                style={{ borderBottom: "none" }}
                alt="create-invoice-image"
                className="my_account mt-1 account_img checkkkk "
              />
              Profile
              <div class="tooltipstext  shadow-lg mb-5 bg-body-tertiary py-1">
                <Link
                  style={{ textDecoration: "none", color: "black" }}
                  to="/my-account"
                >
                  <div className="d-flex justify-content-start align-items-center ps-3 pe-4 pt-0 mt-0 h-0">


                    <img
                      style={{ height: "14px", width: "14px", objectFit: "contain" }}
                      src={UserAccount}
                      crossorigin="anonymous"
                      alt="..."
                    />
                    <p className="pt-3 ps-2 " style={{ fontSize: "13px" }}>
                      Edit Profile
                    </p>
                  </div>
                </Link>
                <Link
                  style={{ textDecoration: "none", color: "black" }}
                  to="/setting"
                >
                  <div className="d-flex border-top border-bottom justify-content-start align-items-center my-0 ps-3 pe-4">
                    <FaRegSun
                      color="#2155CD"
                      style={{ height: "15px", width: "15px" }}
                    />

                    <p style={{ fontSize: "13px" }} className="pt-3 ps-2">
                      Settings
                    </p>
                  </div>
                </Link>
                <div
                  onClick={onLogout}
                  className="d-flex justify-content-start align-items-center  mt-0 ps-3 pe-4"
                >
                  <FaSignOutAlt
                    color="rgb(190, 2, 2)"
                    style={{ height: "15px", width: "15px" }}
                  />
                  <p style={{ fontSize: "13px" }} className="pt-3 ps-2">
                    Sign out
                  </p>
                </div>
              </div>
            </button>
          </span>
          <span  className="text-decoration-none">
            <button
              className={`btn change ${location.pathname === `/invoice-downloader/${id}`
                  ? "active"
                  : ""
                }`}
              onClick={createInvoice}
              style={{
                backgroundColor: "#2155cd",
                color: "#FFFFFF",
                border: "none",
                fontSize: "12.5px",
                // width: "9rem",
                height: "40px",
                // display: "flex",
                // alignItems: "center",
                // justifyContent: "center",imp
                // textAlign: "center",
                borderRadius: "4px",
              }}
            >
              Create Invoices
            </button>
          </span>
        </div>
      </div>
    </nav>
  );

  return (
    <>
      <div className="margin-media-query">
        {isAuthenticate ? login : logOut}
      </div>
    </>
  );
}

export default Header;