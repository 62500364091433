import React, { useState, useEffect } from "react";
import "./Signup.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Countries from "./Countries";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Footer2 from "../Footer/Footer1";
import { Helmet } from "react-helmet";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CountriesData from "./CountriesData";

const Signup = () => {
	const [countryDetail, setCountryDetail] = useState(CountriesData);
	const [getresponse, setGetresponse] = useState();
	const [loading, setLoading] = useState(false);
	const [show, setShow] = useState(true);
	const [input, setInput] = useState({
		name: "",
		organisationName: "",
		email: "",
		country: "India",
		password: "",
	});
	const [error, setError] = useState("");
	const [erro, setErro] = useState("");
	const [countrycode, setCountryCode] = useState();

	const save = (e) => {
		let name1, val;
		// e.preventDefault();
		let code = countryDetail.filter((item) =>
			item.name === e.target.value ? setCountryCode(item?.dial_code) : "",
		);
		// console.log(code,"6565");
		name1 = e.target.name;
		val = e.target.value;

		setErro(null);
		setError(null);
		setInput({ ...input, [name1]: val });
	};

	const change = () => {
		if (show === true) {
			setShow(false);
		} else {
			setShow(true);
		}
	};

	//  var apple;
	//  var seed;
	const navigate = useNavigate();
	const datasave = async (e) => {
		e.preventDefault();

		loader();
		const { name, organisationName, email, country, password } = input;

		await fetch(`${process.env.REACT_APP_BASE_URL}api/auth/signup`, {
			method: "POST",
			headers: {
				"content-type": "application/json",
			},

			body: JSON.stringify({
				name,
				organisationName,
				email,
				country,
				password,
				code: countrycode,
			}),
		})
			.then((response) => response.json())
			.then((response) => {
				// loader();
				setGetresponse(response);

				if (!response?.success) {
					throw Error(response.message);
				}

				toast.success(response?.message);
				// setMessage(response);
				localStorage.setItem("auth", JSON.stringify(response.data[0]));
				navigate("/sign-in");
			})
			.catch((error) => {
				toast.error(error?.message);
			});
	};

	const loader = () => {
		setLoading(true);
	};

	const [seo, setSeo] = useState([]);
	useEffect(() => {
		const seoApi = async () => {
			await fetch(`${process.env.REACT_APP_BASE_URL}api/util/get-meta`, {
				method: "GET",
				headers: {
					"content-type": "application/json",
					Authorization: JSON.parse(localStorage.getItem("token")),
				},
			})
				.then((res) => res.json())
				.then((res) => {
					setSeo(res?.data);
				});
		};
		seoApi();
	}, []);

	return (
		<>
			<div className="flex-wrapper-Signup">
				{seo?.map((item) => {
					if (item?.pagename === "Sign Up") {
						return (
							<Helmet>
								<title>Sign Up | Easy Invoice</title>
								<meta name="twitter:description" content={item?.description} />
								<meta name="twitter:title" content={item?.title} />
								<meta name="twitter:image" content={item?.image} />
								<script crossorigin="anonymous" async>
									{`${item?.headerScript}`}
								</script>
								<script crossorigin="anonymous" async>
									{`${item?.footerScript}`}
								</script>
							</Helmet>
						);
					}
				})}
				{/* <Helmet>
          <title>Sign Up | Easy Invoice</title>
          <meta name="description" content={seo[3]?.content} />
        </Helmet> */}
				<Header></Header>
				<section className="Signup" style={{ marginTop: "5rem" }}>
					<div className="container ">
						<div className="row mt-4 gx-0">
							<div className="col-sm-12 d-flex justify-content-center head-text">
								<h1>Sign Up</h1>
							</div>
						</div>
						<div className="row gx-0">
							<div className="col-sm-12  d-flex justify-content-center mt-3">
								<form onSubmit={datasave}>
									<div class="form-outline mb-3 password_input_class">
										{loading === true && !getresponse && (
											<Backdrop
												sx={{
													color: "#fff",
													zIndex: (theme) => theme.zIndex.drawer + 1,
												}}
												open
											>
												<CircularProgress color="inherit" />
											</Backdrop>
										)}

										<input
											type="text"
											id="form2Example1"
											className="form-control input-box "
											placeholder="Name"
											name="name"
											value={input.name}
											onChange={save}
											minLength={4}
											maxLength={20}
											required
										/>
									</div>
									<div className="form-outline mb-3">
										<input
											type="text"
											id="form2Example1"
											className="form-control input-box "
											placeholder="Company Name"
											name="organisationName"
											value={input.organisationName}
											onChange={save}
											minLength={4}
											maxLength={20}
											required
										/>
									</div>
									{/* <span>{error && <p className="apple">{error.length > 0 ? error : ""}</p>}</span> */}

									<div className="form-outline mb-3">
										<input
											type="email"
											id="form2Example1"
											className="form-control input-box "
											placeholder="Email address"
											name="email"
											value={input.email}
											onChange={save}
											required
										/>
									</div>
									{/* <span>{erro && <p className="apple">{erro.length > 0 ? erro : null}</p>}</span> */}

									<div
										className="form-outline mb-3 password_input_class"
										// style={{ position: "relative" }}
									>
										{input?.password?.length === 0 ? null : show ? (
											<i
												onClick={change}
												className="eye_icon"
												// style={{ position: "absolute" }}
											>
												{" "}
												<VisibilityOffIcon />{" "}
											</i>
										) : (
											<i
												onClick={change}
												className="eye_icon"
												// style={{ position: "absolute" }}
											>
												{" "}
												<VisibilityIcon />{" "}
											</i>
										)}

										<input
											type={show ? "password" : "text"}
											id="form3Example4cg"
											className="form-control input-box "
											placeholder="Password"
											name="password"
											value={input.password}
											onChange={save}
											minLength={3}
											required
										/>
									</div>

									<div className="form-outline mb-3">
										<label
											className="ms-2 position-absolute"
											style={{ marginTop: "-0.12rem!important" }}
										>
											<span
												className="h6 small bg-white text-muted position-absolute "
												style={{
													marginTop: "-8px",
													marginLeft: "5px",
													fontSize: "165",
												}}
											>
												Country
											</span>
										</label>

										<select
											className="form-select form-control input-box mb-3 select-tag-input "
											aria-label=".form-select-lg example"
											name="country"
											// value={input.country }
											onChange={save}
										>
											{countryDetail?.map((item) => {
												return (
													<option name={item?.name} value={item?.name}>
														{item?.name}
													</option>
												);
											})}

											{/* <Countries></Countries> */}
										</select>
									</div>

									<div class="form-check d-flex  mb-3">
										<input
											className="form-check-input me-2 check-box-color"
											type="checkbox"
											value=""
											id="form2Example3cg"
											required
										/>

										<label
											className="form-check-label text-muted input-text"
											for="form2Example3g"
										>
											I agree to the Terms of Service
										</label>
									</div>

									<div className="row gx-0">
										<div className="d-grid gap-2 col-sm-12 mb-3">
											<button
												className="btn btn-primary button-effect"
												type="submit"
												// onClick={loader}
											>
												Sign Up
											</button>
										</div>
									</div>
									<hr></hr>

									<p className="text-center mt-4 mb-2 input-text">
										Already have an account?
									</p>

									<div className="d-grid gap-2 col-4 mx-auto d-flex justify-content-center">
										<Link to="/sign-in">
											<button
												className="btn btn-primary button-effect"
												type="button"
											>
												Log In
											</button>
										</Link>
									</div>
								</form>
							</div>
						</div>
					</div>
				</section>
				<Footer2></Footer2>
			</div>
		</>
	);
};
export default Signup;
