import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Logo from "../../assets/logo.svg";
import "./ForgetPassword.css";

const ForgetPassword = () => {
  const [field, setField] = useState({
    email: "",
  });
  const [error, setError] = useState("");

  let name, val;

  const store = (e) => {
    name = e.target.name;
    val = e.target.value;

    setField({ ...field, [name]: val });
    setError("");
  };

  const save = async (e) => {
    e.preventDefault();
    loader();

    const { email } = field;

    await fetch(`${process.env.REACT_APP_BASE_URL}api/auth/forget`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },

      body: JSON.stringify({
        email,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        // setGetresponse(response);
        if (!response?.success) {
          throw Error(response.message);
        }
        toast.success(response?.message);
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  const loader = () => {
    // alert("jai")
    // setLoading(true);
  };

  return (
    <>
      <div className="flex-wrapper-signin">
        <div className="container signIn" style={{ marginTop: "5rem" }}>
          <div className=" text-center pb-3">
            <Link className="" to="/">
              <img className="LogoImgs" src={Logo} alt="logo-img" />
            </Link>
          </div>
          <div className="row ">
            <div className="col-md-4 col-sm-12 offset-md-4 mb-3 border reset-Container">
              <h3 className="text-center mb-4 mt-4">Reset Your Password</h3>

              {/* {loading === true && !getresponse && (
                <Backdrop
                  sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
              )} */}

              <form onSubmit={save}>
                <div className="form-outline mb-3">
                  <label className="ms-2 ">Email</label>

                  <input
                    type="email"
                    id="form1Example1"
                    class="form-control py-3"
                    placeholder="Email"
                    name="email"
                    onChange={store}
                    value={field.email}
                    required
                  />
                </div>

                <span>{error && <p className="error">{error}!</p>}</span>
                <p>
                  Enter your email address and we will send you a link to reset
                  your password.
                </p>
                <div class="d-flex justify-content-between align-items-center mb-4">
                  <div className="submitbtn">
                    {/* {/ {{/ <Link to="/home"> /}  /} */}
                    <button
                      type="submit"
                      // onClick={loader}
                      className="btn btn-primary px-5 py-2 btn-sm resetbutton"
                    >
                      Send Password Reset Email
                    </button>{" "}
                    {/* {/ {/ </Link> /} /} */}
                  </div>
                </div>
              </form>
            </div>
            {/* <div className="col-md-4 col-sm-12 offset-md-4 d-flex flex-column align-items-center">
              <hr className="w-100 mb-4" />
             
              <Link to="/Signup">
                <button
                  type="submit"
                  class="btn btn-primary  px-5 py-2 btn-sm"
                >
                  Sign up
                </button>
              </Link>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgetPassword;
