import React, { useState } from "react";
import Header from "../Header/Header";
import "../About/About.css";
import { Helmet } from "react-helmet";
import Footer2 from "../Footer/Footer1";

export default function Terms() {
  const [seo, setSeo] = useState([]);

  return (
    <>
      {seo?.map((item) => {
        if (item?.pagename === "Invoice Guide") {
          return (
            <Helmet>
              <title>Invoice Guide | Easy Invoice</title>
              <meta name="description" content={item?.content} />
            </Helmet>
          );
        }
      })}
      <Header></Header>
      <section className="invoice-guide" style={{ marginTop: "6rem" }}>
        <div className="container lmt-5">
          <div className="row gx-0 pt-5 mt-5">
            <div className="col-sm-12 ">
              <div className="heading text-center">
                <div className="d-flex justify-content-center align-items-center">
                  <h1>
                    <span className="heading-text">
                      <span style={{ color: "black" }}>Terms</span> &{" "}
                      <span style={{ color: "black" }}>Conditions</span>
                    </span>
                    <h3 className="privacy-font mt-3">
                      Welcome to our invoice generator website! Before using our
                      service, we kindly ask you to read and agree to the
                      following terms and conditions.
                    </h3>
                  </h1>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-md-12 mt-5">
                  <h4 className="terms-heading">Agreement to Terms</h4>
                  <p className="text-left about-para ">
                    By using our invoice generator website, you agree to be
                    bound by these terms and conditions, as well as any
                    applicable laws and regulations.
                  </p>
                  <h4 className="terms-heading">Use of Service</h4>
                  <p className="text-left about-para ">
                    Our invoice generator website provides a service to create
                    and send professional invoices. You may only use this
                    service for lawful purposes and in accordance with these
                    terms and conditions.
                  </p>
                  <h4 className="terms-heading">User Accounts</h4>
                  <p className="text-left about-para ">
                    To use our invoice generator website, you may be required to
                    create an account. You are responsible for maintaining the
                    confidentiality of your account information, including your
                    username and password. You agree to notify us immediately of
                    any unauthorized access to your account.
                  </p>
                  <h4 className="terms-heading">Intellectual Property</h4>
                  <p className="text-left about-para ">
                    Our invoice generator website and all of its contents,
                    including but not limited to text, graphics, images, logos,
                    and software, are the property of our company or our content
                    suppliers and are protected by intellectual property laws.
                  </p>
                  <h4 className="terms-heading">User Content</h4>
                  <p className="text-left about-para ">
                    By using our invoice generator website, you agree to allow
                    us to use any content you provide in the creation of your
                    invoices, such as your business name and logo.
                  </p>
                  <h4 className="terms-heading">Disclaimer of Warranties</h4>
                  <p className="text-left about-para ">
                    Our invoice generator website and all of its contents are
                    provided "as is" without any warranties, express or implied.
                    We make no warranties or representations regarding the
                    accuracy or completeness of the content on our website.
                  </p>
                  <h4 className="terms-heading">Limitation of Liability</h4>
                  <p className="text-left about-para ">
                    Our company and its affiliates, directors, officers,
                    employees, and agents shall not be liable for any damages,
                    including but not limited to direct, indirect, incidental,
                    consequential, or punitive damages, arising from the use or
                    inability to use our invoice generator website.{" "}
                  </p>
                  <h4 className="terms-heading">Indemnification</h4>
                  <p className="text-left about-para ">
                    You agree to indemnify and hold our company and its
                    affiliates, directors, officers, employees, and agents
                    harmless from any claims, damages, expenses, or losses,
                    including reasonable attorney's fees, arising from your use
                    of our invoice generator website.{" "}
                  </p>
                  <h4 className="terms-heading">
                    Governing Law and Jurisdiction
                  </h4>
                  <p className="text-left about-para ">
                    These terms and conditions shall be governed by and
                    construed in accordance with the laws of the state in which
                    our company is located. Any dispute arising from these terms
                    and conditions shall be resolved exclusively in the courts
                    located in that state.
                  </p>
                  <h4 className="terms-heading">Indemnification</h4>
                  <p className="text-left about-para ">
                    You agree to indemnify and hold our company and its
                    affiliates, directors, officers, employees, and agents
                    harmless from any claims, damages, expenses, or losses,
                    including reasonable attorney's fees, arising from your use
                    of our invoice generator website.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="heading text-center mt-5">
            <div className="d-flex justify-content-center align-items-center">
              <h1>
                <h4 className="extra-font">Thanks For Visiting Our Site</h4>
                <h4 className="heading-text">Have a nice day !</h4>
              </h1>
            </div>
          </div>
        </div>
      </section>
      <Footer2></Footer2>
    </>
  );
}
