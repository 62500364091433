import React from "react";
import { Link } from "react-router-dom";
import "./Footer1.css";

function Footer2() {
  return (
    <div className="footer__container mb-1">
      <hr
        className="mb-4"
        style={{
          marginLeft: "0.6rem",
          marginRight: "0.6rem",
          // fontWeight: "bold",
        }}
      />
      <div className="col-12 container p-0 "></div>
      <div
        className="wrapper_container container mb-4 "
        // style={{ height: "10vh", backgroundColor: "red" }}
      >
        <div className="row d-flex  justify-content-center align-items-center  ">
          <div className="col-6 footer_link d-flex  justify-content-start text-left px-2  ">
            <div>
              <span className="col-6 w-100 ">
                2023 © Easy Invoice All Rights Reserved.
              </span>
            </div>
          </div>

          <div className="col-6 d-flex justify-content-end align-items-center   ">
            <div className="row  ">
              <div className="col-12 ">
                <span className=" footer_link term_and_condition">
                  <Link
                    to="/terms-condition"
                    style={{ color: "black", textDecoration: "none" }}
                  >
                    Terms & Conditions{" "}
                  </Link>
                </span>
                <span className=" footer_link   ms-3 bg-white  ">
                  <Link
                    to="/privacy-policy"
                    style={{ color: "black", textDecoration: "none" }}
                  >
                    Privacy Policy
                  </Link>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer2;
