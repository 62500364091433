import React, { useEffect, useState } from "react";

// Import component
import InvoiceGuide from "./component/Invoice Guide/InvoiceGuide";
import InvoiceDownloader from "./InvoiceDownloader/InvoiceDownloader";
import Notfound from "./component/Notfound/Notfound";
import Home from "./component/Home/Home";
import Signin from "./component/Signin/Signin";
import ForgetPassword from "./component/ForgetPassword/ForgetPassword";
import { Helmet } from "react-helmet";
//Routes
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
  useLocation,
  useParams,
} from "react-router-dom";
import { useLayoutEffect } from "react";

import "./App.css";
import Myaccount from "./component/Myaccount/Myaccount";
import Signup from "./component/Signup/Signup";
import Setting from "./component/Setting/Setting";
import Documents from "./component/Documents/Documents";
import Dashboard from "./component/Dashboard/Dashboard";
import Verify from "./component/Verify/Verify";
import ResetPassword from "./component/ResetPassword/ResetPassword";
import EditInvoiceModal from "./component/Documents/EditInvoiceModal";

import ProtectedRoute from "./component/ProtectedRoute/ProtectedRoutes";
import { ToastContainer } from "react-toastify";
import Htmlformat from "./InvoiceDownloader/Htmlformat";
import History from "./component/History/History";
import UserList from "./component/UserList/UserList";
import Seo from "./component/Seo/Seo";
import About from "./component/About/About";
import Contact from "./component/Contact/Contact";
import Terms from "./component/Terms/Terms";
import Privacy from "./component/Privacy/Privacy";

function App() {
  const [AdminRole, setAdminRole] = useState();
  const getIdValue = () => {
    const ids = localStorage.getItem("ids") || 1;
    return ids;
  };

  useEffect(() => {
    const Admin = JSON.parse(localStorage.getItem("Admin"));
    if (Admin === 1) {
      setAdminRole(Admin);
    } else {
      setAdminRole(0);
    }
  }, [AdminRole]);

  const Wrapper = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children;
  };

  return (
    <>
      <div className="">
        {/* <Header /> */}
        <ToastContainer
          position="top-right"
          autoClose={1000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

        <Helmet>
          <title>
            Online Invoice Generator Tool | Free Online Billing Software
          </title>
          <meta name="description" content="You can create Invoice" />
        </Helmet>
        <Wrapper>
          <Routes>
            <Route element={<ProtectedRoute />}>
              <Route path="/my-account" element={<Myaccount />} />
              <Route path="/setting" element={<Setting />} />
              <Route path="/invoice" element={<Documents />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/user-list" element={<UserList />} />
              <Route path="/seo" element={<Seo />} />
              <Route path="/edit-invoice" element={<EditInvoiceModal />} />
              <Route path="/*" element={<Notfound />} />
            </Route>

            <Route path="/" element={<Home />} />
            <Route path="/sign-in" element={<Signin />} />
            <Route path="/forget-pass" element={<ResetPassword />} />
            <Route path="/forget-password" element={<ForgetPassword />} />
            <Route path="/invoice-guide" element={<InvoiceGuide />} />
            <Route path="/about-us" element={<About />} />
            <Route path="/contact-us" element={<Contact />} />
            <Route path="/terms-condition" element={<Terms />} />
            <Route path="/privacy-policy" element={<Privacy />} />
            <Route path="/signup" element={<Signup />} />
            <Route
              path={`/invoice-downloader`}
              element={<Navigate to={`/invoice-downloader/${getIdValue()}`} />}
            />
            <Route
              path={`/invoice-downloader/:id`}
              element={<InvoiceDownloader />}
            />
            <Route path="/verify-email" element={<Verify />} />
            <Route path="/history" element={<History />} />
          </Routes>
        </Wrapper>
        {/* <Footer /> */}
      </div>
    </>
  );
}

export default App;
