import { SIGN_IN, SIGN_UP, LOGOUT_ACTION } from "../actionType/actionType";
import SignIn from "../../API/Manager/SignIn";
// import Logoutapi from "../../API/Manager/Logout";

export function loginAction(email, password, navigate) {
  const params = { email, password };
  return async (dispatch) => {
    const data = await SignIn(params);
    dispatch(loginConfirmedAction(data[0]?.data[0]));
    localStorage.setItem("auth", JSON.stringify(data[0]));
    localStorage.setItem("token", `Bearer ${JSON.stringify(data[0]?.data[0]?.token)}`);
    navigate("/");
  };
}

// export function signUpAction(
//   name,
//   email,
//   password,
//   country,
//   organisationName,
//   navigate
// ) {
//   const params = {};
// }

export function loginConfirmedAction(data) {
  return {
    type: SIGN_IN,
    payload: data,
  };
}

export function signupConfirmAction(data) {
  return {
    type: SIGN_UP,
    payload: data,
  };
}

export function logout(navigate) {
  localStorage.removeItem("auth");
  localStorage.removeItem("token");
  localStorage.removeItem("Admin");
  navigate("/sign-in");
  return {
    type: LOGOUT_ACTION,
  };
}
