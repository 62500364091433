import {
  ADD_INVOICE_DATA,
  DELETE_INVOICE_DATA,
  DOWNLOAD_PDF_ACTION,
  EDIT_INVOICE_DATA,
  HANDLE_INVOICE_DATA,
  HANDLE_INVOICE_CALCULATION,
  UPLOAD_LOGO,
  TABLE_DATA,
  HANDLE_IMAGE_DATA,
  HANDLE_INVOICE,
  NEW_INVOICE,
  SETTING_LOGO_UPLOAD,
  DELETE_EDIT_INVOICE_MODAL_DATA,
  EDIT_INVOICE_MODAL_DATA,
  POP_UP_ACTION,
  ADD_EDIT_INVOICE_MODAL_DATA
} from "../actionType/actionType";

const initialState = {
  invoiceList: {
    invoiceItems: [{ item: "", quantity: 1, rate: 0, amount: 0 }],
    subtotal: 0,
    logoLink: "",
    settingLogo: "",
  },
  errorMessage: "",
  successMessage: "",
};

const InvoiceReducer = (state = initialState, action) => {
	console.log(action.type, "kaykru");
	switch (action.type) {
		// case HANDLE_INVOICE:
		//   // console.log(action.payload);
		//   // debugger;
		//   return {
		//     ...state,
		//     invoiceList: {
		//       ...state.invoiceList,
		//       invoiceItems: action.payload,
		//     },
		//   };

		case HANDLE_IMAGE_DATA:
			return {
				...state,
				invoiceList: {
					...state.invoiceList,
					logoLink: action.payload,
				},
			};

		case HANDLE_INVOICE_DATA:
			// console.log(action?.payload, "weewewe");
			// if (state?.invoiceList === action.payload.keyName) {
			//   alert("hello");
			//   console.log(state?.invoiceList === action.payload.keyName, "hello");
			// }
			// debugger
			return {
				...state,
				invoiceList: {
					...state.invoiceList,
					invoiceItems: action.payload.invoiceItems,
				},
			};

    case HANDLE_INVOICE_CALCULATION:
      // console.log(action.payload, "HANDLE_INVOICE_CALCULATION");
      return {
        ...state,
        invoiceList: {
          ...state.invoiceList,
          // backgroundColor: action?.payload?.backgroundColor,
          color: {
            light: action?.payload?.selectedColor?.light,
            dark: action?.payload?.selectedColor?.dark,
          },
          textColor: {
            dark: action?.payload?.selectedTextColor?.dark,
            light: action?.payload?.selectedTextColor?.light,
          },
		  taxInput: action?.payload?.newInputList,
          hideDiscountBox: action?.payload?.hideDiscountBox,
          hideShipBox: action?.payload?.hideShipBox,
          taxStatus: action?.payload?.taxStatus,
          isDiscountPercent: action?.payload?.discountState,
          isTaxPercent: action?.payload?.taxState,
          dragLogo: action?.payload?.file,
          discountState: action?.payload?.discountState,
          taxState: action?.payload?.taxState,
          taxAmount: {
            key: action?.payload?.tax?.key,
            value: action?.payload?.taxValue,
          },

					discountAmount: {
						key: action?.payload?.discount?.key,
						value: action?.payload?.discountValue,
					},

					symbol: action?.payload?.countrySymbol,
					companyName: action?.payload?.companyName,
					title: action?.payload?.title,
					userId: action?.payload?.user?._id,

					invoiceItemHeader: {
						item: action?.payload?.invoiceItemHeader?.item,
						amount: action?.payload?.invoiceItemHeader?.amount,
						quantity: action?.payload?.invoiceItemHeader?.quantity,
						rate: action?.payload?.invoiceItemHeader?.rate,
					},

					billTo: {
						key: action?.payload?.billTo?.key,
						value: action?.payload?.billTo?.value,
					},

					shipTo: {
						key: action?.payload?.shipTo?.key,
						value: action?.payload?.shipTo?.value,
					},

					invoiceId: {
						key: action?.payload?.invoiceId?.key,
						value: action?.payload?.invoiceId?.value,
					},

					date: {
						key: action?.payload?.date?.key,
						value: action?.payload?.date?.value,
					},

					dueDate: {
						key: action?.payload?.dueDate?.key,
						value: action?.payload?.dueDate?.value,
					},

					paymentTerms: {
						key: action?.payload?.paymentTerms?.key,
						value: action?.payload?.paymentTerms?.value,
					},

					poNumber: {
						key: action?.payload?.poNumber?.key,
						value: action?.payload?.poNumber?.value,
					},

					notes: {
						key: action?.payload?.notes?.key,
						value: action?.payload?.notes?.value,
					},

					termsAndDescription: {
						key: action?.payload?.termsAndDescription?.key,
						value: action?.payload?.termsAndDescription?.value,
					},

					amountPaid: {
						key: action?.payload?.amountPaid?.key,
						value: action?.payload?.amountPaid?.value,
					},

					subtotal: {
						key: action?.payload?.subtotal?.key,
						value: action?.payload?.subtotal?.value,
					},

					total: {
						key: action?.payload?.total?.key,
						value: action?.payload?.total?.value,
					},
					balanceDue: {
						key: action?.payload?.balance?.key,
						value: action?.payload?.balance?.value,
					},
					discountPercent: {
						key: action?.payload?.discount?.key,
						value: action?.payload?.discount?.value,
					},
					shipping: {
						key: action?.payload?.shipping?.key,
						value: action?.payload?.shipping?.value,
					},
					taxPercent: {
						key: action?.payload?.tax?.key,
						value: action?.payload?.tax?.value,
					},
				},
			};

		case ADD_INVOICE_DATA:
			// console.log(state?.invoiceList, action.payload, "payload");
			let amount = action.payload.quantity * action.payload.rate;
			let subtotal = amount;
			console.log(subtotal);
			{
				state?.invoiceList?.invoiceItems?.map(
					(val) => (subtotal += val.amount),
				);
			}
			return {
				...state,
				invoiceList: {
					...state.invoiceList,

					subtotal: subtotal,
					invoiceItems: [
						...state.invoiceList.invoiceItems,
						{
							item: action.payload.items,
							quantity: action.payload.quantity,
							rate: action.payload.rate,
							amount: amount,
							id: action.payload.id,
						},
					],
				},
			};


			case ADD_EDIT_INVOICE_MODAL_DATA:
				// console.log(state?.invoiceList, action.payload, "payload");
				let editAmount = action.payload.quantity * action.payload.rate;
				let editSubtotal = editAmount;
				// console.log(editSubtotal);
				{
					state?.invoiceList?.invoiceItems?.map(
						(val) => (editSubtotal += val.amount),
					);
				}
				return {
					...state,
					invoiceList: {
						...state.invoiceList,
	
						subtotal: editSubtotal,
						invoiceItems: [
							...state.invoiceList.invoiceItems,
							{
								item: action.payload.items,
								quantity: action.payload.quantity,
								rate: action.payload.rate,
								amount: editAmount,
								id: action.payload.id,
							},
						],
					},
				};

		case DELETE_INVOICE_DATA:
			// console.log(state?.invoiceList, "payload");
			const newInvoiceList = state?.invoiceList?.invoiceItems?.filter(
				(del) => del.id !== action.payload,
			);

      return {
        ...state,
        invoiceList: {
          ...state.invoiceList,
          invoiceItems: newInvoiceList,
        },
      };
    case DELETE_EDIT_INVOICE_MODAL_DATA:
      // console.log(state?.invoiceList, "payload");
      const newInvoiceModalList = state?.invoiceList?.invoiceItems?.filter(
        (del) => del.id !== action.payload
      );

      return {
        ...state,
        invoiceList: {
          ...state.invoiceList,
          invoiceItems: newInvoiceModalList,
        },
      };
    
      
		

		case UPLOAD_LOGO:
			// console.log(action.payload, "payload====");

			const logo = action.payload.path;
			// debugger;
			return {
				...state,
				invoiceList: {
					...state.invoiceList,
					logoLink: logo,
				},
			};

		case SETTING_LOGO_UPLOAD:
			const settinglogo = action.payload.path;
			return {
				...state,
				invoiceList: {
					...state.invoiceList,
					settingLogo: settinglogo,
				},
			};

		case DOWNLOAD_PDF_ACTION:
			// console.log(action.payload, "download pdf");
			return {
				...state,
				invoiceList: {
					...state.invoiceList,
				},
				errorMessage: action.payload,
				successMessage: action.payload,
			};

		case POP_UP_ACTION:
			console.log(action.payload, "apna");
			return {
				...state,
				invoiceList: {
					...state.invoiceList,
				},
				popUpMessage: action.payload,
			};

		case TABLE_DATA:
			// console.log(action.payload.names, action.payload.values, "mmmmmm");
			const { names, values, amounts } = action.payload;
			// console.log(names, values, "pppp");
			return {
				...state,
				invoiceList: {
					...state.invoiceList,
					invoiceItems: [
						...state.invoiceList.invoiceItems,
						{
							[names]: values,
							amount: amounts,
						},
					],
				},
			};
		case EDIT_INVOICE_DATA:
			const { name, value, index } = action.payload;
			// console.log(action.payload, "sfseweewew");
			const data = state?.invoiceList?.invoiceItems?.map((item) => {
				return item.id === index ? { ...item, [name]: value } : item;
			});
			const indexValue = state?.invoiceList?.invoiceItems?.findIndex(
				(data) => data.id === index,
			);

			let x = data[indexValue].quantity * data[indexValue].rate;
			data[indexValue].amount = x;
			// debugger
			// const newEditInvoiceList = { item, quantity, rate, amount: x };

			// console.log(indexValue, x, "indexvalue");
			// const editInvoiceList = state?.invoiceList?.invoiceItem.findIndex(
			//   (edit) => edit.id === action.payload.id
			// );

			// const updateInvoiceList = [...state?.invoiceList?.invoiceItem];
			// updateInvoiceList[editInvoiceList] = {
			//   ...state.invoiceList.invoiceItem[editInvoiceList],
			//   rate: action.payload.rate,
			//   quantity: action.payload.quantity,
			//   amount: action.payload.amount,
			//   items: action.payload.items,
			// };
			return {
				// ...state,
				// invoiceList: {
				//   ...state.invoiceList,
				//   invoiceItem: updateInvoiceList,
				// },
				...state,
				invoiceList: {
					...state.invoiceList,
					invoiceItems: data,
				},
			};

    case EDIT_INVOICE_MODAL_DATA:
      const { nameEdit, valueEdit, indexs } = action.payload;
      const datas = state?.invoiceList?.invoiceItems?.map((item) => {
        return item.id === indexs ? { ...item, [nameEdit]: valueEdit } : item;
      });
      const indexValues = state?.invoiceList?.invoiceItems?.findIndex(
        (data) => data.id === indexs
      );

      let updateItems = datas[indexValues].quantity * datas[indexValues].rate;
      datas[indexValues].amount = updateItems;
      return {
        ...state,
        invoiceList: {
          ...state.invoiceList,
          invoiceItems: datas,
        },
      };

    case NEW_INVOICE:
      // console.log(action.payload, "sfewfsdfwe-");
      // alert("newinvoice")
      // debugger
      return {
        ...state,
        invoiceList: {
          ...state.invoiceList,
          logoLink: "",
          invoiceItems: [{ item: "", quantity: 1, rate: 0, amount: 0 }],
        },
      };

		default:
			return state;
	}
};

export default InvoiceReducer;
