import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { newInvoiceTableData } from "../../InvoiceRedux/action/action";

import "./Home.css";
import pdfIcon from "../../assets/pdf-icon.svg";
import atImage from "../../assets/email.svg";
import paidImg from "../../assets/paid.svg";
import statusImg from "../../assets/clock.svg";
import Animation from "../Animation/Animation";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Footer2 from "../Footer/Footer1";
import { Helmet } from "react-helmet";
import HowDoesItWork from "../../assets/howDoesItWork.png";
import InvoiceCustomerImg from "../../assets/invoice-customerImg.png";
import InvoiceBulletImg from "../../assets/invoiceBullet.png";
import CheckListImage from "../../assets/invoiceCHecklistPng.png";
import FeaturedImg1 from "../../assets/featured1Img.png";
import FeaturedImg2 from "../../assets/featured2Img.png";
import FeaturedImg3 from "../../assets/featured3Img.png";
import FeaturedImg4 from "../../assets/featured4Img.png";
import FeaturedImg5 from "../../assets/featured5Img.png";
import FeaturedImg6 from "../../assets/featured6Img.png";
import FeaturedImg7 from "../../assets/featured7Img.png";
import FeaturedImg8 from "../../assets/featured8Img.png";
import FeaturedImg9 from "../../assets/featured9Img.png";
import FeaturedImg10 from "../../assets/featured10Img.png";
// import FeaturedImg11 from '../../assets/featured11Img.png'
import FeaturedImg12 from "../../assets/featured12Img.png";
import FeaturedImg13 from "../../assets/featured13Img.png";
import FeaturedImg14 from "../../assets/featured14Img.png";
import FeaturedImg15 from "../../assets/featured15Img.png";
import FeaturedImg16 from "../../assets/featured16Img.png";
import FeaturedImg18 from "../../assets/featured18Img.png";
import FaqSection from "../FAQ/FaqSection";

const checkListBulletPoint = [
  "Brand Your Invoice",
  "Add A Professional Header",
  "Include Invoice Information",
  "Include The Dates",
  "Description Of Goods/Services Delivered",
  "Include Tax Details And Highlight The Total Money Owed",
  "Include Payment Terms & Conditions",
];

const featuredArray = [
  {
    id: 0,
    img: FeaturedImg1,
    heading: "Streamlined Invoicing Process",
    description:
      "An online invoice generator simplifies the invoicing process, eliminating the need for manual data entry and repetitive tasks.",
  },
  {
    id: 1,
    img: FeaturedImg3,
    heading: "Professional And Customizable Invoices",
    description:
      "A well-designed invoice enhances your brand image and professionalism.",
  },
  {
    id: 2,
    img: FeaturedImg5,
    heading: "Automation And Time-Saving",
    description:
      "Advantages of using an online GST invoice generator is the automation it provides. The system stores this information securely, allowing you to access it with ease.",
  },
  {
    id: 3,
    img: FeaturedImg7,
    heading: "Accurate Financial Tracking",
    description:
      "Online invoice generators provide a central dashboard where you can monitor and manage all your invoices effortlessly.",
  },
  {
    id: 4,
    img: FeaturedImg9,
    heading: "Payment Integration And Faster Payments",
    description:
      "This integration enables your clients to make payments directly from the invoice, eliminating the need for manual bank transfers or paper checks.",
  },
  {
    id: 5,
    img: FeaturedImg14,
    heading: "Data Security And Backup",
    description:
      "Online invoice generators employ advanced security measures to safeguard your data, including encryption and secure servers.",
  },
  {
    id: 6,
    img: FeaturedImg13,
    heading: "Multiple Business Invoice Templates",
    description:
      "Make professional invoices by using the online invoice maker. Discover an extensive collection of templates and layouts that you can use to create your own.",
  },
  {
    id: 7,
    img: FeaturedImg16,
    heading: "Download Invoice In Any Format",
    description:
      "Easy Invoice tool has the facility to download the generated receipt in multiple formats.",
  },
  {
    id: 8,
    img: FeaturedImg18,
    heading: "Direct Mail Facility",
    description:
      "By using this system you can directly mail the invoice to the customer. It saves your time downloading and then sending an invoice through mail.",
  },
];

function Home() {
  const dispatch = useDispatch();
  let newNumberList;
  const navigate = useNavigate();
  const [id, setId] = useState(increaseNumber());
  const [number, setNumber] = useState(newNumbers());

  function increaseNumber() {
    return JSON.parse(localStorage.getItem("ids")) || 0;
  }

  function newNumbers() {
    return (newNumberList =
      JSON.parse(localStorage.getItem("idCollection")) || []);
  }

  const CreateInvoice = () => {
    const invoiceitems = JSON.parse(localStorage.getItem(`adminProfile${id}`));
    let id2;
    if (invoiceitems?.invoiceGenerator?.billTo?.value === "") {
      id2 = id;
      setId(id2);
      localStorage.setItem("ids", id2);
    } else {
      id2 = id + 1;
      setId(id2);
      localStorage.setItem("ids", id2);
    }

    let newNumber = [...newNumberList, id2];
    localStorage.setItem("idCollection", JSON.stringify(newNumber));
    setNumber(newNumber);
    dispatch(newInvoiceTableData());
    navigate(`/invoice-Downloader/${id2}`);
  };
  const [seo, setSeo] = useState([]);
  useEffect(() => {
    const seoApi = async () => {
      await fetch(`${process.env.REACT_APP_BASE_URL}api/util/get-meta`, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: JSON.parse(localStorage.getItem("token")),
        },
      })
        .then((res) => res.json())
        .then((res) => {
          setSeo(res?.data);
        });
    };
    seoApi();
  }, []);
  return (
    <>
      <div className="flex-wrapper-home">
        {seo?.map((item) => {
          if (item?.pagename === "Home") {
            return (
              <Helmet>
                {/* <title>
                  Online Invoice Generator Tool | Free Online Billing Software
                </title>
                <meta
                  name="description"
                  content="Generate professional invoices and manage your billing effortlessly with our free online billing software. Try our
      user-friendly online invoice generator tool today and streamline your invoicing process. Sign up now!"
                /> */}
                {/* <meta name="twitter:description" content={item?.description} />
                <meta name="twitter:title" content={item?.title} />
                <meta name="twitter:image" content={item?.image} /> */}

                {/* <meta property="og:locale" content="en_US" />
                <meta
                  property="og:type"
                  content="website - Online Invoice Generator Tool"
                />
                <meta
                  property="og:title"
                  content="Online Invoice Generator Tool | Free Online Billing Software"
                />
                <meta
                  property="og:description"
                  content="Generate professional invoices and manage your billing effortlessly with our free online billing software. Try our user-friendly online invoice generator tool today and streamline your invoicing process. Sign up now!"
                />
                <meta
                  property="og:url"
                  content="https://ezinvoicegenerator.com/"
                />
                <meta
                  property="og:site_name"
                  content="Online GST Invoice Generator Tool | Free Online Receipt Download Software"
                />
                <meta name="twitter:card" content="summary_large_image" />
                <meta
                  name="twitter:description"
                  content="Generate professional invoices and manage your billing effortlessly with our free online billing software. Try our user-friendly online invoice generator tool today and streamline your invoicing process. Sign up now"
                />
                <meta
                  name="twitter:title"
                  content="Online Invoice Generator Tool | Free Online Billing Software - EZ Invoice Generator"
                />
                <link rel="canonical" href="https://ezinvoicegenerator.com/" /> */}

                <script crossorigin="anonymous" async>
                  {`${item?.headerScript}`}
                </script>
                <script crossorigin="anonymous" async>
                  {`${item?.footerScript}`}
                </script>
              </Helmet>
            );
          }
        })}
        {/* <Helmet>
          <title>Home | Easy Invoice</title>
          <meta name="description" content={seo[1]?.content} />
        </Helmet> */}
        <Header></Header>
        <div className="container text-center" style={{ marginTop: "5rem" }}>
          <div className="row">
            <div className="heading text-center">
              <h1 className="mt-4">
                Online <span className="Invoice">Invoice </span> Generator
              </h1>
              <p>Create & download invoices for free</p>
              {/* <Link to="/invoice-downloader"> */}
              {/* <button onClick={CreateInvoice}> */}

              <div className="btn btnClr mb-3" onClick={CreateInvoice}>
                Create new invoice
              </div>
              {/* </button> */}
              {/* </Link> */}

              <div className="mb-4 d-flex align-items-center">
                <Animation />
              </div>
            </div>
          </div>
          <div className="eInvoiceInfo row">
            <h3 className="mb-3">Why E-invoicing?</h3>
            <div className="col-md-4 text-start p-3 pe-5">
              <img
                className="mb-2"
                style={{ width: "1.5rem" }}
                src={atImage}
                alt="Online E-Invoice Tool"
                srcSet=""
              />
              <h5>Better than email</h5>
              <p className="">
                With e-invoicing software your invoices no longer get lost in
                someone's email inbox.
              </p>
            </div>
            <div className="col-md-4 text-start p-3 pe-5">
              <img
                className="mb-2"
                style={{ width: "3rem" }}
                src={paidImg}
                alt="E-Invoice software,"
                srcSet=""
              />
              <h5>Fastest way to get paid</h5>
              <p>
                Online E-invoicing tool is the fastest, most accurate, and
                cost-effective way to send, process, and validate invoices.
              </p>
            </div>
            <div className="col-md-4 text-start p-3 pe-5">
              <img
                className="mb-2"
                style={{ width: "1.5rem" }}
                src={statusImg}
                alt="Online E-Invoice Tool"
                srcSet=""
              />
              <h5>Invoice status updates</h5>
              <p>
                Both buyer and seller have real-time visibility into the status
                of invoices.
              </p>
            </div>
          </div>

          {/* ali start */}
          <div className="row">
            <div className="col-12 startWithDiv">
              <h2 className="mb-3">
                Start With <span className="free-tag">Free</span> Online Invoice
                Generator
              </h2>
              <p>
                Easy Invoice is a professional online invoice generator tool and
                that helps your work and business by providing a fast and
                effective way to create professional invoices in a few minutes
                and also it saves time and money. Have a perfect solution for
                small business owners who are required to generate{" "}
                <b style={{ color: "#2155cd" }}>online GST invoices</b> in a
                fast and efficient manner, so in this case, an online invoice
                generator tool will help you.
              </p>
              <p>
                With our best invoice interface, big and small business owners
                can create a GST bill and no need to worry about complex
                calculations, boring and lasting paper work. Our Easy Invoice
                Generator Software has completely customised options, so that
                users can generate invoices as per their requirement. So it
                helps to grow your business.
              </p>
            </div>
          </div>
          {/*   HOW DOES IT WORK SECTION */}
          <div className="row">
            <div className="col-12 sectionPadding">
              <img
                src={HowDoesItWork}
                alt="Easy Invoice - Free online Invoice Generator Tool"
                className="how-does-it-work-img"
              />
            </div>
          </div>

          <div className="row sectionPadding">
            <div className="text col-md-8">
              <h3 className="text-start generate-unlimited-text-heading mb-3">
                Generate unlimited invoices & PDF download
              </h3>
              <p className="text-start">
                Using our online invoice maker, you can generate an unlimited
                number of invoices and download them as PDF without having to
                pay extra. Free Invoice Builder will help you to make invoices
                in the correct format with all essential elements. Plus, you can
                easily help you save, track and manage all your invoices &
                contacts, so you’ll never lose or misplace an invoice and
                clients business details.
              </p>
            </div>
            <div className="pdfImg col-md-4 d-flex justify-content-center text align-items-center">
              <img
                src={pdfIcon}
                style={{ width: "6rem" }}
                alt="Download Free Invoice"
              />
            </div>
          </div>
          {/* CREATE PROFESSIONAL SECTION */}
          {/* <div className="container d-flex justify-content-center align-items-center"> */}
          <div className="row createProfessionalDiv">
            <div className="col-md-5 col-12 ">
              <div className="d-flex justify-content-center align-items-center w-100 ">
                <img
                  src={InvoiceCustomerImg}
                  alt="Create professional invoice"
                  className="invoice-customer-img"
                />
              </div>
            </div>
            <div className="col-md-7 col-12 create-professional-section-container">
              <h2 className=" create-professional-section-container-heading mb-3">
                Create Professional Invoice{" "}
                <span className="for-customer">For Customers</span>
              </h2>
              <p style={{ textAlign: "left" }}>
                In this invoice tool you can Create professional invoices for
                your customers, by using an easy invoice tool you can create a
                healthy business relationship and ensure timely payments. Our
                easy invoice generator tool has unique and complete professional
                features like user-friendly interface, customizable templates,{" "}
                <b style={{ color: "#2155cd" }}>download PDF invoice </b>
                options and easy payment options. By utilising our easy invoice
                platform, you can generate accurate and detailed invoices in a
                minute, by creating a professional invoice you focus on growing
                your business and maintaining a professional image.
              </p>
              <p style={{ textAlign: "left" }}>
                In this <b> Online invoice tool </b> there is a customization
                option that you can change like - <br />
                ● Add Company Logo. <br />
                ● Select colors that represent your brand. <br />
                ● Customize the layout. <br />● Font styles to create a cohesive
                and visually appealing invoice.
              </p>
            </div>
          </div>
          {/* </div> */}

          <div className="access-invoice-text-container">
            <div className="row">
              <div className="col-12 accessYourDiv">
                <h2 className="mb-3" style={{ textAlign: "left" }}>
                  Access Your <span className="free-tag">Invoices</span>{" "}
                  Anywhere, Anytime
                </h2>
                <p style={{ textAlign: "left" }}>
                  Anywhere, at any time, you may access your invoices! Our
                  online invoicing tool makes it simple and cost-free to
                  generate and modify invoices, GST bills, and other documents.
                  For added convenience, you can download your invoices in a
                  variety of formats from the internet. You can manage all of
                  your invoicing requirements with ease because of the way our
                  billing software is structured. You can{" "}
                  <b>generate accurate bills</b> quickly and save time while
                  doing so with the help of our software. All of your records
                  are trackable from anywhere.
                </p>
                <p style={{ textAlign: "left" }}>
                  You may easily download and freely customise your billing
                  software using our online invoicing platform.
                </p>
              </div>
            </div>
          </div>

          {/* CLIENT CREATE */}

          <div className="access-invoice-text-container">
            <div className="row sectionPadding">
              <div className="col-12">
                <h2 className="mb-3">
                  For Clients Create
                  <span className="free-tag"> Custom Invoice</span>
                </h2>
                {/* <p className="custom-invoice-text">Custom Invoice</p> */}
                <p>
                  Enabling customers to generate personalised invoices is an
                  excellent method to boost their invoicing experience and
                  foster strong business connections. Allowing clients to
                  customize their invoices in line with their individual
                  preferences affords them with an experience of flexibleness,
                  private interest, and authority that complements their
                  pleasure and loyalty. Utilize this chance to enable your
                  customers and supply them with a clean and person-pleasant
                  invoicing system. By presenting custom invoice creation, you
                  no longer only simplify their operations but also suggest your
                  commitment to an assembly of their one-of-a-kind enterprise
                  needs.
                </p>
              </div>
              <div className="col-12 col-md-6 mt-2">
                <h3 className="customize-feature-text">
                  Things You Get Customize Features In Online Billing Software
                </h3>
                <div className="checklist-img-and-text-container">
                  {checkListBulletPoint?.map((eachItem) => (
                    <div className="bullet-point">
                      <img
                        src={InvoiceBulletImg}
                        alt="Online Billing Sof"
                        className="bullet-point-image"
                      />
                      <h4 className="each-bullet-point-heading">{eachItem}</h4>
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-12 col-md-6">
                <img src={CheckListImage} className="invoice-bullet-image" />
              </div>
            </div>
          </div>

          <div className="row sectionPadding">
            <h2 className="mb-3" style={{ textAlign: "left" }}>
              Easy & Quick GST{" "}
              <span className="free-tag">Invoice Generator</span>
            </h2>
            <p style={{ textAlign: "left" }}>
              Easy Invoice is an entirely user-friendly online GST invoice
              generator online software. Here, you may quickly and easily create
              invoices of the highest calibre. You can quickly and
              cost-effectively create professional GST invoices with this free
              online receipt generator. You are able to generate correct
              invoices for your clients with this tool. Here, many{" "}
              <b>invoice templates</b> are available for various types of
              business to make it simple for you to change or customize as per
              your requirement.
            </p>
          </div>

          <div className="sectionPadding row">
            <h2 className="mb-3">
              Features of Easy{" "}
              <span className="free-tag">Invoice Generator</span>
            </h2>
            <div className="">
              <div
                className="main-card-stream "
                style={{ display: "flex", flexWrap: "wrap" }}
              >
                {featuredArray?.map((eachItem) => (
                  <div className="d-flex flex-wrap mb-2 each-card col-12 col-md-4">
                    <div className="card-streamline px-2">
                      <img
                        src={eachItem?.img}
                        className="card-strem-img mb-2"
                        alt={eachItem.heading}
                      />
                      <h4 className="streamline-heading">
                        {eachItem?.heading}
                      </h4>
                      <p className="streamline-desc">{eachItem?.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="row sectionPadding">
            <h3 className="mb-3">
              Frequently Asked <span className="free-tag">Questions</span>
            </h3>
            <FaqSection />
          </div>

          {/* ali end */}
        </div>
        <Footer2></Footer2>
      </div>
    </>
  );
}

export default Home;
