import React, { useState, useEffect } from "react";
import "./About.css";
// import "../Dashboard/Dashboard.css";

import { useNavigate } from "react-router-dom";
import Header from "../Header/Header";
import Footer2 from "../Footer/Footer1";
import { useDispatch } from "react-redux";
import { newInvoiceTableData } from "../../InvoiceRedux/action/action";
import { Helmet } from "react-helmet";
import AboutImg from "../../assets/about.svg";

const About = () => {
  const dispatch = useDispatch();
  let newNumberList;
  const navigate = useNavigate();
  const [id, setId] = useState(increaseNumber());
  const [number, setNumber] = useState(newNumbers());

  function increaseNumber() {
    return JSON.parse(localStorage.getItem("ids")) || 0;
  }

  function newNumbers() {
    return (newNumberList =
      JSON.parse(localStorage.getItem("idCollection")) || []);
  }

  const CreateInvoice = () => {
    const invoiceitems = JSON.parse(localStorage.getItem(`adminProfile${id}`));
    let id2;
    if (invoiceitems?.invoiceGenerator?.billTo?.value === "") {
      id2 = id;
      setId(id2);
      localStorage.setItem("ids", id2);
    } else {
      id2 = id + 1;
      setId(id2);
      localStorage.setItem("ids", id2);
    }

    let newInvoiceArray = [...newNumberList, id2];
    localStorage.setItem("idCollection", JSON.stringify(newInvoiceArray));
    setNumber(newInvoiceArray);
    dispatch(newInvoiceTableData());
    navigate(`/invoice-Downloader/${id2}`);
  };

  const signUp = () => {
    localStorage.removeItem("auth");
    localStorage.removeItem("token");
    navigate("/Signup");
  };

  const [seo, setSeo] = useState([]);
  useEffect(() => {
    const seoApi = async () => {
      await fetch(`${process.env.REACT_APP_BASE_URL}api/util/get-meta`, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: JSON.parse(localStorage.getItem("token")),
        },
      })
        .then((res) => res.json())
        .then((res) => {
          setSeo(res?.data);
        });
    };
    seoApi();
  }, []);
  // console.log(seo, "seoo");

  return (
    <>
      <div className="flex-wrapper-dashboard">
        {seo?.map((item) => {
          if (item?.pagename === "About") {
            return (
              <Helmet>
                <title>About | Easy Invoice</title>
                <meta name="description" content={item?.content} />
                <link rel="canonical" href="https://ezinvoicegenerator.com/about-us/" />
              </Helmet>
            );
          }
        })}
        {/* <Helmet>
          <title>Dashboard | Easy Invoice</title>
          <meta name="description" content={seo[6]?.content} />
        </Helmet> */}
        <Header></Header>
        <div className="container">
          <div className="row col-sm-12" style={{ marginTop: "0rem" }}>
            <section className="invoice-guide" style={{ marginTop: "0rem" }}>
              <div className="container lmt-5">
                <div className="row gx-0 pt-5 mt-5">
                  <div className="col-sm-12 mt-xxl-5 pt-xxl-5">
                    <div className="heading text-center">
                      <div className="d-flex justify-content-center align-items-center">
                        <h1>
                          <span className="heading-text">
                            About <span style={{ color: "black" }}>Us</span>{" "}
                          </span>
                          <h3 className="extra-font mt-4">
                            Welcome To Easy Invoice Generator
                          </h3>
                        </h1>
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-lg-7 col-xl-6 mt-5 px-0 px-md-2">
                        <p className="text-left about-para ">
                          Easy Invoice Generator is a Professional E- Invoicing
                          Platform. Here we will provide you only interesting
                          content, which you will like very much. We're
                          dedicated to providing you the best of E- Invoicing,
                          with a focus on dependability and E- Invoice In
                          seconds. We're working to turn our passion for E-
                          Invoicing into a booming online website. We hope you
                          enjoy our E- Invoicing as much as we enjoy offering
                          them to you.
                        </p>
                        <p className="text-left about-para ">
                          I will keep posting more important posts on my Website
                          for all of you. Please give your support and love.
                        </p>
                      </div>
                      <div className="col-lg-5 col-xl-6 d-flex justify-content-center px-0 px-md-2 mt-lg-2 mt-xl-1">
                        <img
                          className="about-img"
                          src={AboutImg}
                          alt="..."
                        ></img>
                      </div>
                    </div>
                    <div className="heading text-center mt-5">
                      <div className="d-flex justify-content-center align-items-center">
                        <h1>
                          <h4 className="extra-font">
                            Thanks For Visiting Our Site
                          </h4>
                          <h4 className="heading-text">Have a nice day !</h4>
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        <Footer2></Footer2>
      </div>
    </>
  );
};
export default About;
