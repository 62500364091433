import React, { useState,useEffect } from "react";
import Header from "../Header/Header";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
// import Box from "@mui/material/Box";
import "./Seo.css";
import {TextareaAutosize} from "@mui/base";
import Notfound from "../Notfound/Notfound";
// import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
// import { borderRadius, fontSize, padding, textAlign } from "@mui/system";
import Footer2 from "../Footer/Footer1";
// import { Button } from "@mui/material";

const data = [
  { id: 1, title: "SEO" },
  { id: 2, title: "Script" },
];

function Seo() {
  const [innerTab, setInnerTab] = useState(data);
  const [activeTab, setActiveTab] = useState(1);
  const [tab, setTab] = useState("Home");
  const Admin = JSON.parse(localStorage.getItem("auth"));
  const [seo, setSeo] = useState();
  const [seoData, setSeoData] = useState(false);
  const [updateReponse, setUpdateResponse] = useState(false);
  const [seoInputField, setSeoInputField] = useState({
    title: "",
    description: "",
    image: "",
    headerScript: "",
    footerScript: "",
  });

  const tabHandler = (activePageName) => {
    setTab(activePageName);
    let seoTabData = seo?.filter(
      (item, index) => item?.pagename === activePageName
    );
    // setSelectedTabData(seoTabData);
    setSeoInputField(seoTabData[0]);
  };

  useEffect(() => {
    // setSeoInputField({...seo})
  }, [tab]);

  const tabActiveHanlder = (id) => {
    setActiveTab(id);
  };
  useEffect(() => {
    const seoApi = async () => {
      await fetch(`${process.env.REACT_APP_BASE_URL}api/util/get-meta`, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: JSON.parse(localStorage.getItem("token")),
        },
      })
        .then((res) => res.json())
        .then((res) => {
          
          setSeoData(true);
          if (res?.success === true) {
            setSeo(res?.data);
            let xyz = res?.data?.filter((item) =>
              item?.pagename === tab ? setSeoInputField(item) : ""
            );
          }
        });
    };

    seoApi();
  }, [updateReponse]);

 

  const onChangeHandler = (e, id) => {
    const { name, value } = e.target;
    setSeoInputField({ ...seoInputField, [name]: value });
  };


  // const deleteMetaTag = async (id) => {
  //   const deleteId = seo.map((item) => {
  //     if (item?._id === id) {
  //       return { item, delete: false };
  //     } else {
  //       return item;
  //     }
  //   });

  //   setSeo(deleteId);

  //   await fetch(`${process.env.REACT_APP_BASE_URL}api/util/meta/${id}`, {
  //     method: "DELETE",
  //     headers: {
  //       "content-type": "application/json",
  //       Authorization: JSON.parse(localStorage.getItem("token")),
  //     },
  //   })
  //     .then((res) => res.json())
  //     .then((res) => {
  //       if (res?.success) {
  //         setGetApi(getApi + 1);
  //       }
  //     })
  //     .catch((err) => {
  //       // setError(err.message);
  //     });
  // };
  const submitHandler = (e, selectedpage) => {
    e.preventDefault();

    let seoApiUpdateData = async () => {
      await fetch(`${process.env.REACT_APP_BASE_URL}api/util/meta`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: JSON.parse(localStorage.getItem("token")),
        },
        body: JSON.stringify({
          pagename: selectedpage,
          title: seoInputField?.title,
          description: seoInputField?.description,
          image: seoInputField?.image,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res?.success) {
            setUpdateResponse(!updateReponse);
          }
        });
    };
    seoApiUpdateData();
  };

  const onScriptHandler = (e, selectedPageName) => {
    e.preventDefault();
    let seoApiUpdateData = async () => {
      await fetch(`${process.env.REACT_APP_BASE_URL}api/util/meta`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: JSON.parse(localStorage.getItem("token")),
        },
        body: JSON.stringify({
          pagename: selectedPageName,
          headerScript: seoInputField?.headerScript,
          footerScript: seoInputField?.footerScript,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            setUpdateResponse(!updateReponse);
          }
        });
    };
    seoApiUpdateData();
  };

  const imageUploadHandler = (e) => {
    e.preventDefault();
    let logo = e.target.files[0];
    const data = new FormData();
    data.append("file", logo);

    fetch(`${process.env.REACT_APP_BASE_URL}api/util/upload`, {
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        setSeoInputField({ ...seoInputField, image: res?.path });
      });
  };


  return (
    <>
      <div className="flex-wrapper-dashboard">
        {Admin?.Admin === 1 ? (
          <>
            {seoData === false && (
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            )}

            <Header />
            <div
              className="container seomaindiv"
              style={{ height: "fit-content", marginTop: "2rem" }}
            >
              <div
                // style={{border: "2px solid black"}}
                className="seo_container"
              >
                <div
                  // style={{width: "100%", height:"100%",border: "2px solid yellow "}}
                  className="seo_container_info"
                >
                  <div
                    style={{
                      position: "relative",
                      paddingTop: "32px",
                      marginTop: "26px",
                      width:"20%"
                    }}
                  >
                    {seo?.map((item) => {
                      return (
                        <>
                          <div
                            key={item.id}
                            style={{
                              borderRadius: "6px",
                              cursor: "pointer",
                              fontSize: "16px",
                              fontWeight: "600",
                              padding: "6px 12px",
                              margin: "8px 0px",
                              textAlign: "center",
                              // margin:"8px, 0px"
                              // marginTop:"24px"
                            }}
                            onClick={() => tabHandler(item?.pagename)}
                            className={
                              tab === item?.pagename
                                ? "activetab"
                                : "unActiveTab"
                            }
                          >
                            {item?.pagename}
                          </div>
                          {/* <div><TextareaAutosize value={item?.content} /></div> */}
                        </>
                      );
                    })}
                  </div>
                  <div
                    style={{
                      paddingTop: "12px",
                      marginLeft: "16px",
                      width: "80%",
                      paddingLeft: "8px",
                    }}
                  >
                    {innerTab?.map((active) => {
                      return (
                        <>
                          <span
                            key={active.id}
                            style={{
                              padding: "4px",
                              paddingRight: "15px",
                              paddingLeft: "15px",
                              borderRadius: "6px",
                              textAlign: "center",
                              fontWeight: "500",
                              fontSize: "16px",
                              cursor: "pointer",
                              marginRight: "20px",
                            }}
                            className={
                              active?.id === activeTab
                                ? "activetab"
                                : "unActiveTab"
                            }
                            onClick={() => tabActiveHanlder(active.id)}
                          >
                            {active?.title}
                          </span>
                        </>
                      );
                    })}
                    {/* <div> */}
                    {activeTab === 1 &&
                      seo?.map(
                        (item) =>
                          // return (

                          item?.pagename === tab ? (
                            <form
                              onSubmit={(e) => submitHandler(e, item?.pagename)}
                              style={{ marginTop: "20px" }}
                            >
                              <div style={{}}>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <label style={{ padding: "6px 0px" }}>
                                    Title
                                  </label>
                                  <input
                                    value={seoInputField?.title}
                                    name="title"
                                    onChange={onChangeHandler}
                                    type="text"
                                    placeholder="title"
                                    style={{
                                      height: "44px",
                                      border: "1px solid #CED4DA",
                                      borderRadius: "6px",
                                      paddingLeft: "8px",
                                      fontSize: "16px",
                                      backgroundColor: "#EFEFEF",
                                      // color: "#CED4DA",
                                    }}
                                  />
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    marginTop: "8px",
                                  }}
                                >
                                  <label style={{ padding: "6px 0px" }}>
                                    Description
                                  </label>

                                  <TextareaAutosize
                                    value={seoInputField?.description}
                                    name="description"
                                    onChange={onChangeHandler}
                                    aria-label="empty textarea"
                                    placeholder="Description"
                                    style={{
                                      resize: "none",
                                      backgroundColor: "#EFEFEF",
                                      border: "1px solid #CED4DA",
                                      borderRadius: "6px",
                                      paddingLeft: "8px",
                                      fontSize: "16px",
                                      outline: "none",
                                      minHeight: "100px",
                                    }}
                                  />
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    marginTop: "8px",
                                  }}
                                >
                                  <label style={{ padding: "6px 0px" }}>
                                    Image
                                  </label>
                                  <div className="seo_image_upload">
                                    <label>
                                      <input
                                        type="file"
                                        accept="image/*"
                                        className="form-control py-2"
                                        id="inputNumber"
                                        onChange={imageUploadHandler}
                                      />
                                      {seoInputField?.image ? (
                                        <div
                                          style={{
                                            // border: "2px solid red",
                                            display: "flex",
                                            alignItems: "center",
                                            paddingLeft: "8px",
                                            flexWrap: "wrap",
                                            fontSize: "14px",
                                            height: "100%",
                                          }}
                                        >
                                          <span>
                                            {seoInputField?.image} hello
                                          </span>
                                        </div>
                                      ) : (
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            paddingLeft: "8px",
                                            flexWrap: "wrap",
                                            fontSize: "14px",
                                            height: "100%",
                                            color: "#888888",
                                          }}
                                        >
                                          <span>Choose File</span>
                                        </div>
                                      )}
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <button
                                style={{
                                  marginTop: "16px",
                                  color: "white",
                                  backgroundColor: "#2155cd",
                                  border: "none",
                                  padding: "6px 16px",
                                  borderRadius: "6px",
                                  width: "195px",
                                }}
                                type="submit"
                              >
                                Save
                              </button>
                            </form>
                          ) : (
                            " "
                          )
                        // )
                      )}

                    {activeTab === 2 &&
                      seo?.map(
                        (item) =>
                          // {
                          // return (
                          item?.pagename === tab ? (
                            <form
                              onSubmit={(e) =>
                                onScriptHandler(e, item.pagename)
                              }
                              style={{ marginTop: "20px" }}
                            >
                              <div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <label style={{ padding: "6px 0px" }}>
                                    Header Script
                                  </label>

                                  <TextareaAutosize
                                    value={seoInputField?.headerScript}
                                    onChange={onChangeHandler}
                                    name="headerScript"
                                    aria-label="empty textarea"
                                    placeholder="Header Script"
                                    style={{
                                      resize: "none",
                                      backgroundColor: "#EFEFEF",
                                      border: "1px solid #CED4DA",
                                      borderRadius: "6px",
                                      paddingLeft: "8px",
                                      fontSize: "16px",
                                      outline: "none",
                                      minHeight: "100px",
                                    }}
                                  />
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    marginTop: "8px",
                                  }}
                                >
                                  <label style={{ padding: "6px 0px" }}>
                                    Footer Script
                                  </label>
                                  <TextareaAutosize
                                    name="footerScript"
                                    value={seoInputField?.footerScript}
                                    onChange={onChangeHandler}
                                    aria-label="empty textarea"
                                    placeholder="Footer Script"
                                    style={{
                                      resize: "none",
                                      backgroundColor: "#EFEFEF",
                                      border: "1px solid #CED4DA",
                                      borderRadius: "6px",
                                      paddingLeft: "8px",
                                      fontSize: "16px",
                                      outline: "none",
                                      minHeight: "100px",
                                    }}
                                  />
                                </div>
                              </div>

                              <button
                                style={{
                                  marginTop: "16px",
                                  color: "white",
                                  backgroundColor: "#2155cd",
                                  border: "none",
                                  padding: "6px 16px",
                                  borderRadius: "6px",
                                  widht: "195px",
                                }}
                                type="submit"
                              >
                                save
                              </button>
                            </form>
                          ) : (
                            ""
                          )
                        // );
                        // }
                      )}
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
            {/* <div>
              <input type="text" placeholder="" />
            </div> */}
            {/* <div className="container seomaindiv">
            <div className="table-responsive">
              <table className="table seotable">
                <thead
                  style={{
                    borderBottom: "solid 2px #dee2e6",
                    borderTop: "solid 2px #dee2e6",
                  }}
                >
                  <tr>
                    <th>Page&nbsp;Name</th>
                    <th className="metacontent"> Meta&nbsp;Content</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {seo.map((data, index) => {
                    return (
                      <>
                        <tr className="seotr" key={index}>
                          <td>
                            <textarea
                              className="pagenametext"
                              value={data?.pagename}
                              onChange={(e) =>
                                onPageNameChange(
                                  data?.pagename,
                                  e.target.value,
                                  index
                                )
                              }
                              rows="1"
                              cols="15"
                            ></textarea>
                          </td>
                          <td>
                            <textarea
                              className="w-100 metcontent"
                              value={data?.content}
                              onChange={(e) =>
                                onChange(data?.pagename, e.target.value)
                              }
                              rows="10"
                            ></textarea>
                          </td>
                          <td>
                            <Box sx={{ m: 1, position: "relative" }}>
                              <button
                                disabled={data?.status == false}
                                style={{
                                  position: "relative",
                                  width: "100px",
                                }}
                                type="button"
                                className="btn btn-primary"
                                onClick={() =>
                                  seoUpdateHandler(data?.pagename, index)
                                }
                              >
                                Update
                              </button>
                              {data?.status == false && (
                                <CircularProgress
                                  size={24}
                                  sx={{
                                    color: "white",
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    marginTop: "-12px",
                                    marginLeft: "-16px",
                                  }}
                                />
                              )}
                            </Box>
                            <Box sx={{ m: 1, position: "relative" }}>
                              <button
                                style={{
                                  position: "relative",
                                  width: "100px",
                                }}
                                disabled={data?.delete == false}
                                onClick={() => deleteMetaTag(data?._id)}
                                type="button"
                                className="btn btn-primary"
                              >
                                Delete
                              </button>
                              {data?.delete == false && (
                                <CircularProgress
                                  size={24}
                                  sx={{
                                    color: "white",
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    marginTop: "-12px",
                                    marginLeft: "-16px",
                                  }}
                                />
                              )}
                            </Box>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div> */}
          </>
        ) : (
          <Notfound />
        )}
        <Footer2 />
      </div>
    </>
  );
}
export default Seo;
