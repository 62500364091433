import React, { useState, useEffect } from "react";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
// import { Link } from "react-router-dom";
import "./Myaccount.css";
import { FaSignOutAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { logout } from "../../InvoiceRedux/action/AuthActions";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Header from "../Header/Header";
import Footer2 from "../Footer/Footer1";
import { Helmet } from "react-helmet";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
// import { emailChangeAction } from "../../InvoiceRedux/action/MyAccountUpdate";

// check

function Myaccount() {
  // const [apiresponse, setApiResponse] = useState();

  const [btn, setBtn] = useState(false);
  const [updatingbtn, setUpdatingBtn] = useState(false);

  const [name, setName] = useState("");
  const [getemail, getTheemail] = useState();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");

  const [password, setPassword] = useState("");

  const [newPassword, setnewPassword] = useState("");
  const [cPassword, setcPassword] = useState("");
  const [show, setShow] = useState(true);
  const [namePlaceHolder, setNamePlaceHolder] = useState("");
  const [newPasswordShow, setnewPasswordShow] = useState(true);
  const [cPasswordShow, setCPasswordShow] = useState(true);

  const updateName = async () => {
    setUpdatingBtn(true);

    await fetch(
      `${process.env.REACT_APP_BASE_URL}api/auth/setting-update-profile`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: localStorage.getItem(`token`).replaceAll('"', ""),
        },

        body: JSON.stringify({ name }),
      }
    )
      .then((response) => response.json())
      .then((response) => {
        //setRe(window.location.reload(re));
        setName("");
        setNamePlaceHolder(name);
        setName(name)

        getTheemail(response);
        toast.success(response.message);
        setUpdatingBtn(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const datasave = async () => {
      await fetch(`${process.env.REACT_APP_BASE_URL}api/auth/user-profile`, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: localStorage.getItem(`token`).replaceAll('"', ""),
        },
      })
        .then((response) => response.json())
        .then((response) => {
          getTheemail(response);
        // console.log(response, "chalak");
          setNamePlaceHolder(response?.data?.name);
          setName(response?.data?.name)
          setEmail(response?.data?.email);
        })
        .catch((error) => {
          // console.log(error, "joih");
        });
    };
    datasave();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setBtn(true);
    await fetch(`${process.env.REACT_APP_BASE_URL}api/auth/reset-pass`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: localStorage.getItem(`token`).replaceAll('"', ""),
      },

      body: JSON.stringify({ email, newPassword, password, cPassword }),
    })
      .then((response) => response.json())
      .then((response) => {
        setBtn(false);
        if (response?.success === true) {
          toast.success(response?.message);
        } else {
          throw Error(response?.message);
        }
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  function onLogout() {
    dispatch(logout(navigate));
  }

  const change = () => {
    if (show === true) {
      setShow(false);
    } else {
      setShow(true);
    }
  };

  return (
    <>
      {!getemail && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <div className="flex-wrapper-myaccount">
        <Helmet>
          <title>My Account | Easy Invoice</title>
          <meta name="description" content="" />
        </Helmet>
        <Header></Header>
        <div className="container mt-4">
          <div className="row" style={{ marginTop: "6rem" }}>
            <div className="col-md-8 col-sm-12 offset-md-2 mb-3">
              <h1 className="text-center mb-2 header"> My Account</h1>
              <p className="text-center mb-4 headertext">
                Manage your user account, including your contact and sign in
                information.
              </p>
              <form className="form" onSubmit={handleSubmit}>
                <div className="row mb-3">
                  <label
                    htmlFor="inputEmail3"
                    className="col-sm-5 col-form-label py-3"
                  >
                    Email address
                  </label>
                  <div className="col-sm-7">
                    <input
                      type="email"
                      className="form-control py-3"
                      id="inputEmail3"
                      // placeholder="xyz123@gmail.com"
                      name="emailaddress"
                      value={email}
                      onChange={email}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <label
                    htmlFor="inputText"
                    className="col-sm-5 col-form-label py-3"
                  >
                    Full Name
                  </label>
                  <div className="col-sm-7" style={{ position: "relative" }}>
                    <input
                      type="text"
                      className="form-control py-3"
                      id="inputName"
                      placeholder={namePlaceHolder}
                      name="fullname"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                    <button className="updateBtn border-0" disabled={name===namePlaceHolder} onClick={updateName}>
                      {updatingbtn ? "UPDATING..." : "UPDATE"}
                    </button>
                  </div>
                </div>
                <div className="row  mb-3">
                  <label
                    htmlFor="inputText"
                    className="col-sm-5 col-form-label py-3"
                  >
                    Current Password
                  </label>
                  <div className="col-sm-7" style={{ position: "relative" }}>
                    {password?.length === 0 ? null : show ? (
                      <i
                        onClick={change}
                        className="my_account_eye_icon"
                        // style={{ position: "absolute" }}
                      >
                        {" "}
                        <VisibilityOffIcon />{" "}
                      </i>
                    ) : (
                      <i
                        onClick={change}
                        className="my_account_eye_icon"
                        // style={{ position: "absolute" }}
                      >
                        {" "}
                        <VisibilityIcon />{" "}
                      </i>
                    )}
                    <input
                      type={show ? "password" : "text"}
                      className="form-control py-3"
                      id="form1Example2"
                      // placeholder="Current Password"
                      name="currentPassword"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                      // minLength={6}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <label
                    htmlFor="inputText"
                    className="col-sm-5 col-form-label py-3"
                  >
                    New Password{" "}
                  </label>
                  <div className="col-sm-7" style={{ position: "relative" }}>
                    {newPassword?.length === 0 ? null : newPasswordShow ? (
                      <i
                        onClick={() => setnewPasswordShow(!newPasswordShow)}
                        className="newpassword_eye_icon"
                      >
                        {" "}
                        <VisibilityOffIcon />{" "}
                      </i>
                    ) : (
                      <i
                        onClick={() => setnewPasswordShow(!newPasswordShow)}
                        className="newpassword_eye_icon"
                      >
                        {" "}
                        <VisibilityIcon />{" "}
                      </i>
                    )}
                    <input
                      type={newPasswordShow ? "password" : "text"}
                      className="form-control py-3"
                      id="form1Example2"
                      name="newPassword"
                      // placeholder="New Password"
                      value={newPassword}
                      onChange={(e) => setnewPassword(e.target.value)}
                      required
                      minLength={6}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <label
                    htmlFor="inputText"
                    className="col-sm-5 col-form-label py-3"
                  >
                    Confirm Password
                  </label>
                  <div className="col-sm-7" style={{ position: "relative" }}>
                    {cPassword?.length === 0 ? null : cPasswordShow ? (
                      <i
                        onClick={() => setCPasswordShow(!cPasswordShow)}
                        className="newpassword_eye_icon"
                      >
                        {" "}
                        <VisibilityOffIcon />{" "}
                      </i>
                    ) : (
                      <i
                        onClick={() => setCPasswordShow(!cPasswordShow)}
                        className="newpassword_eye_icon"
                      >
                        {" "}
                        <VisibilityIcon />{" "}
                      </i>
                    )}
                    <input
                      type={cPasswordShow ? "password" : "text"}
                      className="form-control py-3 px-2 "
                      id="form1Example2"
                      // placeholder="Confirm Password"
                      name="repeatPassword"
                      value={cPassword}
                      onChange={(e) => setcPassword(e.target.value)}
                      required
                      minLength={6}
                    />
                  </div>
                </div>

                <div className="d-flex justify-content-between align-items-center mb-4">
                  <div className="forgotpassword sign-out-btn">
                    <span
                      // to="/"
                      className="text-decoration-none rounded"
                      style={{
                        color: "#BE0202",
                        padding: ".6rem",
                        border: "1px solid #8E4141",
                      }}
                      onClick={onLogout}
                    >
                      <FaSignOutAlt className="me-2" />
                      <small>Sign out</small>
                    </span>
                  </div>
                  <div className="submitbtn">
                    <a to="/">
                      <button
                        type="submit"
                        className="btn btn-primary primarybutton px-5 py-2 btn-sm"
                      >
                        {btn ? "Wait..." : "Save"}
                      </button>
                    </a>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* <div>
          <Setting />
        </div> */}
        <Footer2></Footer2>
      </div>
    </>
  );
}

export default Myaccount;