import axios from "axios";

// import { getToken } from '../helpers/authToken'
// import { logoutUser } from '../helpers/user'
let apiClient = null;
class ApiClient {
  constructor() {
    this.get = this.get.bind(this);
    this.post = this.post.bind(this);
    this.patch = this.patch.bind(this);
    this.put = this.put.bind(this);
    this.delete = this.delete.bind(this);
  }

  _getClient() {
    apiClient = axios.create({
      baseURL: `${process.env.REACT_APP_BASE_URL}`,
    });
    apiClient.interceptors.request.use(
      async (config) => {
        const token = JSON.parse(localStorage.getItem("token")) || null;
        const document_type =
          JSON.parse(localStorage.getItem("document_type")) || null;
        if (token) {
          // config.headers.document_type = "pdfContent";
          config.headers.Authorization = `${token}`;
        } else if (!token) {
          if (document_type === "pdfContent") {
            config.headers["document-type"] = "pdfContent";
          } else {
            config.headers["document-type"] = "xmlContent";
          }
        }
        if (document_type) {
          if (document_type === "pdfContent") {
            config.headers["document-type"] = "pdfContent";
            config.responseType = "blob";
          } else {
            config.headers["document-type"] = "xmlContent";
          }
        }
        return config;
      },
      (err) => {
        return Promise.reject(err);
      }
    );
    apiClient.interceptors.response.use(
      async (response) => {
        // const blobFile = new Blob([response?.data], {
        //   type: "application/pdf",
        // });
        // const url = window.URL.createObjectURL(blobFile);
        // window.open(url);
        //Create a Blob from the PDF Stream
        // if (JSON.parse(localStorage.getItem("document_type")).length > 0) {
        // const file = new Blob([response?.data], { type: "application/pdf" });

        //Build a URL from the file
        // const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        // return window.open(fileURL);
        // }

        // const data = response.data;

        if (response.headers["content-type"] === "application/pdf") {
          const invoiceId = JSON.parse(localStorage.getItem("ids"))
          const blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });

        const pdfNumber= JSON.parse(localStorage.getItem("pdfNum"))

        console.log(pdfNumber, "pdfNum");
          let a = document.createElement("a");
          a.href = URL.createObjectURL(blob);
          a.download = `invoice-#${pdfNumber}.pdf`;
          a.click();
          //Build a URL from the file
          // const fileURL = URL.createObjectURL(blob);
          // //Open the URL on new Window
          // window.open(fileURL);
        } else if (
          response.headers["content-type"] === "text/xml; charset=utf-8"
        ) {
          const blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          let a = document.createElement("a");
          a.href = URL.createObjectURL(blob);
          a.download = `invoice.xml`;
          a.click();
        }
        // else {
        //   const blob = new Blob([response.data], {
        //     type: "application/pdf",
        //   });
        //   let a = document.createElement("a");
        //   a.href = URL.createObjectURL(blob);
        //   a.download = `report_${new Date().getTime()}.pdf`;
        //   a.click();
        // }

        return [response.data, undefined];
      },
      (err) => {
        if (err.response) {
          if (err?.response?.data?.token_expired) {
            // logoutUser()
          }
          return [err.response];
        }
        if (err.request) {
          return [undefined, "Request failed"];
        }
        return [undefined, "Request config error"];
      }
    );
    return apiClient;
  }
  _config() {
    return {};
  }
  get(url) {
    const _get = this._getClient().get(url, this._config());

    return _get;
  }
  post(url, data) {
    const _post = this._getClient().post(url, data, this._config());

    return _post;
  }
  patch(url, data) {
    const _patch = this._getClient().patch(url, data, this._config());
    return _patch;
  }
  put(url, data) {
    const _put = this._getClient().put(url, data, this._config());
    return _put;
  }
  delete(url, data) {
    const _delete = this._getClient().delete(url, data, this._config());
    return _delete;
  }
}
const FcClient = new ApiClient();
const get = FcClient.get;
const post = FcClient.post;
const patch = FcClient.patch;
const put = FcClient.put;
const del = FcClient.delete;

export { get, post, patch, put, del };
