import React, { useEffect, useState } from "react";
// import "antd/dist/antd.css";
// import { DatePicker, Space } from "antd";
import {TextareaAutosize} from "@mui/base";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.css";
import Modal from "react-bootstrap/Modal";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import ModalBootrap from "react-bootstrap/Modal";
import { Helmet } from "react-helmet";
import ColorBox from "./ColorBox";
import TextColorBox from "./TextColorBox";
// redux and action
import { useDispatch, useSelector } from "react-redux";
import {
  invoiceData,
  invoiceDataAction,
  deleteInvoiceData,
  editInvoiceData,
  handleInvoiceData,
  handleInvoiceCalculation,
  logoUpload,
  imageData,
  handleInvoice,
  newInvoiceTableData,
  popAction,
  logoUploadConfirm,
} from "../InvoiceRedux/action/action";

import downloadpPdfAction from "../InvoiceRedux/action/action";
// components
import CurrencyData from "./CurrencyData";
//css
import "./InvoiceDownloader.css";

// Icons and images
import SendIcon from "../assets/send_icon.svg";
import DownloadIcon from "../assets/download_icon.svg";
import LogoImgSelect from "../assets/logo image.svg";
import calendar from "../assets/calendar.svg";
import addNewInvoiceButton from "../assets/add icon.svg";
import delte from "../assets/delte.svg";
import refresh from "../assets/refresh.svg";
import { ImCross } from "react-icons/im";
import { BiRefresh } from "react-icons/bi";
import { BiPlusCircle } from "react-icons/bi";
import IconFeather from "../assets/Icon feather-download.svg";
import { toast } from "react-toastify";
import Header from "../component/Header/Header";
import Footer from "../component/Footer/Footer";
import Footer2 from "../component/Footer/Footer1";
import moment from "moment";
import { tableData } from "../InvoiceRedux/action/action";

const InvoiceDownloader = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selector = useSelector((state) => state.invoice.invoiceList);
  const [draftState, setDraftstate] = useState();
  const popUpSelector = useSelector((state) => state?.invoice?.popUpMessage);
  const userId = JSON.parse(localStorage.getItem(`auth`));
  const [discountState, setDiscountState] = useState(true);
  const [taxState, setTaxState] = useState(true);
  const [hideTaxBox, setHideTaxBox] = useState(false);
  const [hideDiscountBox, setHideDiscountBox] = useState(true);
  const [hideShipBox, setHideshipBox] = useState(true);
  const [currencyinput, setCurrencyInput] = useState();
  const [countrySymbol, setCountrySymbol] = useState("$");
  const isAuthenticate = localStorage.getItem(`token`);
  const [data, setData] = useState({ items: "", quantity: 1, rate: 0 });
  const [file, setFile] = useState(selector?.logoLink);
  const [isOpen, setIsOpen] = React.useState(false);
  const [updateLocalStorage, setUpdateLocalStorage] = useState(0);
  const [taxStatus, setTaxStatus] = useState(false);
  const location = useLocation();

  // console.log(location.state, 'location');
  const [notificationNumber, setNotificationNumber] = useState();
  const [invoicenumber, setInvoiceNumber] = useState();
  const [currencyCode, setCurrencyCode] = useState("USD");

  const [newInputField, setNewInputField] = useState({
    key: "new value",
    value: 0,
  });
  const [newInputList, setNewInputList] = useState([]);
  const [newTaxId, setNewTaxId] = useState();
  const { id } = useParams();
  const [discountCalculate, setDiscountCalculate] = useState(0);
  const [totalNewTax, setTotalNewTax] = useState(0);
  const [selectedColor, setSelectedColor] = useState({
    light: "black",
    dark: "#f4efef",
  });

  const [selectedTextColor, setSelectedTextColor] = useState({
    dark: "black",
    light: "black",
  });

  // const profile=	(response?.data?.addressLine1 ? response?.data?.addressLine1 + "\n" : "" ) + (response?.data?.addressLine2 ? response?.data?.addressLine2 + "\n" : "" ) + (response?.data?.city ? response?.data?.city + "\n" : ""  )+ (response?.data?.state ? response?.data?.state + "\n" : "" ) + (response?.data?.country ? response?.data?.country + "\n" :"" )+  (response?.data?.email ? response?.data?.email : "")
  // const currentId= JSON.parse(localStorage.getItem("ids"))
  // const currentAdminProfile= JSON.parse(localStorage.getItem(`adminProfile${currentId}`))
  // const updateCurrentAdminProfile = {...currentAdminProfile,  invoiceGenerator: {...currentAdminProfile.invoiceGenerator, companyName : profile}}
  // // console.log(updateCurrentAdminProfile, "olwp");

  // localStorage.setItem(`adminProfile${currentId}`,JSON.stringify(updateCurrentAdminProfile) )
  // setUpdateLocalStorage(updateLocalStorage+1)

  // ===================  Currency Api Start ====================

  useEffect(() => {
    const datasave = async () => {
      await fetch(`${process.env.REACT_APP_BASE_URL}api/auth/user-profile`, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: localStorage.getItem(`token`).replaceAll('"', ""),
        },
      })
        .then((response) => response.json())
        .then((response) => {
          if (response?.success) {
            // const profile =
            //   (response?.data?.addressLine1
            //     ? response?.data?.addressLine1 + " "
            //     : "") +
            //   (response?.data?.addressLine2
            //     ? response?.data?.addressLine2 + "\n"
            //     : "") +
            //   (response?.data?.city ? response?.data?.city + "\n" : "") +
            //   (response?.data?.state ? response?.data?.state + "\n" : "") +
            //   (response?.data?.country ? response?.data?.country + "\n" : "") +
            //   (response?.data?.email ? response?.data?.email : "");

            // setInvoiceGenerator({ ...invoiceGenerator, companyName: profile });

            setCurrencyInput(response);
            dispatch(imageData(response?.data?.logo));

            setFile(response?.data?.logo);
            setCountrySymbol(response?.data?.currency);
            CurrencyData?.currency?.map((val, ind) => {
              if (val?.symbol === response?.data?.currency)
                return setCurrencyCode(val?.code);
            });
          }
        })
        .catch((error) => {});
    };
    datasave();
  }, []);

  // =======================  Currency Api Ends ==================

  useEffect(() => {
    // debugger;

    let numberofInvoices =
      JSON.parse(localStorage.getItem("idCollection")) || 0;
    if (numberofInvoices.length === 0 || numberofInvoices === 0) {
      setNotificationNumber(0);
    } else {
      setNotificationNumber(numberofInvoices?.length - 1);
    }
  }, []);

  const showModal = () => {
    localStorage.setItem("pdfNum", invoiceGenerator?.invoiceId?.value);
    // invoiceGenerator?.map((val)=>{

    // 	return val
    // })

    setIsOpen(true);
    dispatch(
      handleInvoiceCalculation({
        ...invoiceGenerator,
        taxValue,
        discountValue,
        countrySymbol,
        user,
        discountState,
        taxState,
        taxStatus,
        file,
        hideDiscountBox,
        hideShipBox,
        selectedColor,
        selectedTextColor,
        newInputList,
      })
    );
    // }
  };

  let user;
  if (isAuthenticate) {
    user = userId;
  }

  // const showtoast = () => {
  //   toast.error("Items Required");
  // };

  const hideModal = () => {
    setIsOpen(false);
  };
  // checking

  const [invoiceGenerator, setInvoiceGenerator] = useState({
    dragLogo: "",
    title: "Invoice",
    companyName: "",
    billTo: { key: "Bill To", value: "" },
    shipTo: { key: "Ship To", value: "" },
    invoiceId: { key: "Invoice Id", value: id },
    date: { key: "Date", value: "" },
    paymentTerms: { key: "Payment Mode", value: "" },
    poNumber: { key: "Po Number", value: "" },
    notes: { key: "Notes", value: "" },
    termsAndDescription: { key: "Terms & Description", value: "" },
    dueDate: { key: "Due Date", value: "" },
    subtotal: { key: "Subtotal", value: 0 },
    amountPaid: { key: "Amount Paid", value: "" },
    total: { key: "Total", value: 0 },
    balance: { key: "Balance", value: 0 },
    discount: { key: "Discount", value: "0" },
    tax: { key: "Tax", value: "0" },
    shipping: { key: "Shipping", value: "" },
    invoiceItemHeader: {
      item: "Item",
      quantity: "Quantity",
      rate: "Rate",
      amount: "Amount",
    },
  });

  const DiscountBox = () => {
    setHideDiscountBox(!hideDiscountBox);
    setInvoiceGenerator({
      ...invoiceGenerator,
      discount: { ...invoiceGenerator.discount, value: 0 },
    });
  };

  const ShippedBox = () => {
    setHideshipBox(!hideShipBox);
    setInvoiceGenerator({
      ...invoiceGenerator,
      shipping: { ...invoiceGenerator.shipping, value: 0 },
    });
  };

  const TaxBox = () => {
    setHideTaxBox(!hideTaxBox);
    setTaxStatus(true);
    setInvoiceGenerator({
      ...invoiceGenerator,
      tax: { ...invoiceGenerator.tax, value: 0 },
    });
  };

  const eInvoice = () => {
    dispatch(
      invoiceDataAction(
        selector,
        localStorage.setItem("document_type", JSON.stringify("xmlContent"))
      )
    );
    navigate("");
  };

  const handlePrint = () => {
    setDraftstate("");
    setUpdateLocalStorage(updateLocalStorage + 1);
    setIsOpen(false);
    let totalInvoices = JSON.parse(localStorage.getItem("idCollection")) || 0;
    localStorage.setItem(
      "totalInvoices",
      JSON.stringify(totalInvoices?.length ? totalInvoices?.length : 0)
    );
    setUpdateLocalStorage(1);
    dispatch(
      invoiceDataAction(
        selector,
        localStorage.setItem("document_type", JSON.stringify("pdfContent"))
      )
    );
    // navigate("/history")
  };

  const handleSelectChange = async (e) => {
    const selectedCountry = await e.target.value;
    setCountrySymbol(selectedCountry);
  };

  // let amount = 0;
  // let balance = invoiceGenerator?.balance?.value;

  // CALCULATION PART START
  // useEffect(()=> {
  const amount = Number(data?.quantity || 0) * Number(data?.rate || 0);
  invoiceGenerator.subtotal.value = amount;

  selector?.invoiceItems?.map((val) => {
    invoiceGenerator.subtotal.value =
      invoiceGenerator?.subtotal?.value + val?.amount;
    return invoiceGenerator?.subtotal.value;
  });

  // let disco = discountState
  //   ? invoiceGenerator?.subtotal?.value -
  //     Number(
  //       (invoiceGenerator?.discount?.value / 100) *
  //         invoiceGenerator?.subtotal?.value
  //     )
  //   : invoiceGenerator?.subtotal?.value - invoiceGenerator?.discount?.value;

  // const [totalNewTax, setTotalNewTax] = useState(0);

  let tax = taxState
    ? Number(
        discountCalculate +
          totalNewTax +
          (invoiceGenerator?.tax?.value / 100) * discountCalculate
      ).toFixed(2)
    : Number(discountCalculate) +
      Number(totalNewTax) +
      Number(invoiceGenerator?.tax?.value);

  invoiceGenerator.total.value = (
    Number(tax) + Number(invoiceGenerator?.shipping?.value)
  ).toFixed(2);

  invoiceGenerator.balance.value = Number(
    invoiceGenerator?.total?.value - invoiceGenerator?.amountPaid?.value
  ).toFixed(2);
  // },[])

  let discountValue = discountState
    ? Number(
        (invoiceGenerator?.discount?.value / 100) *
          invoiceGenerator?.subtotal?.value
      ).toFixed(2)
    : Number(invoiceGenerator?.discount?.value).toFixed(2);

  let taxValue = taxState
    ? Number((invoiceGenerator?.tax?.value / 100) * discountCalculate).toFixed(
        2
      )
    : Number(invoiceGenerator?.tax?.value).toFixed(2);

  // CALCULATION PART END

  const newInputChangeHandler = (e, id) => {
    let value = e.target.value;
    let name = e.target.name;
    setNewTaxId(id);
    let editInput = newInputList.map((item) =>
      item.id === id ? { ...item, [name]: value } : item
    );
    newTaxCalculatefunction(editInput, id);
  };

  function newTaxCalculatefunction(editInput, id, allchange = false) {
    if (allchange) {
      editInput?.map((item, index) => {
        let newAmountCalculate = Number(
          (editInput[index]?.value / 100) * discountCalculate
        ).toFixed(2);
        if (NaN !== newAmountCalculate && editInput[index]?.value) {
          editInput[index].newAmount = Number(newAmountCalculate);
        }
        setNewInputList(editInput);
        console.log(editInput[index], editInput, newAmountCalculate, "56666");
      });

      finalTotalTax(editInput);
    } else {
      let findIndexValue = editInput.findIndex((item) => item?.id === id);
      let newAmountCalculate = Number(
        (editInput[findIndexValue]?.value / 100) * discountCalculate
      ).toFixed(2);
      if (NaN !== newAmountCalculate && editInput[findIndexValue]?.value) {
        editInput[findIndexValue].newAmount = Number(newAmountCalculate);
      }
      if (!editInput[findIndexValue]?.value) {
        editInput[findIndexValue].newAmount = 0;
      }
      console.log(
        editInput[findIndexValue],
        editInput,
        newAmountCalculate,
        "56666"
      );
      finalTotalTax(editInput);
      setNewInputList(editInput);
    }
  }

  useEffect(() => {
    // let totalNewTaxs;
    // let newValueAdd = newInputList?.map((item) => {
    //   totalNewTaxs = totalNewTax + item?.newAmount;
    //   return totalNewTaxs;
    // });

    // setTotalNewTax(totalNewTaxs);

    let discountCalculates = discountState
      ? invoiceGenerator?.subtotal?.value -
        Number(
          (invoiceGenerator?.discount?.value / 100) *
            invoiceGenerator?.subtotal?.value
        )
      : invoiceGenerator?.subtotal?.value - invoiceGenerator?.discount?.value;
    setDiscountCalculate(discountCalculates);
  }, [invoiceGenerator?.subtotal?.value, invoiceGenerator?.discount?.value]);

  useEffect(() => {
    let allchange = true;
    // alert('hello')
    newTaxCalculatefunction(newInputList, newTaxId, allchange);
  }, [discountCalculate]);

  useEffect(() => {
    if (invoiceGenerator?.discount?.value == 0) {
      // alert('change')
      let allchange = true;
      newTaxCalculatefunction(newInputList, newTaxId, allchange);
    }
  }, [invoiceGenerator?.discount?.value]);

  const invoiceChangeHandler = (e, k) => {
    const name = e.target.name;
    const value = e.target.value;
    let keyName = k;
    if (keyName === "invoiceId") {
      localStorage.setItem("pdfNum", JSON.stringify(value));
    }
    if (keyName === "companyName") {
      setUpdateLocalStorage(updateLocalStorage + 1);
      setInvoiceGenerator({
        ...invoiceGenerator,
        [keyName]: value,
      });
      return;
    } else if (keyName === "title") {
      setUpdateLocalStorage(updateLocalStorage + 1);
      setInvoiceGenerator({
        ...invoiceGenerator,
        [keyName]: value,
      });
      return;
    }
    let obj = { ...invoiceGenerator[keyName], [name]: value };
    setInvoiceGenerator({
      ...invoiceGenerator,
      [keyName]: obj,
      id: id,
    });
    setUpdateLocalStorage(updateLocalStorage + 1);
    // dispatch(invoiceData(name, value, keyName));
    // dispatch(handleInvoiceData(name, value));
    // setInvoiceGenerator({ ...invoiceGenerator, [keyName]: { [name]: value } });
  };

  const TaxHandler = () => {
    setHideTaxBox(!hideTaxBox);
    setTaxStatus(false);
  };

  const discountHandler = () => {
    setHideDiscountBox(!hideDiscountBox);
  };

  const shippingHandler = () => {
    setHideshipBox(!hideShipBox);
  };

  // deletebutton
  const deleteHandler = (id) => {
    dispatch(deleteInvoiceData(id));
    setUpdateLocalStorage(updateLocalStorage + 1);
  };

  // logo
  const formDataGenerator = (data) => {
    let formDataValue = new FormData();
    let keys = Object.keys(data);
    keys.map((key) => {
      formDataValue.append(key, data[key]);
    });

    return formDataValue;
  };

  const discountAmountHandler = () => {
    setDiscountState(!discountState);
    setUpdateLocalStorage(updateLocalStorage + 1);
    // setDiscountGiven(0);
  };

  const taxAmountHandler = () => {
    setTaxState(!taxState);
    setUpdateLocalStorage(updateLocalStorage + 1);
    // setPercentage(0);
  };

  const logoChangeHandler = (e) => {
    e.preventDefault();
    let selectedFile = e.target.files[0];

    setFile(URL.createObjectURL(e.target.files[0]));
    dispatch(logoUpload(formDataGenerator({ file: selectedFile })));
    // debugger;
    setUpdateLocalStorage(updateLocalStorage + 1);
  };

  const SendInvoice = () => {
    if (isAuthenticate) {
      // dispatch(invoiceDataAction(selector));
      navigate("/invoice");
    } else {
      navigate("/sign-in");
    }
  };

  // edit data
  const dataUpdate = (e, index) => {
    const { name, value } = e.target;
    // const data = list.map((item, key) => {
    //   return key === index ? { ...item, [name]: value } : item;
    // });
    //setList(data);
    // debugger
    dispatch(editInvoiceData(name, value, index));
    setUpdateLocalStorage(updateLocalStorage + 1);
  };

  const addNewInputField = () => {
    let id = newInputList?.length + 1;
    setNewInputList([
      { ...newInputField, id, newAmount: 0.0, symbol: countrySymbol },
      ...newInputList,
    ]);
    setNewInputField({ key: "new value", value: 0 });
  };

  const deleteInputField = (id) => {
    let removeInputField = newInputList.filter((item) => item?.id !== id);
    setNewInputList(removeInputField);
    finalTotalTax(removeInputField);
  };
  function finalTotalTax(removeInputField) {
    let totalNewTaxs = 0;
    let newValueAdd = removeInputField?.map((item) => {
      totalNewTaxs += item?.newAmount;
      return totalNewTaxs;
    });
    setTotalNewTax(Number(totalNewTaxs));
  }
  // add items
  const add = () => {
    // if (Object?.keys(data)?.length === 0) {
    //   return setData({ items: "", quantity: 1, rate: 0 });
    // }
    // setData({ items: "", quantity: 1, rate: 0 });
    dispatch(
      invoiceData({
        ...data,
        // ...invoiceGenerator,
        id: new Date().getTime().toString(),
        amount: amount,
      })
    );
    setUpdateLocalStorage(updateLocalStorage + 1);
  };

  // edit button
  // const datahandler = (e) => {
  // 	const name = e.target.name;
  // 	const value = e.target.value;
  // 	setData({ ...data, [name]: value });
  // 	// dispatch(tableData(name, value, amount));
  // 	setUpdateLocalStorage(updateLocalStorage + 1);
  // };

  // Data picker all states and functionality
  const [datePickerIsOpen, setDatePickerIsOpen] = useState(false);
  const [duedatePickerIsOpen, setDueDatePickerIsOpen] = useState(false);
  const [startDate, setstartDate] = useState();
  const [duestartDate, setDuestartDate] = useState();
  const [state, setState] = useState();
  const [duedateState, setDuedatestate] = useState();

  useEffect(() => {
    let invoicecount = JSON.parse(localStorage.getItem("totalInvoices")) || 0;
    setInvoiceNumber(invoicecount);
  }, [updateLocalStorage]);

  const handleChange = (date) => {
    setstartDate(date);
    setState(moment(date).format("MMM DD, YYYY"));
    setDatePickerIsOpen(!datePickerIsOpen);
  };

  const openDatePicker = () => {
    setDatePickerIsOpen(!datePickerIsOpen);
  };

  const handle = (e) => {
    setState(e.target.value);
  };

  const duehandlechange = (date) => {
    setDuestartDate(date);
    setDuedatestate(moment(date).format("MMM DD, YYYY"));
    setDueDatePickerIsOpen(!duedatePickerIsOpen);
  };

  const dueopenDatePicker = () => {
    setDueDatePickerIsOpen(!duedatePickerIsOpen);
  };

  const duehandle = (e) => {
    setDuedatestate(e.target.value);
  };

  // useEffect(()=> {
  //   const logoImage = JSON.parse(localStorage.getItem("logoimage"))

  //   dispatch(logoUpload(formDataGenerator({ logo: logoImage })));

  // },[])

  // useEffect(() => {
  //   const data = JSON.parse(localStorage.getItem("InvoiceItems"));
  //   // debugger
  //   if (data) {
  //     dispatch(handleInvoiceData(data));
  //   }
  // }, []);

  /* LOCAL STORAGE FUNCTIONALITY */

  useEffect(() => {
    setInvoiceGenerator({
      ...invoiceGenerator,
      dueDate: { ...invoiceGenerator.dueDate, value: duedateState },
      // date: { ...invoiceGenerator.date, value: state }
    });

    setUpdateLocalStorage(updateLocalStorage + 1);
  }, [duedateState]);

  useEffect(() => {
    setInvoiceGenerator({
      ...invoiceGenerator,
      date: { ...invoiceGenerator.date, value: state },
    });
    setUpdateLocalStorage(updateLocalStorage + 1);
  }, [state]);

  // useEffect(() => {
  //   // alert('helllo')
  //   let previousInvoice = JSON.parse(localStorage.getItem("idCollection"))
  //   let previousInvoiceNum = previousInvoice[previousInvoice.length <= 1 ? 0 : previousInvoice.length - 2]
  //   console.log(previousInvoiceNum, '656565');
  //   let lastProfile = JSON.parse(localStorage.getItem(`adminProfile${previousInvoiceNum}`)) || {
  //     ...invoiceGenerator,
  //   };
  //   const billToValue = lastProfile?.invoiceGenerator?.billTo?.value
  //   const billToKey = lastProfile?.invoiceGenerator?.billTo?.key
  //   const companyName = lastProfile?.invoiceGenerator?.companyName
  //   const notesKey = lastProfile?.invoiceGenerator?.notes?.key
  //   const notesValue = lastProfile?.invoiceGenerator?.notes?.value
  //   const invoiceIdKey = lastProfile?.invoiceGenerator?.invoiceId?.key
  //   const invoiceIdValue = lastProfile?.invoiceGenerator?.invoiceId?.value ? Number(lastProfile?.invoiceGenerator?.invoiceId?.value) + 1 : 1
  //   const termsAndDescriptionKey = lastProfile?.invoiceGenerator?.termsAndDescription?.key
  //   const termsAndDescriptionValue = lastProfile?.invoiceGenerator?.termsAndDescription?.value
  //   setInvoiceGenerator({
  //     ...invoiceGenerator, billTo: {
  //       ...invoiceGenerator.billTo ,
  //       key: billToKey,
  //       value: billToValue
  //     },
  //     companyName: companyName,
  //     notes: {
  //       key: notesKey,
  //       value: notesValue
  //     }, invoiceId: {
  //       key: invoiceIdKey,
  //       value: invoiceIdValue
  //     },
  //     termsAndDescription: {
  //       key: termsAndDescriptionKey,
  //       value: termsAndDescriptionValue
  //     }
  //   })

  //   // }
  // }, [])

  useEffect(() => {
    if (updateLocalStorage === 0) {
      const datas = JSON.parse(localStorage.getItem(`adminProfile${id}`)) || {
        ...invoiceGenerator,
      };
      // if(datas?.selector?.invoiceItems?.[0]?.item !== ""){

      if (datas?.selector) {
        // alert('hello')
        dispatch(handleInvoiceData(datas?.selector));
      }
      // }else if(datas?.selector?.invoiceItems?.[0]?.item !== ""){
      //   alert('bye')
      //   dispatch(editInvoiceData(data));
      // }

      // const image = JSON.parse(localStorage.getItem("logoimage"));

      if (datas?.selector?.logoLink) {
        dispatch(imageData(datas?.selector?.logoLink));
        setFile(datas?.selector?.logoLink);
      }

      if (datas?.draftState === "") {
        setDraftstate(datas?.draftState);
      } else {
        setDraftstate("DRAFT");
      }

      // setInvoiceGenerator(selectedItem);
      setInvoiceGenerator(
        datas?.invoiceGenerator ? datas?.invoiceGenerator : datas
      );
      setState(datas?.invoiceGenerator?.date?.value);
      setDuedatestate(datas?.invoiceGenerator?.dueDate?.value);

      if (datas?.discountState === false) {
        setDiscountState(false);
      } else {
        setDiscountState(true);
      }

      if (datas?.taxState === false) {
        setTaxState(false);
      } else {
        setTaxState(true);
      }

      // let existingArray = JSON.parse(localStorage.getItem("idCollection"))|| []
      // let lastId = existingArray[existingArray.length - 1]
      // console.log(lastId, "leea");
      if (location?.state !== 1) {
        // alert('chandra')
        let previousInvoice =
          JSON.parse(localStorage.getItem("idCollection")) || [];
        let previousInvoiceNum =
          previousInvoice[
            previousInvoice.length <= 1 ? 0 : previousInvoice.length - 2
          ];
        // console.log(previousInvoiceNum, '656565');
        let lastProfile = JSON.parse(
          localStorage.getItem(`adminProfile${previousInvoiceNum}`)
        ) || {
          ...invoiceGenerator,
        };
        const billToValue = lastProfile?.invoiceGenerator?.billTo?.value
          ? lastProfile?.invoiceGenerator?.billTo?.value
          : "";
        const billToKey = lastProfile?.invoiceGenerator?.billTo?.key
          ? lastProfile?.invoiceGenerator?.billTo?.key
          : "Bill To";
        const companyName = lastProfile?.invoiceGenerator?.companyName
          ? lastProfile?.invoiceGenerator?.companyName
          : "";
        const notesKey = lastProfile?.invoiceGenerator?.notes?.key
          ? lastProfile?.invoiceGenerator?.notes?.key
          : "Notes";
        const notesValue = lastProfile?.invoiceGenerator?.notes?.value
          ? lastProfile?.invoiceGenerator?.notes?.value
          : "";
        const invoiceIdKey = lastProfile?.invoiceGenerator?.invoiceId?.key
          ? lastProfile?.invoiceGenerator?.invoiceId?.key
          : "Invoice Id";
        const invoiceIdValue = JSON.parse(localStorage.getItem("ids"))
          ? JSON.parse(localStorage.getItem("ids"))
          : 1;
        const termsAndDescriptionKey = lastProfile?.invoiceGenerator
          ?.termsAndDescription?.key
          ? lastProfile?.invoiceGenerator?.termsAndDescription?.key
          : "Terms & Description";
        const termsAndDescriptionValue = lastProfile?.invoiceGenerator
          ?.termsAndDescription?.value
          ? lastProfile?.invoiceGenerator?.termsAndDescription?.value
          : "";
        setInvoiceGenerator({
          ...invoiceGenerator,
          billTo: {
            key: billToKey,
            value: billToValue,
          },
          companyName: companyName,
          notes: {
            key: notesKey,
            value: notesValue,
          },
          invoiceId: {
            key: invoiceIdKey,
            value: invoiceIdValue,
          },
          termsAndDescription: {
            key: termsAndDescriptionKey,
            value: termsAndDescriptionValue,
          },
          id: id,
        });
      }
    } else {
      // arry[arry.length - 1];

      // let lastIndex = previousInvoiceIds[previousInvoiceIds.length - 1]
      // console.log(lastIndex);
      // console.log(lastIndex, '454==');
      // let last = previousInvoice?.pop()
      // console.log(previousInvoice, '123465');
      //

      // let invoiceGenerator = {...invoiceGenerator, billTo: {
      //   key: billToKey,
      //   value: billToValue,
      // }}

      localStorage.setItem(
        `adminProfile${id}`,
        JSON.stringify({
          invoiceGenerator,
          selector,
          draftState,
          discountValue,
          taxValue,
          taxState,
          countrySymbol,
          user,
          discountState,
          taxStatus,
          file,
          hideDiscountBox,
          hideShipBox,
          selectedColor,
          discountValueTotal: {
            key: invoiceGenerator?.discount?.key,
            value: discountValue,
          },
          taxvalueTotal: { key: invoiceGenerator?.tax?.key, value: taxValue },
        })
      );
    }
  }, [updateLocalStorage, selector?.logoLink]);

  const historyHandler = () => {
    // debugger;
    // let newNumberList = JSON.parse(localStorage.getItem("idCollection")) || [];

    // if (invoiceGenerator.billTo.value === "") {
    //   newNumberList.pop();
    //   localStorage.removeItem(`adminProfile${id}`);
    //   localStorage.setItem("idCollection", JSON.stringify(newNumberList));

    //   let idcollectionAfterpop =
    //     JSON.parse(localStorage.getItem("idCollection")) || [];

    //   const index =
    //     idcollectionAfterpop.length <= 0 ? 0 : idcollectionAfterpop.length - 1;

    //   const indexvalue = idcollectionAfterpop[index] || 1;
    //   localStorage.setItem("ids", JSON.stringify(indexvalue));
    // }
    // dispatch(newInvoiceTableData());
    navigate("/history");
  };

  const removeimage = () => {
    setFile("");
    dispatch(logoUploadConfirm(""));
  };

  const colorChangeHandler = (color, newbackgroundColor) => {
    // let selectColor = e.target.value
    if (color === "black") {
      setSelectedColor({
        ...selectedColor,
        light: color,
        dark: color,
      });
    } else {
      setSelectedColor({
        ...selectedColor,
        light: color,
        dark: color,
      });
    }
  };

  const textColorChangeHandler = (color, newbackgroundColor) => {
    // let selectColor = e.target.value
    if (color === "black") {
      setSelectedTextColor({
        ...selectedTextColor,
        light: color,
        dark: "black",
      });
    } else {
      setSelectedTextColor({
        ...selectedTextColor,
        light: color,
        dark: color,
      });
    }
  };
  const [seo, setSeo] = useState([]);
  useEffect(() => {
    const seoApi = async () => {
      await fetch(`${process.env.REACT_APP_BASE_URL}api/util/get-meta`, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: JSON.parse(localStorage.getItem("token")),
        },
      })
        .then((res) => res.json())
        .then((res) => {
          setSeo(res?.data);
        });
    };
    seoApi();
  }, []);

  const handleDragOver = (event) => {
    event.preventDefault();
  };
  const handleDrop = (e) => {
    e.preventDefault();

    let selectedFile = e?.dataTransfer?.files[0];
    let fileType = ["image/jpeg", "image/jpg", "image/png", "image/gif"];

    if (fileType.includes(selectedFile?.type)) {
      setFile(URL.createObjectURL(e?.dataTransfer.files[0]));
      dispatch(logoUpload(formDataGenerator({ file: selectedFile })));
      // debugger;
      setUpdateLocalStorage(updateLocalStorage + 1);
    } else {
      alert(
        "The image you uploaded is not in a file type we accept. Please use a JPEG, PNG, or GIF"
      );
    }
  };

  const resetColor = () => {
    setSelectedColor({
      light: "black",
      dark: "#f4efef",
    });

    setSelectedTextColor({
      dark: "black",
      light: "black",
    });
  };

  const modalfunc = () => {
    dispatch(popAction(false));
  };

  const clickHereFunc = () => {
    dispatch(popAction(false));
    navigate("/documents");
  };

  return (
    <>
      <div className="flex_wrapper_invoicedowloader">
        <ModalBootrap
          show={popUpSelector}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <ModalBootrap.Body className="text-center">
            <span style={{ color: "red", fontWeight: "700" }}>Warning:</span>{" "}
            Invoice Id Already Used. You have previously used this Invoice Id.
            Please use different Invoice Id to avoid Duplication. <br /> Or{" "}
            <br />{" "}
            <span
              style={{ color: "#0000EE", cursor: "pointer" }}
              onClick={clickHereFunc}
            >
              Click Here
            </span>{" "}
            to View Invoices.
            <div className="d-flex justify-content-end">
              <button className="btn btn-primary" onClick={modalfunc}>
                Close
              </button>
            </div>
          </ModalBootrap.Body>
        </ModalBootrap>
        {seo?.map((item) => {
          if (item?.pagename === "Create Invoice") {
            return (
              <Helmet>
                <title>Create Invoice | Easy Invoice</title>
                <meta name="twitter:description" content={item?.description} />
                <meta name="twitter:title" content={item?.title} />
                <meta name="twitter:image" content={item?.image} />
                <script crossorigin="anonymous" async>
                  {`${item?.headerScript}`}
                </script>
                <script crossorigin="anonymous" async>
                  {`${item?.footerScript}`}
                </script>
              </Helmet>
            );
          }
        })}
        {/* <Helmet>
					<title>Create Invoice | Easy Invoice</title>
					<meta name="description" content={seo[2]?.content} />
				</Helmet> */}
        <Header></Header>
        <div className="backgrounds">
          <div className="container" style={{ marginTop: "3.5rem" }}>
            <div className="row  ">
              <div className="col-md-9 bg-white mb-3">
                <div className="row pt-3">
                  <div className="col-md-6 d-flex flex-column">
                    <div className="col-md-6 mb-3 d-flex align-items-center w-100">
                      <div
                        className="header_container"
                        onDrop={handleDrop}
                        onDragOver={handleDragOver}
                      >
                        <img src={LogoImgSelect} alt="logoImgSelect" />

                        <label>
                          <input
                            accept="image/*"
                            type="file"
                            name=" dragLogo"
                            // value={invoiceGenerator?.dragLogo}
                            onChange={(e) => logoChangeHandler(e)}
                          />
                          {file ? (
                            <div style={{ height: "55px", width: "auto" }}>
                              <img
                                src={file}
                                // crossorigin="anonymous"
                                alt="logo"
                                style={{
                                  height: "100%",
                                  maxWidth: "100%",
                                  objectFit: "contain",
                                }}
                              />
                            </div>
                          ) : (
                            <div>
                              <p>
                                Drag your logo here or<u> select a file</u>
                              </p>
                            </div>
                          )}
                        </label>
                        {file ? (
                          <button
                            className="imagecrossbtn"
                            onClick={removeimage}
                          >
                            <ImCross />
                          </button>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-md-6 d-flex flex-column w-100 mb-4">
                      <TextareaAutosize
                        aria-label="empty textarea"
                        placeholder="Who is this Invoice from *? (required)"
                        style={{
                          resize: "none",
                          // fontWeight: `${font.fontWeight} : 900px`,
                          fontSize: "12px",
                        }}
                        className="containerOne_input"
                        value={invoiceGenerator?.companyName}
                        onChange={(e) => invoiceChangeHandler(e, "companyName")}
                        // onKeyPress={handleKeyPress}
                      />
                    </div>
                    <div className="col-md-6 d-flex flex-column w-100 mb-3">
                      <div className="containerOne_info">
                        <input
                          id="bill"
                          type="text"
                          name="key"
                          value={invoiceGenerator?.billTo?.key}
                          onChange={(e) => invoiceChangeHandler(e, "billTo")}
                          style={{
                            color: `${selectedTextColor?.light}`,
                            fontSize: "14px",
                          }}
                        />
                      </div>
                      <TextareaAutosize
                        aria-label="empty textarea"
                        id="bill"
                        type="text"
                        name="value"
                        style={{
                          resize: "none",
                          // fontWeight: `${font.fontWeight} : 900px`,
                          fontSize: "12px",
                        }}
                        placeholder="Who is this Invoice to *? (required)"
                        className="containerTwo_input"
                        value={invoiceGenerator?.billTo?.value}
                        onChange={(e) => invoiceChangeHandler(e, "billTo")}
                      />
                    </div>

                    {/* ..... */}
                    <div className="col-md-6 d-flex flex-column w-100 mb-3">
                      <div className="containerOne_info">
                        <input
                          type="text"
                          name="key"
                          value={invoiceGenerator?.shipTo?.key}
                          onChange={(e) => invoiceChangeHandler(e, "shipTo")}
                          style={{
                            color: `${selectedTextColor?.light}`,
                            fontSize: "14px",
                          }}
                        />
                      </div>
                      <TextareaAutosize
                        aria-label="empty textarea"
                        id="bill"
                        type="text"
                        name="value"
                        style={{
                          resize: "none",
                          // fontWeight: `${font.fontWeight} : 900px`,
                          fontSize: "12px",
                        }}
                        className="containerTwo_input"
                        placeholder="Optional"
                        value={invoiceGenerator?.shipTo?.value}
                        onChange={(e) => invoiceChangeHandler(e, "shipTo")}
                      />
                    </div>

                    {/* ...... */}
                  </div>
                  <div className=" col-md-6 d-flex flex-column ">
                    <div className="invoice_title">
                      <input
                        // style={{ color: `${selectedColor?.light}` }}
                        style={{ color: `${selectedTextColor?.light}` }}
                        type="text"
                        name="title"
                        value={invoiceGenerator?.title}
                        onChange={(e) => invoiceChangeHandler(e, "title")}
                      />
                      {/* </h3> */}
                    </div>
                    <div className="containerTwo">
                      <div className="containerTwo_inputOne invoice-id-key">
                        <input
                          type="text "
                          name="key"
                          value={invoiceGenerator?.invoiceId?.key}
                          onChange={(e) => invoiceChangeHandler(e, "invoiceId")}
                          min="0"
                          style={{ color: `${selectedTextColor?.light}` }}
                        />
                      </div>
                      <div className="containerTwo_inputTwo">
                        <span
                          style={{
                            backgroundColor: `${selectedColor?.dark}`,
                            color: `${selectedTextColor?.light}`,
                            fontSize: "12px",
                          }}
                        >
                          #
                        </span>
                        <input
                          style={{ fontSize: "12px" }}
                          type="number"
                          name="value"
                          value={invoiceGenerator?.invoiceId?.value}
                          onChange={(e) => invoiceChangeHandler(e, "invoiceId")}
                          min="0"
                        />
                      </div>
                    </div>

                    <div className="containerTwo">
                      <div className="containerTwo_inputOne invoice-date-key">
                        <input
                          type="text"
                          name="key"
                          value={invoiceGenerator?.date?.key}
                          onChange={(e) => invoiceChangeHandler(e, "date")}
                          style={{ color: `${selectedTextColor?.light}` }}
                        />
                      </div>

                      <div className="containerTwo_inputTwodate d-flex align-items-center justify-content-center">
                        <input
                          className="date_input_first calender-date"
                          type="text"
                          onClick={openDatePicker}
                          // name="value"
                          // onChange={(e) => invoiceChangeHandler(e, "date")}
                          // value={invoiceGenerator.date.value}
                          onChange={handle}
                          value={state}
                        ></input>

                        <DatePicker
                          className="invisibleInput"
                          selected={startDate}
                          onChange={handleChange}
                          onClickOutside={openDatePicker}
                          open={datePickerIsOpen}
                        />
                      </div>
                    </div>
                    <div className="containerTwo">
                      <div className="containerTwo_inputOne due-date-key">
                        <input
                          type="text"
                          name="key"
                          value={invoiceGenerator?.dueDate?.key}
                          onChange={(e) => invoiceChangeHandler(e, "dueDate")}
                          style={{ color: `${selectedTextColor?.light}` }}
                        />
                      </div>
                      <div className="containerTwo_inputTwodate d-flex align-items-center justify-content-center">
                        <input
                          className="date_input_first due-date"
                          type="text"
                          onClick={dueopenDatePicker}
                          onChange={duehandle}
                          value={duedateState}
                        ></input>

                        <DatePicker
                          className="invisibleInput"
                          selected={duestartDate}
                          onChange={duehandlechange}
                          onClickOutside={dueopenDatePicker}
                          open={duedatePickerIsOpen}
                          dateFormat="yyyy/MM/dd"
                        />
                      </div>
                    </div>
                    <div className="containerTwo">
                      <div className="containerTwo_inputOne payment-mode-key">
                        <input
                          type="text"
                          name="key"
                          value={invoiceGenerator?.paymentTerms?.key}
                          style={{ color: `${selectedTextColor?.light}` }}
                          onChange={(e) =>
                            invoiceChangeHandler(e, "paymentTerms")
                          }
                        />
                      </div>
                      <div className="containerTwo_inputTwo payment-mode">
                        <input
                          type="text"
                          name="value"
                          value={invoiceGenerator?.paymentTerms?.value}
                          onChange={(e) =>
                            invoiceChangeHandler(e, "paymentTerms")
                          }
                        />
                      </div>
                    </div>

                    <div className="containerTwo">
                      <div className="containerTwo_inputOne po-number-key">
                        <input
                          type="text"
                          name="key"
                          value={invoiceGenerator?.poNumber?.key}
                          style={{ color: `${selectedTextColor?.light}` }}
                          onChange={(e) => invoiceChangeHandler(e, "poNumber")}
                        />
                      </div>
                      <div className="containerTwo_inputTwo po-number">
                        <input
                          type="text"
                          name="value"
                          value={invoiceGenerator?.poNumber?.value}
                          onChange={(e) => invoiceChangeHandler(e, "poNumber")}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* TABLE START */}
                <div className="table-container mb-2  ">
                  <div className="wrapper-container p-2 ">
                    <div className="row">
                      <div className="col-12  ">
                        {/* <div className={classes.inputDecoration}> */}
                        <div
                          className="row"
                          style={{
                            backgroundColor: `${selectedColor?.dark}`,
                            color: `${selectedTextColor?.light}`,
                            borderRadius: "4px",
                          }}
                        >
                          <div className=" col-2  col-md-5 col-lg-7 p-2">
                            <input
                              type="text"
                              name="item"
                              value={invoiceGenerator?.invoiceItemHeader?.item}
                              // className="bg-secondary "

                              onChange={(e) =>
                                invoiceChangeHandler(e, "invoiceItemHeader")
                              }
                              style={{
                                border: "none",
                                outline: "none",
                                width: "100%",
                                borderRadius: "4px",
                                // backgroundColor: "#F2F2F2",
                                backgroundColor: `${selectedColor?.dark}`,
                                color: `${selectedTextColor?.light}`,
                                fontSize: "14px",
                              }}
                            />
                          </div>
                          <div className="col-10  col-md-7 col-lg-5   p-2 ">
                            <div className="row  d-flex  justify-content-evenly ">
                              <div className="col-4 col-md-4  ">
                                <input
                                  type="text"
                                  name="quantity"
                                  value={
                                    invoiceGenerator?.invoiceItemHeader
                                      ?.quantity
                                  }
                                  onChange={(e) =>
                                    invoiceChangeHandler(e, "invoiceItemHeader")
                                  }
                                  // className="  bg-secondary"
                                  style={{
                                    border: "none",
                                    outline: "none",
                                    width: "100%",
                                    borderRadius: "4px",
                                    // backgroundColor: "#F2F2F2",
                                    backgroundColor: `${selectedColor?.dark}`,
                                    color: `${selectedTextColor?.light}`,
                                    fontSize: "14px",
                                  }}
                                />
                              </div>
                              <div className="col-4 col-md-3 ">
                                <input
                                  type="text"
                                  // className=" bg-secondary "
                                  name="rate"
                                  value={
                                    invoiceGenerator?.invoiceItemHeader?.rate
                                  }
                                  onChange={(e) =>
                                    invoiceChangeHandler(e, "invoiceItemHeader")
                                  }
                                  style={{
                                    border: "none",
                                    outline: "none",
                                    // backgroundColor: "#F2F2F2",
                                    width: "100%",
                                    backgroundColor: `${selectedColor?.dark}`,
                                    color: `${selectedTextColor?.light}`,
                                    fontSize: "14px",
                                  }}
                                />
                              </div>

                              <div className="col-4   col-md-5 ">
                                <input
                                  type="text"
                                  // className="  bg-secondary"
                                  name="amount"
                                  value={
                                    invoiceGenerator?.invoiceItemHeader?.amount
                                  }
                                  onChange={(e) =>
                                    invoiceChangeHandler(e, "invoiceItemHeader")
                                  }
                                  style={{
                                    border: "none",
                                    outline: "none",
                                    width: "100%",
                                    marginRight: "10px",
                                    borderRadius: "4px",
                                    // backgroundColor: "#F2F2F2",
                                    backgroundColor: `${selectedColor?.dark}`,
                                    color: `${selectedTextColor?.light}`,
                                    fontSize: "14px",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* </div> */}
                      </div>

                      {selector?.invoiceItems?.map((data, index) => (
                        <div className="col-12 px-1">
                          <div className="row">
                            <div className="col-md-12  col-lg-7 col-sm-12 pt-0 px-2 order-md-1 order-sm-last pb-0 mt-1 description-textbox">
                              <TextareaAutosize
                                aria-label="empty textarea"
                                id="bill"
                                type="text"
                                minRows={1}
                                style={{
                                  padding: "4px",
                                  resize: "none",
                                  width: "100%",
                                  borderRadius: "4px",
                                  outline: "none",
                                  border: "1px solid #dddddd",
                                  fontSize: "12px",
                                  height: "36px",
                                  paddingTop: "9px",
                                }}
                                className="description-textbox"
                                placeholder="Description of service or product..."
                                key={data?.id}
                                value={data?.item}
                                name="item"
                                onChange={(e) => dataUpdate(e, data.id)}
                              />
                              {/* ..... */}
                            </div>
                            <div className="col-md-12  col-lg-5  col-sm-12 px-2   order-md-2 order-sm-last py-0 mt-1">
                              <div className="row ps-1 pt-0">
                                <div className="col-3  col-lg-3  col-md-3 p-0 px-0 ms-2 ">
                                  <input
                                    type="number"
                                    placeholder="Quantity"
                                    className=" py-2  quantity-textbox"
                                    style={{
                                      border: "1px solid #dddddd",
                                      textAlign: "center",
                                      // width: "130%",
                                      width: "100%",
                                      borderRadius: "4px",
                                      fontSize: "12px",
                                    }}
                                    min="1"
                                    key={data.id}
                                    value={data?.quantity}
                                    name="quantity"
                                    onChange={(e) => dataUpdate(e, data.id)}
                                  />
                                </div>

                                <div
                                  className="col-3  col-md-3 col-lg-3 p-0 mx-2"
                                  style={{ position: "relative" }}
                                >
                                  <div
                                    className="rate-wrapper-container "
                                    style={{
                                      border: "1px solid #dddddd",
                                      // width: "130%",
                                      width: "100%",
                                      borderRadius: "4px",
                                      paddingTop: "4px",
                                    }}
                                  >
                                    <div
                                      className="label-rate  ms-2"
                                      style={{
                                        position: "absolute",
                                        top: "10px",
                                        // left: "14px",
                                        fontSize: "10px",
                                        // marginRight:"10px"
                                        fontSize: "12px",
                                      }}
                                    >
                                      {" "}
                                      {`${countrySymbol}`}
                                    </div>
                                    <div
                                      className="rate-container "
                                      style={{
                                        width: "80%",
                                        marginLeft: "25px",
                                        paddingRight: "4px",
                                      }}
                                    >
                                      <input
                                        name="rate"
                                        type="number"
                                        placeholder="Rate"
                                        className="ms-1 px-0 pb-2 pt-1 rate-textbox"
                                        style={{
                                          // border: "1px solid #dddddd",
                                          width: "76%",
                                          borderRadius: "4px",
                                          border: "none",
                                          outline: "none",
                                          fontSize: "12px",
                                          // textAlign: "left",
                                          textAlign: "center",
                                          display: "flex",
                                          justifyContent: "flex-end",
                                        }}
                                        min="0"
                                        key={data?.id}
                                        value={data?.rate}
                                        onChange={(e) => dataUpdate(e, data.id)}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div
                                  className="amount-container   col-3  col-md-3 col-lg-3  "
                                  style={{
                                    height: "34px",
                                    maxWidth: "100%",
                                    border: "1px solid #dddddd",
                                    borderRadius: "4px",
                                    fontSize: "10px",
                                    height: "36px",
                                  }}
                                >
                                  <div
                                    style={{ height: "100%" }}
                                    className="  row gap-1 d-flex  align-items-center"
                                  >
                                    <div
                                      className="col-2  me-0"
                                      style={{ fontSize: "12px" }}
                                    >{`${countrySymbol} `}</div>
                                    <div
                                      className="col-6 me-2"
                                      style={{ fontSize: "12px" }}
                                    >
                                      {data?.amount}
                                    </div>
                                  </div>
                                </div>

                                {selector?.invoiceItems?.length > 1 ? ( // 0 changes to 1
                                  <div className="col-1  px-4">
                                    <span>
                                      <img
                                        src={delte}
                                        alt="delete"
                                        onClick={() => deleteHandler(data.id)}
                                        style={{ cursor: "pointer" }}
                                      />
                                    </span>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                {/* TABLE END */}
                <div className="row ">
                  <div className="btnOne">
                    {
                      <button onClick={() => add()}>
                        <img
                          src={addNewInvoiceButton}
                          alt="addNewInvoiceButton"
                        />
                        <span>Line Item</span>
                      </button>
                    }
                  </div>
                </div>
                <div className="row pt-2 ">
                  <div className="col-md-6 d-flex flex-column justify-content-end align-item-center">
                    <div className="col-md-12 d-flex flex-column justify-content-end align-item-center  mb-3">
                      <div className="containerOne_info">
                        <input
                          id="notes"
                          type="text"
                          name="key"
                          value={invoiceGenerator?.notes?.key}
                          style={{
                            color: `${selectedTextColor?.light}`,
                            fontSize: "14px",
                          }}
                          onChange={(e) => invoiceChangeHandler(e, "notes")}
                        />
                      </div>
                      <TextareaAutosize
                        aria-label="empty textarea"
                        id="notes"
                        type="text"
                        name="value"
                        value={invoiceGenerator?.notes?.value}
                        onChange={(e) => invoiceChangeHandler(e, "notes")}
                        minRows={3}
                        style={{
                          maxWidth: "23.375rem",
                          width: "100%",
                          border: "1px dashed #dddddd",
                          borderTop: "none",
                          outline: "none",
                          resize: "none",
                          paddingLeft: "15px",
                          fontSize: "12px",
                        }}
                      />
                    </div>

                    {/* desc */}
                    <div className="col-md-12 d-flex flex-column justify-content-end align-item-center  mb-1">
                      <div className="containerOne_info">
                        <input
                          type="text"
                          name="key"
                          value={invoiceGenerator?.termsAndDescription?.key}
                          style={{
                            color: `${selectedTextColor?.light}`,
                            fontSize: "14px",
                          }}
                          onChange={(e) =>
                            invoiceChangeHandler(e, "termsAndDescription")
                          }
                        />
                      </div>
                      <TextareaAutosize
                        aria-label="empty textarea"
                        id="termAndCondition"
                        type="text"
                        name="value"
                        value={invoiceGenerator?.termsAndDescription?.value}
                        onChange={(e) =>
                          invoiceChangeHandler(e, "termsAndDescription")
                        }
                        minRows={3}
                        style={{
                          maxWidth: "23.375rem",
                          width: "100%",
                          border: "1px dashed #dddddd",
                          borderTop: "none",
                          outline: "none",
                          resize: "none",
                          paddingLeft: "15px",
                          fontSize: "12px",
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-md-6 d-flex flex-column ">
                    <div
                      className="invoiceSummary"
                      style={{
                        borderRadius: "4px",
                        backgroundColor: `${selectedColor?.dark}`,
                        color: `${selectedTextColor?.light}`,
                      }}
                    >
                      <h5>Invoice Summary</h5>
                    </div>

                    <div className="invoiceSummaryOne">
                      <div className="subtotal_left_inputOne">
                        <input
                          type="text"
                          name="key"
                          value={invoiceGenerator?.subtotal?.key}
                          style={{
                            color: `${selectedTextColor?.light}`,
                            fontSize: "14px",
                          }}
                          onChange={(e) => invoiceChangeHandler(e, "subtotal")}
                        />
                      </div>

                      <div className="subtotal_left_inputTwo">
                        <span style={{ fontSize: "12px" }}>
                          {`${countrySymbol} ${invoiceGenerator?.subtotal?.value}`}
                        </span>
                      </div>
                    </div>

                    <div className="showdisplay">
                      {!hideDiscountBox && (
                        <div className="discount_left">
                          <div className="discount_left_inputWrap">
                            <div className="discount_left_inputOne">
                              <input
                                name="key"
                                type="text"
                                value={invoiceGenerator?.discount?.key}
                                style={{
                                  color: `${selectedTextColor?.light}`,
                                  fontSize: "14px",
                                }}
                                onChange={(e) =>
                                  invoiceChangeHandler(e, "discount")
                                }
                              />
                            </div>

                            <div className="discount_left_inputTwo">
                              <span
                                className={
                                  discountState ? "taxPercent" : "symbol"
                                }
                              >
                                {discountState ? "%" : countrySymbol}
                              </span>
                              <input
                                style={{
                                  marginTop: "0px",
                                  textAlign: "end",
                                  fontSize: "12px",
                                }}
                                onChange={(e) =>
                                  invoiceChangeHandler(e, "discount")
                                }
                                min="0"
                                type="number"
                                name="value"
                                value={invoiceGenerator?.discount?.value}
                              />
                            </div>
                          </div>

                          <div>
                            <div>
                              <ImCross
                                className="displaynone"
                                style={{
                                  cursor: "pointer",
                                  marginBottom: "15px",
                                  // marginRight: "15px",
                                  fontSize: "10px",
                                  // marginLeft: "3px",
                                }}
                                onClick={DiscountBox}
                                //   onClick={addNewInputField}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      {!hideDiscountBox && (
                        <span>
                          <BiRefresh
                            className="refresh"
                            onClick={discountAmountHandler}
                          />
                        </span>
                      )}
                    </div>

                    <div className="showdisplay">
                      {!hideTaxBox && (
                        <div className="discount_left">
                          <div className="discount_left_inputWrap">
                            <div className="discount_left_inputOne">
                              <input
                                name="key"
                                type="text"
                                value={invoiceGenerator?.tax?.key}
                                style={{
                                  color: `${selectedTextColor?.light}`,
                                  fontSize: "14px",
                                }}
                                onChange={(e) => invoiceChangeHandler(e, "tax")}
                              />
                            </div>
                            <div
                              className="discount_left_inputTwo"
                              style={{ position: "relative" }}
                            >
                              <span
                                className={taxState ? "taxPercent" : "symbol"}
                              >
                                {taxState ? "%" : countrySymbol}
                              </span>
                              <input
                                style={{ textAlign: "end", fontSize: "12px" }}
                                name="value"
                                type="number"
                                value={invoiceGenerator?.tax?.value}
                                onChange={(e) => invoiceChangeHandler(e, "tax")}
                                min="0"
                              />
                            </div>
                          </div>

                          <div className="newInputPlusIcon">
                            {/* <div> */}
                            <ImCross
                              id="cross"
                              className="displaynone"
                              style={{
                                cursor: "pointer",
                                marginBottom: "15px",
                                fontSize: "10px",
                              }}
                              onClick={TaxBox}
                            />
                            {/* </div> */}

                            {/* <div> */}
                            <BiPlusCircle
                              className="displaynone"
                              style={{
                                cursor: "pointer",
                                marginBottom: "10px",
                              }}
                              onClick={addNewInputField}
                            />
                            {/* </div> */}
                          </div>
                        </div>
                      )}
                      {!hideTaxBox && (
                        <span style={{}}>
                          <BiRefresh
                            className="refresh"
                            onClick={taxAmountHandler}
                          />
                        </span>
                      )}
                    </div>
                    {/* new input field chandra start */}
                    <div className="showdisplay">
                      {newInputList?.map((item) => {
                        return (
                          <div className="discount_left">
                            <div className="discount_left_inputWrap">
                              <div className="discount_left_inputOne">
                                <input
                                  name="key"
                                  type="text"
                                  //   value={invoiceGenerator?.discount?.key}
                                  value={item?.key}
                                  style={{
                                    color: `${selectedTextColor?.light}`,
                                    fontSize: "14px",
                                  }}
                                  onChange={(e) =>
                                    newInputChangeHandler(e, item.id)
                                  }
                                />
                              </div>
                              <div className="discount_left_inputTwo">
                                <span className="taxPercent">{"%"}</span>
                                <input
                                  style={{
                                    marginTop: "0px",
                                    textAlign: "end",
                                    fontSize: "12px",
                                  }}
                                  // onChange={(e) =>
                                  //   invoiceChangeHandler(e, "discount")
                                  // }
                                  onChange={(e) =>
                                    newInputChangeHandler(e, item.id)
                                  }
                                  // min='0'
                                  type="number"
                                  name="value"
                                  value={item?.value}
                                  //   value={invoiceGenerator?.discount?.value}
                                />
                              </div>
                            </div>
                            <div>
                              <ImCross
                                className="displaynone"
                                style={{
                                  cursor: "pointer",
                                  marginBottom: "15px",
                                  // marginRight: "15px",
                                  fontSize: "10px",
                                }}
                                onClick={() => deleteInputField(item?.id)}
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    {/* new input field chandra end*/}
                    <div className="showdisplay">
                      {!hideShipBox && (
                        <div className="discount_left">
                          <>
                            <div className="discount_left_inputWrap">
                              <div className="discount_left_inputOne">
                                <input
                                  name="key"
                                  type="text"
                                  value={invoiceGenerator?.shipping?.key}
                                  style={{
                                    color: `${selectedTextColor?.light}`,
                                    fontSize: "14px",
                                  }}
                                  onChange={(e) =>
                                    invoiceChangeHandler(e, "shipping")
                                  }
                                />
                              </div>
                              <div className="discount_left_inputTwo">
                                <input
                                  style={{ textAlign: "end", fontSize: "12px" }}
                                  type="number"
                                  name="value"
                                  onChange={(e) =>
                                    invoiceChangeHandler(e, "shipping")
                                  }
                                  placeholder={countrySymbol}
                                  value={invoiceGenerator?.shipping?.value}
                                />
                              </div>
                            </div>
                            <div>
                              <ImCross
                                id="cross"
                                className="displaynone"
                                style={{
                                  cursor: "pointer",
                                  marginBottom: "15px",
                                  fontSize: "10px",
                                  resize: "none",
                                }}
                                onClick={ShippedBox}
                              />
                            </div>
                          </>
                        </div>
                      )}
                    </div>
                    {/*  */}

                    <div className="discount_shipping">
                      {hideDiscountBox && (
                        <span
                          onClick={discountHandler}
                          style={{ cursor: "pointer", color: "blue" }}
                        >
                          + Discount
                        </span>
                      )}

                      {/*  */}
                      {hideTaxBox && (
                        <span
                          onClick={TaxHandler}
                          style={{ cursor: "pointer", color: "blue" }}
                        >
                          + Tax
                        </span>
                      )}

                      {/*  */}
                      {hideShipBox && (
                        <span
                          onClick={shippingHandler}
                          style={{ cursor: "pointer", color: "blue" }}
                        >
                          + Shipping
                        </span>
                      )}
                    </div>

                    <div className="invoiceSummaryOne">
                      <div className="subtotal_left_inputOne">
                        <input
                          type="text"
                          name="key"
                          value={invoiceGenerator?.total?.key}
                          style={{
                            color: `${selectedTextColor?.light}`,
                            fontSize: "14px",
                          }}
                          onChange={(e) => invoiceChangeHandler(e, "total")}
                        />
                      </div>
                      <div className="subtotal_left_inputTwo">
                        <span style={{ fontSize: "12px" }}>
                          {/* {`${countrySymbol} ${selector?.subtotal > 0 ? total : 0}`} */}
                          {`${countrySymbol} ${invoiceGenerator?.total?.value}`}
                        </span>
                      </div>
                    </div>

                    <div className="amountPaid">
                      <div className="amountPaid_inputOne">
                        <input
                          type="text"
                          name="key"
                          value={invoiceGenerator?.amountPaid?.key}
                          style={{
                            color: `${selectedTextColor?.light}`,
                            fontSize: "14px",
                          }}
                          min="0"
                          onChange={(e) =>
                            invoiceChangeHandler(e, "amountPaid")
                          }
                        />
                      </div>

                      <div
                        className="amountPaid_inputTwo"
                        style={{ position: "relative" }}
                      >
                        <span
                          style={{
                            position: "absolute",
                            left: "10px",
                            top: "16px",
                            color: "#888888",
                            fontSize: "12px",
                          }}
                        >
                          {countrySymbol}
                        </span>
                        <input
                          type="number"
                          name="value"
                          value={invoiceGenerator?.amountPaid?.value}
                          min="0"
                          onChange={(e) =>
                            invoiceChangeHandler(e, "amountPaid")
                          }
                          style={{ fontSize: "12px" }}
                        />
                      </div>
                    </div>

                    <div
                      className="invoiceSummaryOne"
                      style={{
                        backgroundColor: `${selectedColor?.dark}`,
                        borderRadius: "4px",
                        color: `${selectedTextColor?.light}`,
                      }}
                    >
                      <div className="subtotal_left_inputOne">
                        <input
                          style={{
                            backgroundColor: `${selectedColor?.dark}`,
                            borderRadius: "4px",
                            color: `${selectedTextColor?.light}`,
                            fontSize: "14px",
                          }}
                          type="text"
                          name="key"
                          value={invoiceGenerator?.balance?.key}
                          min="0"
                          onChange={(e) => invoiceChangeHandler(e, "balance")}
                        />
                      </div>
                      <div className="subtotal_left_inputTwo ">
                        <span
                          className="balance-value"
                          style={{ fontSize: "12px" }}
                        >{`${countrySymbol} ${
                          invoiceGenerator?.balance?.value
                          // selector?.subtotal > 0 ? balance : 0
                        }`}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                {/* <RightSide /> */}
                <div className="buttonContainer">
                  <div className="mb-3 ">
                    <button onClick={SendInvoice} className="buttonOne">
                      <img src={SendIcon} alt="" /> Send Invoice
                    </button>
                  </div>
                  <div
                    className={
                      invoiceGenerator?.billTo?.value?.length === 0 ||
                      invoiceGenerator?.companyName?.length === 0
                        ? // selector?.invoiceItems?.length === 0
                          "disable"
                        : "buttonTwo"
                    }
                  >
                    <button
                      onClick={
                        // selector?.invoiceItems?.[0]?.item?.length === 0
                        //   ? showtoast
                        //   : showModal
                        showModal
                      }
                      disabled={
                        invoiceGenerator?.billTo?.value?.length === 0 ||
                        invoiceGenerator?.companyName?.length === 0
                        // selector?.invoiceItems?.length === 0
                      }
                    >
                      <img src={DownloadIcon} alt="" />
                      Download Invoice
                    </button>
                  </div>

                  <Modal
                    show={isOpen}
                    onHide={hideModal}
                    backdrop="static"
                    keyboard={false}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title className="fileFormatHeading">
                        <h4>What file format do you want ?</h4>
                      </Modal.Title>
                    </Modal.Header>
                    {/* <Modal.Body>The body</Modal.Body> */}
                    <Modal.Footer>
                      <div className="d-flex w-100 justify-content-between align-items-center ">
                        <div
                          className="invoicePdfButton"
                          onClick={() => handlePrint()}
                        >
                          <div className="iconAndTextContainer">
                            <div className="myIconContainer">
                              <img
                                src={IconFeather}
                                alt=""
                                className="download-icon-img"
                                color="blue"
                              />
                            </div>

                            <div className="pdfTextContainer">
                              <p className="">PDF</p>
                              <p className="">(Recommended)</p>
                            </div>
                          </div>
                        </div>

                        <div
                          className="invoicePdfButton"
                          onClick={() => eInvoice()}
                        >
                          <div className="iconAndTextContainer">
                            <div className="myIconContainer">
                              <img
                                src={IconFeather}
                                alt=""
                                className="download-icon-img"
                                color="blue"
                              />
                            </div>
                            <div className="pdfTextContainer1">
                              <p className=""> E-Invoice</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal.Footer>
                  </Modal>

                  <div className="select_container">
                    <p>Currency Symbol</p>
                    <select onChange={handleSelectChange} className="px-3">
                      <option
                        value={
                          currencyinput?.data?.currency
                            ? currencyinput?.data?.currency
                            : "$"
                        }
                        selected
                        disabled
                        hidden
                      >
                        {currencyCode}{" "}
                        {currencyinput?.data?.currency
                          ? currencyinput?.data?.currency
                          : "$"}
                      </option>
                      {CurrencyData.currency.map((eachCountry, index, name) => {
                        return (
                          <>
                            <option
                              key={index.toString()}
                              value={eachCountry.symbol}
                            >
                              {`${eachCountry.code} ${eachCountry.symbol}`}
                            </option>
                          </>
                        );
                      })}
                    </select>
                  </div>
                  <hr />
                  <button
                    className=" history-btn border-0 "
                    onClick={historyHandler}
                  >
                    History{" "}
                    <span className="history-notification-design">
                      {invoicenumber}
                    </span>
                  </button>

                  <ColorBox
                    colorChangeHandler={colorChangeHandler}
                    selectedColor={selectedColor}
                  />
                  <TextColorBox
                    textColorChangeHandler={textColorChangeHandler}
                    selectedTextColor={selectedTextColor}
                  />
                  <button
                    onClick={resetColor}
                    className="btn btn-primary"
                    style={{ marginLeft: "5px", marginTop: "8px" }}
                  >
                    Reset Color
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer2></Footer2>
      </div>
    </>
  );
};

export default InvoiceDownloader;
