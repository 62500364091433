import "./Notfound.css";
import { Link, useLocation } from "react-router-dom";
const Notfound = () => {
  const user = localStorage.getItem("Admin");
  let location = useLocation();

  return (
    <>
      <div
        className="container  pagenotfound text-center d-flex flex-column
 justify-content-center align-items-center"
      >
        <div className="four_zero_four_bg"></div>
        <br />

        <div>404 | Page Not Found</div>
        <br />

        <Link to="/">
          <button className="btn btn-primary" style={{ textColor: "white" }}>
            BACK TO HOME
          </button>
        </Link>
      </div>
    </>
  );
};

export default Notfound;
