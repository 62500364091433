import React, { useState } from "react";
import Header from "../Header/Header";
import "../About/About.css";
import { Helmet } from "react-helmet";
import Footer2 from "../Footer/Footer1";

export default function Privacy() {
  const [seo, setSeo] = useState([]);

  return (
    <>
      {seo?.map((item) => {
        if (item?.pagename === "Invoice Guide") {
          return (
            <Helmet>
              <title>Invoice Guide | Easy Invoice</title>
              <meta name="description" content={item?.content} />
            </Helmet>
          );
        }
      })}
      <Header></Header>
      <section className="invoice-guide" style={{ marginTop: "6rem" }}>
        <div className="container lmt-5">
          <div className="row gx-0 pt-5 mt-5">
            <div className="col-sm-12 ">
              <div className="heading text-center">
                <div className="d-flex justify-content-center align-items-center">
                  <h1>
                    <span className="heading-text">
                      Privacy<span style={{ color: "black" }}> Policy</span>
                    </span>
                    <h3 className="terms-font mt-3">
                      Our E-Invoicing website is committed to ensuring the
                      privacy of its users and visitors. This privacy policy
                      page outlines the information we collect, how we use it,
                      and the measures we take to protect your data.
                    </h3>
                  </h1>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-md-12 mt-5">
                  <h4 className="privacy-heading">Information Collection</h4>
                  <p className="text-left about-para ">
                    We collect personal information such as name, address, phone
                    number, email address, and financial information when you
                    register with our website. We may also collect information
                    about your use of our website, such as the pages you visit,
                    the links you click, and the searches you make.
                  </p>
                  <h4 className="privacy-heading">Use of Information</h4>
                  <p className="text-left about-para ">
                    We use the information we collect to provide you with our
                    services, such as processing invoices and payments. We may
                    also use your information to communicate with you regarding
                    your account or to provide you with updates or marketing
                    materials.
                  </p>
                  <h4 className="privacy-heading">Sharing of Information</h4>
                  <p className="text-left about-para ">
                    We do not share your personal information with third
                    parties, except as required by law or as necessary to
                    provide you with our services. We may share your information
                    with our affiliates or partners, but only if they agree to
                    protect your privacy in accordance with this policy.
                  </p>
                  <h4 className="privacy-heading">Security</h4>
                  <p className="text-left about-para ">
                    We take the security of your personal information seriously
                    and employ appropriate measures to protect your data. We use
                    industry-standard encryption technologies to protect your
                    data during transmission and storage.
                  </p>
                  <h4 className="privacy-heading">Cookies</h4>
                  <p className="text-left about-para ">
                    We use cookies to improve your experience on our website.
                    Cookies are small text files that are stored on your
                    computer or mobile device when you visit our website. We use
                    cookies to remember your preferences, personalize your
                    experience, and analyze how our website is used.
                  </p>
                  <h4 className="privacy-heading">Your Rights</h4>
                  <p className="text-left about-para ">
                    You have the right to access, correct, or delete your
                    personal information. You may also choose to unsubscribe
                    from our marketing communications. To exercise your rights,
                    please contact us at the email address provided below.
                  </p>
                  <h4 className="privacy-heading">
                    Changes to this Privacy Policy
                  </h4>
                  <p className="text-left about-para ">
                    We reserve the right to modify this privacy policy at any
                    time. Any changes will be posted on our website, and we
                    encourage you to review this page periodically.
                  </p>
                  <h4 className="privacy-heading">Contact Us</h4>
                  <p className="text-left about-para ">
                    If you have any questions or concerns about our privacy
                    policy or how we handle your personal information, please
                    contact us at{" "}
                    <a href="mailto:ezinvoicegenerator@gmail.com">
                      ezinvoicegenerator@gmail.com
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="heading text-center mt-5">
            <div className="d-flex justify-content-center align-items-center">
              <h1>
                <h4 className="extra-font">Thanks For Visiting Our Site</h4>
                <h4 className="heading-text">Have a nice day !</h4>
              </h1>
            </div>
          </div>
        </div>
      </section>
      <Footer2></Footer2>
    </>
  );
}
