import React from "react";
import { useState, useEffect } from "react";
import "./Documents.css";
import axios from "axios";
import "./CreatedInvoice.css";
import { TablePagination } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
// import DeleteIcon from "@mui/icons-material/Delete";
import { TbChevronDown, TbSend } from "react-icons/tb";
import { MdEdit } from "react-icons/md";
import { toast } from "react-toastify";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import EditIcon from "../../assets/EditIcon.svg";
import DeleteIcon from "../../assets/DeleteIcon.svg";
import SendIcon from "../../assets/SendIcon.svg";
import cloneIcon from "../../assets/cloneIcon.svg";
import seeInvoiceIcon from "../../assets/seeInvoiceIcon.svg";
// Ali code
import { FaSistrix } from "react-icons/fa";
import DatePicker from "react-datepicker";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { BiX } from "react-icons/bi";
import SearchComponents from "./SearchComponent";
import { debounce } from "lodash";

const SentDocument = (props) => {
  const [searchInput, setSearchInput] = useState({
    input: "",
    month: "",
  });
  const navigate = useNavigate();
  const animatedComponents = makeAnimated();
  const [input, setInput] = useState();
  const [count, setCount] = useState(0);
  const [newState, setNewState] = useState([]);
  const [show, setShow] = useState(false);
  const [modalstate, setModalstate] = useState(false);
  const [cloneApiResponse, setCloneApiResponse] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataUpdate, setDataUpdate] = useState(0);
  const [controller, setController] = useState({
    currentpage: 0,
    rowsPerPage: 10,
  });

 const [hideCrossIcon , setHideCrossIcon] = useState(false);


  const handleClose = () => {
    setNewState([]);
    setShow(false);
  };

  const handleShow = (id, invoiceId) => {
    setNewState([{ value: id, label: invoiceId }]);
    setShow(true);
  };

  const [formvalue, setFormvalue] = useState({
    subject: "",
    receiverEmail: "",
    invoiceId: [],
    notes: "",
  });

  let name1, val;
  const save = (e) => {
    setFormvalue({ ...formvalue, invoiceId: [newState?.[0]?.value] });
    name1 = e.target.name;
    val = e.target.value;
    setFormvalue({ ...formvalue, [name1]: val });
  };

  useEffect(() => {
    setFormvalue({ ...formvalue, invoiceId: [newState?.[0]?.value] });
  }, [newState]);

  const datasave = async () => {
    setLoading(true);
    await fetch(
      `${process.env.REACT_APP_BASE_URL}api/invoice/get-invoice?offset=${controller.currentpage}&limit=${controller.rowsPerPage}`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: localStorage.getItem(`token`).replaceAll('"', ""),
        },
      }
    )
      .then((response) => response.json())
      .then((response) => {
        const selectedData = response?.data?.map((val, ind) => {
          return {
            ...val,
            value: val?.id,
            label: val?.invoiceId?.value,
            deleting: false,
            cloning: false,
            viewInvoice: false,
          };
        });

        setLoading(false);
        setInput(selectedData);
        setCount(response?.total ? response?.total : 0);
        const trashGet = JSON.parse(localStorage.getItem("trash"));
        const sentGet = JSON.parse(localStorage.getItem("sent"));
        props?.notificationFunction(
          response?.total ? response?.total : 0,
          trashGet,
          sentGet
        );
        props.child(response?.total, selectedData);
      })
      .catch((error) => {
        // console.log(error, "joih");
        setLoading(false);
      });
  };
  useEffect(() => {
    if (searchInput?.input || searchInput?.month) {
      searchInputApi(searchInput?.input, searchInput?.month);
    } else {
      datasave();
    }
  }, [controller, cloneApiResponse, dataUpdate]);

  const handlePageChange = (event, newPage) => {
    setController({
      ...controller,
      currentpage: newPage,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setController({
      ...controller,
      rowsPerPage: parseInt(+event.target.value, 10),
      currentpage: 0,
    });
  };

  const moadalDataPost = async (e) => {
    e.preventDefault();

    const { subject, receiverEmail, invoiceId, notes } = formvalue;

    if (receiverEmail.length <= 2) {
      toast.error("Please Enter Your Email");
    }

    receiverEmail.length >= 3 && setShow(false);
    receiverEmail.length >= 3 &&
      (await fetch(
        `${process.env.REACT_APP_BASE_URL}api/invoice/send-invoice`,
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
            Authorization: localStorage.getItem(`token`).replaceAll('"', ""),
          },
          body: JSON.stringify({
            subject,
            receiverEmail,
            invoiceId,
            notes,
          }),
        }
      )
        .then((response) => response.json())
        .then((response) => {
          if (response.success === true) {
            const createdGet = JSON.parse(localStorage.getItem("created"));
            const trashGet = JSON.parse(localStorage.getItem("trash"));

            const sentGet = JSON.parse(localStorage.getItem("sent"));
            const newSentData = sentGet + 1;
            localStorage.setItem("sent", JSON.stringify(newSentData));

            props?.notificationFunction(createdGet, trashGet, newSentData);
            toast.success(response.message);
          } else {
            throw response.message;
          }

          // debugger;
          // setMessage(response.error);

          // localStorage.setItem('auth', JSON.stringify(response.data[0]))
          // window.location.reload();
        })
        .catch((error) => {
          // console.log(error, "joih");
          toast.error(error);
        }));
  };

  const deleteCreatedInvoice = async (userId, invoiceId, index) => {
    const sure = window.confirm(
      "Are you sure you want to delete your invoices?"
    );
    if (sure === true) {
      const loading = input?.map((val, ind) => {
        if (ind === index) {
          return { ...val, deleting: true };
        } else {
          return val;
        }
      });

      setInput(loading);
    }
    sure === true &&
      (await fetch(
        `${process.env.REACT_APP_BASE_URL}api/invoice/soft-delete-invoice`,
        {
          method: "PUT",
          headers: {
            "content-type": "application/json",
            Authorization: localStorage.getItem(`token`).replaceAll('"', ""),
          },

          body: JSON.stringify({
            id: invoiceId,
            // userId: userId,
          }),
        }
      )
        .then((response) => response.json())
        .then((response) => {
          if (response.status === true) {
            const trashGet = JSON.parse(localStorage.getItem("trash"));

            localStorage.setItem("trash", JSON.stringify(trashGet + 1));

            setDataUpdate(dataUpdate + 1);
            toast.success(response?.message);
          } else {
            throw response?.message;
          }
        })
        .catch((error) => {
          // console.log(error, "joih");
          toast.error(error);
        }));
  };

  const handleViewIcon = async (id, index) => {
    const invoiceView = input?.map((val, ind) => {
      if (ind === index) {
        return { ...val, viewInvoice: true };
      } else {
        return val;
      }
    });
    setInput(invoiceView);

    let a = input?.filter((item) => item?._id === id);
    await fetch(`${process.env.REACT_APP_BASE_URL}api/invoice/download/${id}`, {
      method: "GET",
      responseType: "blob",
      headers: {
        "content-type": "application/pdf",
       "document-type": "pdfContent",
        Authorization: localStorage.getItem(`token`).replaceAll('"', ""),
      },
      // body: JSON.stringify({
      //  ...a[0]
      // }),
    })
      .then((res) => res.blob())
      .then((res) => {
        const blob = new Blob([res], {
          type: "application/pdf",
        });
        const fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
        setDataUpdate(dataUpdate + 1);
      });
  };

  const cloneInvoiceHandler = async (id, assignid, index) => {
    const cloningInvoice = input?.map((val, ind) => {
      if (ind === index) {
        return { ...val, cloning: true };
      } else {
        return val;
      }
    });

    setInput(cloningInvoice);

    await fetch(`${process.env.REACT_APP_BASE_URL}api/invoice/clone-invoice`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: localStorage.getItem(`token`).replaceAll('"', ""),
      },
      body: JSON.stringify({
        userId: id,
        id: assignid,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (!res?.status) {
          throw Error(res?.message);
        }
        if (res?.status === true) {
          setCloneApiResponse(!cloneApiResponse);
          toast.success(res?.message);
        }
      })
      .catch((error) => {
        toast.error(error?.message);
      });
  };

  // const handleClearFilter = () => {
  // 	datasave()
  // 	setStartDate(null)
  // 	setQuery("")
  // }

  const handleSearchInput = debounce((text) => {
    let input = text;
    let month = searchInput?.month;
    setSearchInput({ ...searchInput, input: text });
    searchInputApi(input, month);
    setController({...controller, currentpage: 0, rowsPerPage: 10})
  }, 500);

 

  function searchInputApi(input, month) {
    // const settime = setTimeout(() => {
      if (month?.length || input.length > 0) {
        setLoading(true);
        fetch(
          `${process.env.REACT_APP_BASE_URL}api/invoice/get-timewise-invoice?offset=${controller.currentpage}&limit=${controller.rowsPerPage}`,
          {
            method: "POST",
            headers: {
              "content-type": "application/json",
              Authorization: localStorage.getItem(`token`).replaceAll('"', ""),
            },
            body: JSON.stringify({
              input: input,
              month: month,
            }),
          }
        )
          .then((response) => response.json())
          .then((response) => {
            if (!response?.success) {
              throw Error(response.message);
            }

            if (response?.success === true) {
              setInput(response?.data);
              setCount(response?.total ? response?.total : 0);
              setLoading(false);
            }
          })
          .catch((error) => {
            setLoading(false);
            // console.log(error, "thisIsError");
          });
      } else {
        datasave();
      }
    // }, 1000);

    // return () => clearTimeout(settime);
  }

  const handleSelectDates = (date) => {
    var currMonthName = moment(date).format("MMM");
    var prevYearName = moment(date).format("YYYY");
    let changesInYear = currMonthName + "," + prevYearName;
    setSearchInput({ ...searchInput, month: changesInYear });

  
 
  };
  useEffect(() => {
    if(searchInput.month <= 0){
      setHideCrossIcon(true)
    }else{
      setHideCrossIcon(false)
    }
  } , [searchInput.month])


  const fetchSearchData = () => {
    let input = searchInput?.input;
    let month = searchInput?.month;
    setController({...controller, currentpage: 0, rowsPerPage: 10})
    searchInputApi(input, month);
  };

  const handleClearFilter = () => {
    let input = searchInput?.input;
    let month = "";
    setSearchInput({ month: "" , input:input });
    searchInputApi(input, month);
  };

  return (
    <>
      {
        <div>
          {/* =========== Modal Start============= */}

          <Modal
            show={show}
            onHide={handleClose}
            scrollable={true}
            className="main-modal"
          >
            <Modal.Header>
              <div className="text-center mt-4">
                <h2 className="modal-send-document-text">Send Invoices</h2>
                <p className="modal-green-text ">
                  Send a invoice like a purchase order or invoice to businesses
                  in your network.
                </p>
              </div>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div className="">
                  <div className=" modal-radius">
                    <div className="modal-body">
                      <form>
                        <div className="mt-3">
                          <h6 className="modal-who-is-receipent">
                            What is the Subject?
                          </h6>
                          <label className="email-address-class">SUBJECT</label>

                          <input
                            class="form-control form-control-md button-focus input-field1"
                            type="text"
                            placeholder=" "
                            aria-label=".form-control-lg example"
                            name="subject"
                            value={formvalue?.subject}
                            onChange={save}
                            minLength={3}
                            required
                          />
                        </div>

                        <div className="mt-4">
                          <h6 className="modal-who-is-receipent">
                            Who is the recipient?
                          </h6>
                          <label className="email-address-class">
                            EMAIL ADDRESS
                          </label>

                          <input
                            class="form-control form-control-md button-focus input-field1"
                            type="text"
                            placeholder=" "
                            aria-label=".form-control-lg example"
                            name="receiverEmail"
                            value={formvalue?.receiverEmail}
                            onChange={save}
                            minLength={3}
                            required
                          />
                        </div>

                        <div className="mt-4">
                          <h6 className="which-document-like-send">
                            Which document would you like to send?
                          </h6>
                          <label className="email-address-class">INVOICE</label>

                          <Select
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            onChange={setNewState}
                            value={newState?.[0]}
                            defaultValue={[newState?.[0]]}
                            isMulti
                            options={newState}
                          />
                        </div>

                        <div className="mt-4">
                          <h6 className="modal-who-is-receipent">
                            Any Specific Custom Notes?
                          </h6>
                          <label className="email-address-class">NOTES</label>

                          <textarea
                            class="form-control form-control-md button-focus input-field1"
                            type="text"
                            placeholder=" "
                            aria-label=".form-control-lg example"
                            name="notes"
                            value={formvalue?.notes}
                            onChange={save}
                            minLength={3}
                            required
                          />
                          <div>
                            <p className="modal-bottom-blue-text">
                              Documents must be formatted using UBL (Universal
                              Business Language). If you need a tool to help you
                              generate UBL documents try easyinvoice.com{" "}
                            </p>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <div className="modal-footer mb-3">
              <button
                className="btn button-focus cancel-button-modal"
                type="submit"
                onClick={handleClose}
              >
                Cancel
              </button>

              <button
                type="button"
                className="btn btn-primary px-5 button-focus"
                onClick={moadalDataPost}
                data-bs-dismiss={modalstate === false ? "koi" : "modal"}
              >
                Send
              </button>
            </div>
          </Modal>
          <SearchComponents
            handleSearchInput={(e) => handleSearchInput(e)}
            handleSelectDates={(date) => handleSelectDates(date)}
            searchInput={searchInput}
            fetchSearchData={fetchSearchData}
            handleClearFilter={handleClearFilter}
            hideCrossIcon = {hideCrossIcon}
          />
          <p className="below-tab-fonts">Created</p>
          <div className="table-responsive ">
            <table className="table table-striped table-hover ">
              {input?.length > 0 ? (
                <>
                  <thead className="table-head text-center">
                    <tr>
                      <th>#Invoice</th>
                      {/* <th >Email</th> */}
                      <th>Recepient</th>
                      <th>Bill&nbsp;Date</th>
                      {/* <th>Sent&nbsp;Date</th> */}
                      {/* <th>Payment&nbsp;Type</th> */}
                      <th>Amount</th>

                      <th>Actions</th>
                    </tr>
                  </thead>
                  <>
                    <tbody className="text-center">
                      {input?.map((val, index) => {
                        return (
                          <>
                            <tr>
                              <td>{val?.invoiceId?.value}</td>
                              <td>{val?.billTo?.value} </td>
                              <td>{val?.date?.value}</td>
                              {/* <td>{val?.paymentTerms?.value}</td> */}
                              <td>
                                {val?.symbol} {val?.total?.value}
                              </td>

                              <td>
                                <span className="d-flex align-items-center justify-content-center">
                                  {" "}
                                  <img
                                    src={SendIcon}
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Send"
                                    alt="send"
                                    style={{
                                      cursor: "pointer",
                                      height: "14px",
                                      width: "14px",
                                    }}
                                    className="sendIconClass"
                                    onClick={() =>
                                      handleShow(
                                        val?._id,
                                        val?.invoiceId?.value
                                      )
                                    }
                                  ></img>
                                  <Link
                                    to="/edit-invoice"
                                    state={val}
                                    style={{ paddingBottom: "0.219rem" }}
                                  >
                                    <img
                                      src={EditIcon}
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Edit"
                                      alt="edit"
                                      className="editIconClass"
                                      style={{
                                        cursor: "pointer",
                                        height: "14px",
                                        width: "14px",
                                      }}
                                      // onClick={editClickHandler}
                                    ></img>
                                  </Link>
                                  <img
                                    src={cloneIcon}
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Clone"
                                    alt="clone"
                                    style={{
                                      cursor: "pointer",
                                      height: "14px",
                                      width: "14px",
                                    }}
                                    className="sendIconClass"
                                    onClick={() =>
                                      cloneInvoiceHandler(
                                        val?._id,
                                        val?.assignedUsers?.id,
                                        index
                                      )
                                    }
                                  ></img>
                                  {val?.cloning === true && (
                                    <Backdrop
                                      sx={{
                                        color: "#fff",
                                        zIndex: (theme) =>
                                          theme.zIndex.drawer + 1,
                                      }}
                                      open
                                    >
                                      <CircularProgress color="inherit" />
                                    </Backdrop>
                                  )}
                                  <img
                                    src={seeInvoiceIcon}
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="View Invoice"
                                    alt="View Icon"
                                    style={{
                                      cursor: "pointer",
                                      height: "14px",
                                      width: "14px",
                                    }}
                                    className="sendIconClass seeInvoiceClass"
                                    onClick={() =>
                                      handleViewIcon(val?._id, index)
                                    }
                                  ></img>
                                  {val?.viewInvoice === true && (
                                    <Backdrop
                                      sx={{
                                        color: "#fff",
                                        zIndex: (theme) =>
                                          theme.zIndex.drawer + 1,
                                      }}
                                      open
                                    >
                                      <CircularProgress color="inherit" />
                                    </Backdrop>
                                  )}
                                  <img
                                    src={DeleteIcon}
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Delete"
                                    alt="delete"
                                    className="deleteIconClass"
                                    style={{
                                      cursor: "pointer",
                                      height: "14px",
                                      width: "14px",
                                    }}
                                    onClick={() =>
                                      deleteCreatedInvoice(
                                        val?.userId,
                                        val?._id,
                                        index
                                      )
                                    }
                                  ></img>
                                  {val?.deleting === true && (
                                    <Backdrop
                                      sx={{
                                        color: "#fff",
                                        zIndex: (theme) =>
                                          theme.zIndex.drawer + 1,
                                      }}
                                      open
                                    >
                                      <CircularProgress color="inherit" />
                                    </Backdrop>
                                  )}
                                </span>{" "}
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </>
                </>
              ) : (
                <>
                  <thead className="table-head">
                    <tr>
                      <th>#Invoice</th>
                      {/* <th >Email</th> */}
                      <th>Recepient</th>
                      <th>Bill&nbsp;Date</th>
                      {/* <th>Sent&nbsp;Date</th> */}
                      {/* <th>Payment&nbsp;Type</th> */}
                      <th>Amount</th>

                      <th>Actions</th>
                    </tr>
                  </thead>
                  <>
                    <tbody>
                      <tr>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td style={{ textAlign: "center" }}>
                          No Invoices Found{" "}
                        </td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                      </tr>
                    </tbody>
                  </>
                </>
              )}

              {loading && (
                <>
                  <tbody></tbody>
                  <tfoot>
                    <tr>
                      <td colSpan="3"></td>
                    </tr>
                  </tfoot>

                  <Backdrop
                    sx={{
                      color: "#fff",
                      zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop>
                </>
              )}
            </table>

            <hr></hr>
            <TablePagination
              component="div"
              count={count}
              rowsPerPage={controller?.rowsPerPage}
              page={controller?.currentpage}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </div>
      }
    </>
  );
};
export default SentDocument;