import React, { useState, useEffect } from "react";
import "./Documents.css";
import CreatedInvoice from "./CreatedInvoice";
import RecivedDocument from "./RecievedDocument";
import SentDocument from "./SentDocument";
import { Tabs } from "./Tabs";
import Modal from "react-modal";
import ReactModal from "react-modal";
// import sendImage from "../../assets/send.png"
import { TbChevronDown, TbSend } from "react-icons/tb";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { Helmet } from "react-helmet";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Trash from "./Trash";

const Documents = () => {
	const animatedComponents = makeAnimated();
	const [modalstate, setModalstate] = useState(false);

	const [createdNumberTrack, setCreatedNumberTrack] = useState(0);
	const [formvalue, setFormvalue] = useState({
		receiverEmail: "",
		invoiceId: "",
	});

	const [select, setSelect] = useState();
	const [createdInvoice, setCreatedInvoice] = useState();
	const [receivedInvoice, setReceivedInvoice] = useState();

	const [invoice, setInvoice] = useState();

	const [getinvoice, setGetinvoice] = useState();
	const [sentInvoice, setSentInvoice] = useState();
	const [trashNumberTrack, setTrashnumberTrack] = useState();
	const [sentNumberTrack, setSentNumberTrack] = useState();

	const child = (createdCount, CreatedApiResponse) => {
		localStorage.setItem("created", JSON.stringify(createdCount));
		setSelect(createdCount);
		setCreatedInvoice(CreatedApiResponse);
		//  setTrashnumberTrack(trashNumberTrack+1)
	};

	// const receivedChild=(receivedApiResponse)=>{

	//   setInvoice(receivedApiResponse)

	// }

	const sentChild = (sentApiResponse) => {
	
		localStorage.setItem("sent", JSON.stringify(sentApiResponse?.totalSentInvoice));
		setGetinvoice(sentApiResponse);
	};

	const trashChild = (trashApiResponse) => {
		localStorage.setItem("trash", JSON.stringify(trashApiResponse?.total));
		setInvoice(trashApiResponse);

		// invoice?.map((val)=>{
		//   console.log(val, "rtui");
		// return val
		// })
	};

	const notificationFunction = (createdNumber, trashNumber, sentNumber) => {
		setSelect(createdNumber);
		setTrashnumberTrack(trashNumber);
		setSentNumberTrack(sentNumber);
	
	};

	const data = [
		{
			title: "Created",
			component: (
				<CreatedInvoice
					child={child}
					notificationFunction={notificationFunction}
				/>
			),
			id: 0,
			notifications: select ? select : 0,
			relativedata: createdInvoice,
		},
		// {
		//   title: "Received",
		//   component: <RecivedDocument receivedChild={receivedChild}/>,
		//   id: 1,
		//   notifications: invoice?.totalReceivedInvoice ? invoice?.totalReceivedInvoice : 0,
		//   relativedata:receivedInvoice
		// },
		{
			title: "Sent",
			component: (
				<SentDocument
					sentChild={sentChild}
					notificationFunction={notificationFunction}
				/>
			),
			id: 1,
			notifications: sentNumberTrack ? sentNumberTrack : 0,
			relativedata: sentInvoice,
		},
		{
			title: "Trash",
			component: (
				<Trash
					trashChild={trashChild}
					notificationFunction={notificationFunction}
				/>
			),
			id: 2,
			notifications: trashNumberTrack ? trashNumberTrack : 0,
			// relativedata: sentInvoice
		},
	];

	// =========          This code is For Notifications in Received Documents           ========

	//   useEffect(() => {
	//     const datasave = async () => {
	//       await fetch(`${process.env.REACT_APP_BASE_URL}api/invoice/received`, {
	//         method: "GET",
	//         headers: {
	//           "content-type": "application/json",
	//           Authorization: localStorage.getItem(`token`).replaceAll('"', ""),
	//         },
	//       })
	//         .then((response) => response.json())
	//         .then((response) => {
	//           setInvoice(response);
	//         const received= response?.data?.map((val, ind)=>{
	//           return {...val, value:val?.invoiceId, label:val?.invoiceId}
	//         })

	// setReceivedInvoice(received)

	//         })
	//         .catch((error) => {
	//           // console.log(error, "joih");
	//         });
	//     };
	//     datasave();
	//   }, []);

	// ================  This code is Notification Api in Trash    =====================

	useEffect(() => {
		const datasave = async () => {
			await fetch(
				`${process.env.REACT_APP_BASE_URL}api/invoice/get-trash-invoice?offset=0&limit=10`,
				{
					method: "GET",
					headers: {
						"content-type": "application/json",
						Authorization: localStorage.getItem(`token`).replaceAll('"', ""),
					},
				},
			)
				.then((response) => response.json())
				.then((response) => {
					localStorage.setItem("trash", JSON.stringify(response?.total));
					setInvoice(response);
					setTrashnumberTrack(response?.total);
					//         const received= response?.data?.map((val, ind)=>{
					//           return {...val, value:val?.invoiceId, label:val?.invoiceId}
					//         })

					// setReceivedInvoice(received)
				})
				.catch((error) => {
					// console.log(error, "joih");
				});
		};
		datasave();
	}, []);

	//    =============== This code is For Notifications in Sent Documents       ==========

	useEffect(() => {
		const datasave = async () => {
			await fetch(
				`${process.env.REACT_APP_BASE_URL}api/invoice/sent-invoices`,
				{
					method: "GET",
					headers: {
						"content-type": "application/json",
						Authorization: localStorage.getItem(`token`).replaceAll('"', ""),
					},
				},
			)
				.then((response) => response.json())
				.then((response) => {
					if (response?.success === true) {
						setGetinvoice(response);

						localStorage.setItem(
							"sent",
							JSON.stringify(response?.totalSentInvoice),
						);
						const sent = response?.data?.map((val, ind) => {
							return {
								...val,
								value: val?.invoiceId,
								label: val?.invoiceDetail?.invoiceId?.value,
							};
						});
						setSentInvoice(sent);
					} else {
						throw response?.message;
					}
				})
				.catch((error) => {
					toast.error(error?.message);
				});
		};
		datasave();
	}, []);

	let name1, val;
	const save = (e) => {
		name1 = e.target.name;
		val = e.target.value;
		setFormvalue({ ...formvalue, [name1]: val });
	};

	// ============     This Code is to post data in Modal Section Select Tag   ===========

	const [seo, setSeo] = useState([]);
	useEffect(() => {
		const seoApi = async () => {
			await fetch(`${process.env.REACT_APP_BASE_URL}api/util/get-meta`, {
				method: "GET",
				headers: {
					"content-type": "application/json",
					Authorization: JSON.parse(localStorage.getItem("token")),
				},
			})
				.then((res) => res.json())
				.then((res) => {
					setSeo(res?.data);
				});
		};
		seoApi();
	}, []);

	return (
		<>
			<div className="flex-wrapper-documents mt-0 pt-0">
				{seo?.map((item) => {
					if (item?.pagename === "Documents") {
						return (
							<Helmet>
								<title>Documents | Easy Invoice</title>
								<meta name="twitter:description" content={item?.description} />
								<meta name="twitter:title" content={item?.title} />
								<meta name="twitter:image" content={item?.image} />
								<script crossorigin="anonymous" async>
									{`${item?.headerScript}`}
								</script>
								<script crossorigin="anonymous" async>
									{`${item?.footerScript}`}
								</script>
							</Helmet>
						);
					}
				})}
				{/* <Helmet>
          <title>Documents | Easy Invoice</title>
          <meta name="description" content={seo[7]?.content} />
        </Helmet> */}
				<Header></Header>
				<section className="document" style={{ marginTop: "6rem" }}>
					<div className="container mt-4">
						<div className="row gx-0">
							<div className="row text-center gx-0 ">
								<div className="col-12">
									<h1>Invoices</h1>

									<p>View Your Created and Sent Invoices</p>
								</div>
							</div>

							<div className="">
								<Tabs data={data} notificationFunction={notificationFunction} />
								{/* <hr></hr> */}
							</div>
						</div>
					</div>
				</section>
				{/* <Footer></Footer> */}
			</div>
		</>
	);
};
export default Documents;