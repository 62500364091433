import React, { useState, useEffect } from "react";
import LogoImgSelect from "../../assets/logo image.svg";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import IconFeather from "../../assets/Icon feather-download.svg";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {
  logoUpload,
  editInvoiceData,
  editInvoiceModalData,
  deleteInvoiceData,
  deleteInvoiceModalData,
  invoiceData,
  handleInvoiceData,
  handleInvoiceCalculation,
  invoiceDataAction,
  logoUploadConfirm,
  addEditInvoiceData,
} from "../../InvoiceRedux/action/action";
import { ImCross } from "react-icons/im";
import {TextareaAutosize} from "@mui/base";
import DatePicker from "react-datepicker";
import moment from "moment";
import delte from "../../assets/delte.svg";
import { BiRefresh, BiPlusCircle } from "react-icons/bi";
import addNewInvoiceButton from "../../assets/add icon.svg";
import Header from "../Header/Header";
import Footer2 from "../Footer/Footer1";
import SendIcon from "../../assets/SendIcon.svg";
import { useNavigate, useLocation } from "react-router-dom";
import ColorBox from "../../InvoiceDownloader/ColorBox";
import TextColorBox from "../../InvoiceDownloader/TextColorBox";
import CurrencyData from "../../InvoiceDownloader/CurrencyData";
import DownloadIcon from "../../assets/download_icon.svg";
import { ToastContainer, toast } from "react-toastify";

const EditInvoiceModal = () => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [file, setFile] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [currencyCode, setCurrencyCode] = useState("USD");
  const [countrySymbol, setCountrySymbol] = useState("$");
  const [datePickerIsOpen, setDatePickerIsOpen] = useState(false);
  const [duedatePickerIsOpen, setDueDatePickerIsOpen] = useState(false);
  const [startDate, setstartDate] = useState();
  const [duestartDate, setDuestartDate] = useState();
  const [state, setState] = useState();
  const [duedateState, setDuedatestate] = useState();
  const selector = useSelector((state) => state.invoice.invoiceList);
  const [data, setData] = useState({ items: "", quantity: 1, rate: 0 });
  const [discountState, setDiscountState] = useState(true);
  const [taxState, setTaxState] = useState(true);
  const [hideDiscountBox, setHideDiscountBox] = useState(true);
  const [hideShipBox, setHideshipBox] = useState(true);
  const [hideTaxBox, setHideTaxBox] = useState(false);
  const [taxStatus, setTaxStatus] = useState(false);
  const userId = JSON.parse(localStorage.getItem(`auth`));
  const [currencyinput, setCurrencyInput] = useState();
  const isAuthenticate = localStorage.getItem(`token`);
  const [invoiceIdOld, setInvoiceIdOld] = useState();
  const [invoiceIdNew, setInvoiceIdNew] = useState("");
  const [newInputField, setNewInputField] = useState({
    key: "new value",
    value: 0,
  });
  const [newTaxId, setNewTaxId] = useState();
  const [newInputList, setNewInputList] = useState([]);
  const [discountCalculate, setDiscountCalculate] = useState(0);
  const [totalNewTax, setTotalNewTax] = useState(0);
  const [editInvoiceInputData, setEditInvoiceInputData] = useState(
    location?.state
  );
  const [editInvoiceId, setEditInvoiceId] = useState();

  // const eInvoice = () => {
  //   dispatch(
  //     invoiceDataAction(
  //       selector,
  //       localStorage.setItem("document_type", JSON.stringify("xmlContent"))
  //     )
  //   );
  // };

  const [invoiceGenerator, setInvoiceGenerator] = useState({
    dragLogo: "",
    title: "Invoice",
    companyName: "",
    billTo: { key: "Bill To", value: "" },
    shipTo: { key: "Ship To", value: "" },
    invoiceId: { key: "Invoice Id", value: "" },
    date: { key: "Date", value: "" },
    paymentTerms: { key: "Payment Mode", value: "" },
    poNumber: { key: "Po Number", value: "" },
    notes: { key: "Notes", value: "" },
    termsAndDescription: { key: "Terms & Description", value: "" },
    dueDate: { key: "Due Date", value: "" },
    subtotal: { key: "Subtotal", value: 0 },
    amountPaid: { key: "Amount Paid", value: "" },
    total: { key: "Total", value: 0 },
    balanceDue: { key: "Balance", value: 0 },
    discount: { key: "Discount", value: "0" },
    tax: { key: "Tax", value: "0" },
    shipping: { key: "Shipping", value: "" },
    invoiceItemHeader: {
      item: "Item",
      quantity: "Quantity",
      rate: "Rate",
      amount: "Amount",
    },
  });

  useEffect(() => {
    if (invoiceGenerator.invoiceId.value === invoiceIdOld) {
      setInvoiceIdNew("");
    } else {
      setInvoiceIdNew(invoiceGenerator.invoiceId.value);
    }
  }, [invoiceGenerator.invoiceId.value]);

  const [selectedColor, setSelectedColor] = useState({
    light: "black",
    dark: "#f4efef",
  });

  const [selectedTextColor, setSelectedTextColor] = useState({
    dark: "black",
    light: "black",
  });

  const resetColor = () => {
    setSelectedColor({
      light: "black",
      dark: "#f4efef",
    });

    setSelectedTextColor({
      dark: "black",
      light: "black",
    });
  };

  const invoiceChangeHandler = (e, k) => {
    const name = e.target.name;
    const value = e.target.value;
    let keyName = k;

    if (keyName === "companyName") {
      // setUpdateLocalStorage(updateLocalStorage + 1);
      setInvoiceGenerator({
        ...invoiceGenerator,
        [keyName]: value,
      });
      return;
    } else if (keyName === "title") {
      // setUpdateLocalStorage(updateLocalStorage + 1);
      setInvoiceGenerator({
        ...invoiceGenerator,
        [keyName]: value,
      });
      return;
    }
    let obj = { ...invoiceGenerator[keyName], [name]: value };
    setInvoiceGenerator({
      ...invoiceGenerator,
      [keyName]: obj,
      // id: id,
    });
    // setUpdateLocalStorage(updateLocalStorage + 1);
    // dispatch(invoiceData(name, value, keyName));
    // dispatch(handleInvoiceData(name, value));
    // setInvoiceGenerator({ ...invoiceGenerator, [keyName]: { [name]: value } });
  };

  const openDatePicker = () => {
    setDatePickerIsOpen(!datePickerIsOpen);
  };

  const formDataGenerator = (data) => {
    let formDataValue = new FormData();
    let keys = Object.keys(data);
    keys.map((key) => {
      formDataValue.append(key, data[key]);
    });

    return formDataValue;
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };
  const handleDrop = (e) => {
    e.preventDefault();
    let selectedFile = e?.dataTransfer?.files[0];
    let fileType = ["image/jpeg", "image/jpg", "image/png", "image/gif"];

    if (fileType.includes(selectedFile?.type)) {
      setFile(URL.createObjectURL(e?.dataTransfer.files[0]));
      dispatch(logoUpload(formDataGenerator({ file: selectedFile })));
      // debugger;
      // setUpdateLocalStorage(updateLocalStorage + 1);
    } else {
      alert(
        "The image you uploaded is not in a file type we accept. Please use a JPEG, PNG, or GIF"
      );
    }
  };

  const handleChange = (date) => {
    setstartDate(date);
    setState(moment(date).format("MMM DD, YYYY"));
    setDatePickerIsOpen(!datePickerIsOpen);
  };

  const handle = (e) => {
    setState(e.target.value);
  };

  const duehandlechange = (date) => {
    setDuestartDate(date);
    setDuedatestate(moment(date).format("MMM DD, YYYY"));
    setDueDatePickerIsOpen(!duedatePickerIsOpen);
  };

  const dueopenDatePicker = () => {
    setDueDatePickerIsOpen(!duedatePickerIsOpen);
  };

  const duehandle = (e) => {
    setDuedatestate(e.target.value);
  };

  const logoChangeHandler = (e) => {
    e.preventDefault();
    let selectedFile = e.target.files[0];

    setFile(URL.createObjectURL(e.target.files[0]));
    dispatch(logoUpload(formDataGenerator({ file: selectedFile })));
    // debugger;
    // setUpdateLocalStorage(updateLocalStorage + 1);
  };

  const removeimage = () => {
    setFile("");
    dispatch(logoUploadConfirm(""));
    // dispatch(logoUpload({ file: null }));
  };

  const dataUpdate = (e, indexs) => {
    const nameEdit = e.target.name;
    const valueEdit = e.target.value;
    // const { name, value } = e.target;
    dispatch(editInvoiceModalData(nameEdit, valueEdit, indexs));
    // setUpdateLocalStorage(updateLocalStorage + 1);
  };

  // deletebutton
  const deleteHandler = (id) => {
    console.log(id, "deleteHandler");
    dispatch(deleteInvoiceModalData(id));
    // setUpdateLocalStorage(updateLocalStorage + 1);
  };
  const hideModal = () => {
    setIsOpen(false);
  };

  // const showModal = () => {
  //   setIsOpen(true);
  //   dispatch(
  //     handleInvoiceCalculation({
  //       ...invoiceGenerator,
  //       taxValue,
  //       discountValue,
  //       countrySymbol,
  //       user,
  //       discountState,
  //       taxState,
  //       taxStatus,

  //       hideDiscountBox,
  //       hideShipBox,
  //       selectedColor,
  //       selectedTextColor,

  //     })
  //   );
  //   // }
  // };
  let user;
  if (isAuthenticate) {
    user = userId;
  }

  const amount = Number(data?.quantity || 0) * Number(data?.rate || 0);
  invoiceGenerator.subtotal.value = amount;

  selector?.invoiceItems?.map((val) => {
    invoiceGenerator.subtotal.value =
      invoiceGenerator?.subtotal?.value + val?.amount;
    return invoiceGenerator?.subtotal.value;
  });

  // let disco = discountState
  //   ? invoiceGenerator?.subtotal?.value -
  //     Number(
  //       (invoiceGenerator?.discount?.value / 100) *
  //         invoiceGenerator?.subtotal?.value
  //     )
  //   : invoiceGenerator?.subtotal?.value - invoiceGenerator?.discount?.value;

  let tax = taxState
    ? Number(
        discountCalculate +
          totalNewTax +
          (invoiceGenerator?.tax?.value / 100) * discountCalculate
      ).toFixed(2)
    : Number(discountCalculate) +
      Number(totalNewTax) +
      Number(invoiceGenerator?.tax?.value);

  invoiceGenerator.total.value = (
    Number(tax) + Number(invoiceGenerator?.shipping?.value)
  ).toFixed(2);

  invoiceGenerator.balanceDue.value = Number(
    invoiceGenerator?.total?.value - invoiceGenerator?.amountPaid?.value
  ).toFixed(2);
  // },[])

  let discountValue = discountState
    ? Number(
        (invoiceGenerator?.discount?.value / 100) *
          invoiceGenerator?.subtotal?.value
      ).toFixed(2)
    : Number(invoiceGenerator?.discount?.value).toFixed(2);

  let taxValue = taxState
    ? Number((invoiceGenerator?.tax?.value / 100) * discountCalculate).toFixed(
        2
      )
    : Number(invoiceGenerator?.tax?.value).toFixed(2);

  const newInputChangeHandler = (e, id) => {
    let value = e.target.value;
    let name = e.target.name;
    setNewTaxId(id);
    let editInput = newInputList.map((item) =>
      item.id === id ? { ...item, [name]: value } : item
    );
    newTaxCalculatefunction(editInput, id);
  };

  function newTaxCalculatefunction(editInput, id, allchange = false) {
    if (allchange) {
      editInput?.map((item, index) => {
        let newAmountCalculate = Number(
          (editInput[index]?.value / 100) * discountCalculate
        ).toFixed(2);
        if (NaN !== newAmountCalculate && editInput[index]?.value) {
          editInput[index].newAmount = Number(newAmountCalculate);
        }
        setNewInputList(editInput);
        console.log(editInput[index], editInput, newAmountCalculate, "56666");
      });

      finalTotalTax(editInput);
    } else {
      let findIndexValue = editInput.findIndex((item) => item?.id === id);
      let newAmountCalculate = Number(
        (editInput[findIndexValue]?.value / 100) * discountCalculate
      ).toFixed(2);
      if (NaN !== newAmountCalculate && editInput[findIndexValue]?.value) {
        editInput[findIndexValue].newAmount = Number(newAmountCalculate);
      }
      if (!editInput[findIndexValue]?.value) {
        editInput[findIndexValue].newAmount = 0;
      }
      // console.log(
      //   editInput[findIndexValue],
      //   editInput,
      //   newAmountCalculate,
      //   "56666"
      // );
      finalTotalTax(editInput);
      setNewInputList(editInput);
    }
  }

  useEffect(() => {
    // let totalNewTaxs;
    // let newValueAdd = newInputList?.map((item) => {
    //   totalNewTaxs = totalNewTax + item?.newAmount;
    //   return totalNewTaxs;
    // });

    // setTotalNewTax(totalNewTaxs);

    let discountCalculates = discountState
      ? invoiceGenerator?.subtotal?.value -
        Number(
          (invoiceGenerator?.discount?.value / 100) *
            invoiceGenerator?.subtotal?.value
        )
      : invoiceGenerator?.subtotal?.value - invoiceGenerator?.discount?.value;
    setDiscountCalculate(discountCalculates);
  }, [invoiceGenerator?.subtotal?.value, invoiceGenerator?.discount?.value]);

  useEffect(() => {
    let allchange = true;
    // alert('hello')
    newTaxCalculatefunction(newInputList, newTaxId, allchange);
  }, [discountCalculate]);

  useEffect(() => {
    if (invoiceGenerator?.discount?.value == 0) {
      // alert('change')
      let allchange = true;
      newTaxCalculatefunction(newInputList, newTaxId, allchange);
    }
  }, [invoiceGenerator?.discount?.value]);

  // add items
  const add = () => {
    // if (Object?.keys(data)?.length === 0) {
    //   return setData({ items: "", quantity: 1, rate: 0 });
    // }
    // setData({ items: "", quantity: 1, rate: 0 });
    dispatch(
      addEditInvoiceData({
        ...data,
        // ...invoiceGenerator,
        id: new Date().getTime().toString(),
        amount: amount,
      })
    );
    // setUpdateLocalStorage(updateLocalStorage + 1);
  };

  const DiscountBox = () => {
    setHideDiscountBox(!hideDiscountBox);
    setInvoiceGenerator({
      ...invoiceGenerator,
      discount: { ...invoiceGenerator.discount, value: 0 },
    });
  };

  const ShippedBox = () => {
    setHideshipBox(!hideShipBox);
    setInvoiceGenerator({
      ...invoiceGenerator,
      shipping: { ...invoiceGenerator.shipping, value: 0 },
    });
  };

  const TaxBox = () => {
    setHideTaxBox(!hideTaxBox);
    setTaxStatus(true);
    setInvoiceGenerator({
      ...invoiceGenerator,
      tax: { ...invoiceGenerator.tax, value: 0 },
    });
  };

  const discountAmountHandler = () => {
    setDiscountState(!discountState);
    // setUpdateLocalStorage(updateLocalStorage + 1);
    // setDiscountGiven(0);
  };

  const taxAmountHandler = () => {
    setTaxState(!taxState);
    // setUpdateLocalStorage(updateLocalStorage + 1);
    // setPercentage(0);
  };

  const discountHandler = () => {
    setHideDiscountBox(!hideDiscountBox);
  };

  const TaxHandler = () => {
    setHideTaxBox(!hideTaxBox);
    setTaxStatus(false);
  };

  const shippingHandler = () => {
    setHideshipBox(!hideShipBox);
  };

  const saveInvoiceHandler = async (e) => {
    e.preventDefault();
    //  let a  = invoiceGenerator?.date?.value
    //  a= state
    setLoading(true);
    const color = { light: selectedColor?.light, dark: selectedColor?.dark };
    const textColor = {
      light: selectedTextColor?.light,
      dark: selectedTextColor?.dark,
    };
    const invoiceItems = selector?.invoiceItems;
    // console.log(invoiceGenerator, "useEffect");

    let discountAmount = {
      key: invoiceGenerator.discount.key,
      value: discountValue,
    };

    let discountPercent = {
      key: invoiceGenerator.discount.key,
      value: invoiceGenerator.discount.value,
    };

    let taxAmount = {
      key: invoiceGenerator.tax.key,
      value: taxValue,
    };

    let taxPercent = {
      key: invoiceGenerator.tax.key,
      value: invoiceGenerator.tax.value,
    };
    await fetch(
      `${process.env.REACT_APP_BASE_URL}api/invoice/update-invoice?_id=${editInvoiceId}`,
      {
        method: "PUT",
        headers: {
          "content-type": "application/json",
          Authorization: localStorage.getItem(`token`).replaceAll('"', ""),
        },

        body: JSON.stringify({
          ...invoiceGenerator,
          discountState,
          taxState,
          symbol: countrySymbol,
          invoiceItems,
          color,
          textColor,
          invoiceIdOld: invoiceIdOld,
          logoLink: selector?.logoLink,
          invoiceIdNew: invoiceIdNew,
          discountAmount,
          discountPercent,
          taxAmount,
          taxPercent,
          taxInput: newInputList,
        }),
      }
    )
      .then((response) => response.json())
      .then((response) => {
        // if (response?.status === true){
        //   navigate("/invoice")
        // }
        // loader();
        // setGetresponse(response);
        if (!response?.status) {
          throw Error(response.message);
        } else {
          setLoading(false);
          toast.success(response?.message);
          navigate("/invoice");
        }
      })
      .catch((error) => {
        toast.error(error?.message);
        setLoading(false);
      });
  };

  const handleSelectChange = async (e) => {
    const selectedCountry = await e.target.value;
    setCountrySymbol(selectedCountry);
    changeCurrencySymbol(selectedCountry)
  };

  function changeCurrencySymbol(selectedCountry) {
    let changeSymbol = newInputList((item) => ({
      ...item,
      symbol: selectedCountry,
    }));
    setNewInputList(changeSymbol)
  }

  // const SendInvoice = () => {
  //   navigate("/invioce");
  // };

  const colorChangeHandler = (color, newbackgroundColor) => {
    // let selectColor = e.target.value
    if (color === "black") {
      setSelectedColor({
        ...selectedColor,
        light: color,
        dark: color,
      });
    } else {
      setSelectedColor({
        ...selectedColor,
        light: color,
        dark: color,
      });
    }
  };

  const textColorChangeHandler = (color, newbackgroundColor) => {
    // let selectColor = e.target.value
    if (color === "black") {
      setSelectedTextColor({
        ...selectedTextColor,
        light: color,
        dark: "black",
      });
    } else {
      setSelectedTextColor({
        ...selectedTextColor,
        light: color,
        dark: color,
      });
    }
  };

  useEffect(() => {
    setInvoiceGenerator({
      ...invoiceGenerator,
      date: { ...invoiceGenerator.date, value: state },
    });
  }, [state]);

  useEffect(() => {
    setInvoiceGenerator({
      ...invoiceGenerator,
      dueDate: { ...invoiceGenerator.dueDate, value: duedateState },
    });
  }, [duedateState]);

  useEffect(() => {
    console.log(editInvoiceInputData, "useEffect");
    const title = editInvoiceInputData?.title;
    const organisationName = editInvoiceInputData?.organisationName;
    const billToKey = editInvoiceInputData?.billTo?.key;
    const billToValue = editInvoiceInputData?.billTo?.value;
    const shipToKey = editInvoiceInputData?.shipTo?.key;
    const shipToValue = editInvoiceInputData?.shipTo?.value;
    const invoiceIdKey = editInvoiceInputData?.invoiceId?.key;
    const invoiceIdValue = editInvoiceInputData?.invoiceId?.value;
    const dateKey = editInvoiceInputData?.date?.key;
    const dateValue = editInvoiceInputData?.date?.value;
    const duedateKey = editInvoiceInputData?.dueDate?.key;
    const duedateValue = editInvoiceInputData?.dueDate?.value;
    const paymentModeKey = editInvoiceInputData?.paymentTerms?.key;
    const paymentModeValue = editInvoiceInputData?.paymentTerms?.value;
    const poNumberKey = editInvoiceInputData?.poNumber?.key;
    const poNumberValue = editInvoiceInputData?.poNumber?.value;
    const noteKey = editInvoiceInputData?.notes?.key;
    const noteValue = editInvoiceInputData?.notes?.value;
    const termsAndDescriptionKey =
      editInvoiceInputData?.termsAndDescription?.key;
    const termsAndDescriptionValue =
      editInvoiceInputData?.termsAndDescription?.value;
    const discountKey = editInvoiceInputData?.discountAmount?.key;
    const discountValue = editInvoiceInputData?.discountAmount?.value;
    const taxKey = editInvoiceInputData?.taxAmount?.key;
    const shippingKey = editInvoiceInputData?.shipping?.key;
    const shippingValue = editInvoiceInputData?.shipping?.value;
    const amountKey = editInvoiceInputData?.amountPaid?.key;
    const amountValue = editInvoiceInputData?.amountPaid?.value;
    const invoiceItems = editInvoiceInputData?.invoiceItem;
    const newDiscountState = editInvoiceInputData?.discountState;
    const newTaxState = editInvoiceInputData?.taxState;
    const symbol = editInvoiceInputData?.symbol;
    const balanceDue = editInvoiceInputData?.balanceDue?.key;
    const subtotal = editInvoiceInputData?.subtotal?.key;
    const discountPercent = editInvoiceInputData?.discountPercent?.value;
    let taxPercent = editInvoiceInputData?.taxPercent?.value;
    let logoLink = editInvoiceInputData?.logoLink;
    let taxInput = editInvoiceInputData?.taxInput;
    // let discountPercent;

    // if (newDiscountState === true) {
    //    discountPercent = editInvoiceInputData?.discountPercent?.value;
    // } else {
    //   discountPercent = editInvoiceInputData?.discountAmount?.value;
    // }

    // if (newTaxState === true) {
    //   taxPercent = editInvoiceInputData?.taxPercent?.value;
    // } else {
    //   taxPercent = editInvoiceInputData?.taxAmount?.value;
    // }

    // setInvoiceGenerator(
    //   // editInvoiceInputData?.invoiceGenerator ? editInvoiceInputData?.invoiceGenerator : editInvoiceInputData
    // );
    // setDiscountAmount({ key: discountKey, value: discountValue });
    // setDiscountPercent({ key: discountKey, value: discountPercents });
    setEditInvoiceId(editInvoiceInputData?._id);
    setInvoiceGenerator({
      ...invoiceGenerator,
      billTo: { key: billToKey, value: billToValue },
      shipTo: { key: shipToKey, value: shipToValue },
      invoiceId: { key: invoiceIdKey, value: invoiceIdValue },
      companyName: organisationName,
      title: title,
      paymentTerms: { key: paymentModeKey, value: paymentModeValue },
      poNumber: {
        key: poNumberKey,
        value: poNumberValue,
      },
      notes: {
        key: noteKey,
        value: noteValue,
      },
      termsAndDescription: {
        key: termsAndDescriptionKey,
        value: termsAndDescriptionValue,
      },

      discount: {
        key: discountKey,
        value: discountPercent,
      },

      // discountAmount: {
      //   key: discountKey,
      //   value: discountPercent,
      // },
      tax: {
        key: taxKey,
        value: taxPercent,
      },
      shipping: {
        key: shippingKey,
        value: shippingValue,
      },
      amountPaid: {
        key: amountKey,
        value: amountValue,
      },
      date: {
        key: dateKey,
        value: dateValue,
      },
      dueDate: {
        key: duedateKey,
        value: duedateValue,
      },
      balanceDue: {
        key: balanceDue,
      },
      subtotal: {
        key: subtotal,
      },
    });
    setSelectedColor({
      light: editInvoiceInputData?.color?.light,
      dark: editInvoiceInputData?.color?.dark,
    });
    setSelectedTextColor({
      light: editInvoiceInputData?.textColor?.light,
      dark: editInvoiceInputData?.textColor?.dark,
    });
    setCountrySymbol(symbol);
    setTaxState(editInvoiceInputData?.taxState);
    setDiscountState(editInvoiceInputData?.discountState);
    setFile(editInvoiceInputData?.logoLink);
    setState(dateValue);
    setInvoiceIdOld(editInvoiceInputData?.invoiceId?.value);
    setDuedatestate(duedateValue);
    setNewInputList(taxInput);
    dispatch(handleInvoiceData({ invoiceItems }));
    dispatch(logoUploadConfirm({ path: logoLink }));
  }, []);
  // console.log(file, 'file===');
  // useEffect(() => {
  //   // const duedateValue = editInvoiceInputData?.dueDate?.value;
  //   setDuedatestate(duedateState);
  // }, [duedateState]);

  // useEffect(() => {
  //   // const dateValue = editInvoiceInputData?.date?.value;
  //   setState(state);
  // }, [state]);

  // const handlePrint = () => {
  //   // setDraftstate("");
  //   // setUpdateLocalStorage(updateLocalStorage + 1);
  //   setIsOpen(false);
  //   let totalInvoices = JSON.parse(localStorage.getItem("idCollection")) || 0;
  //   localStorage.setItem(
  //     "totalInvoices",
  //     JSON.stringify(totalInvoices?.length ? totalInvoices?.length : 0)
  //   );
  //   // setUpdateLocalStorage(1)
  //   dispatch(
  //     invoiceDataAction(
  //       selector,
  //       localStorage.setItem("document_type", JSON.stringify("pdfContent"))
  //     )
  //   );
  //   // navigate("/history")
  // };

  const addNewInputField = () => {
    let id = newInputList?.length + 1;
    setNewInputList([
      { ...newInputField, id, newAmount: 0, symbol: countrySymbol },
      ...newInputList,
    ]);
    setNewInputField({ key: "new value", value: 0 });
  };

  const deleteInputField = (id) => {
    let removeInputField = newInputList.filter((item) => item?.id !== id);
    setNewInputList(removeInputField);
    finalTotalTax(removeInputField);
  };
  function finalTotalTax(removeInputField) {
    let totalNewTaxs = 0;
    let newValueAdd = removeInputField?.map((item) => {
      totalNewTaxs += item?.newAmount;
      return totalNewTaxs;
    });
    setTotalNewTax(Number(totalNewTaxs));
  }

  return (
    // <div>
    <>
      <Header></Header>
      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <div className="backgrounds">
        <div className="container" style={{ marginTop: "3.5rem" }}>
          <div className="row  ">
            <div className="col-md-9 bg-white mb-3">
              <div className="row pt-3">
                <div className="col-md-6 d-flex flex-column">
                  <div className="col-md-6 mb-3 d-flex align-items-center w-100">
                    <div
                      className="header_container"
                      onDrop={handleDrop}
                      onDragOver={handleDragOver}
                    >
                      <img src={LogoImgSelect} alt="logoImgSelect" />

                      <label>
                        <input
                          accept="image/*"
                          type="file"
                          name=" dragLogo"
                          // value={invoiceGenerator?.dragLogo}
                          onChange={(e) => logoChangeHandler(e)}
                        />
                        {file ? (
                          <div style={{ height: "55px", width: "auto" }}>
                            <img
                              src={file}
                              // crossorigin="anonymous"
                              alt="logo"
                              style={{
                                height: "100%",
                                maxWidth: "100%",
                                objectFit: "contain",
                              }}
                            />
                          </div>
                        ) : (
                          <div>
                            <p>
                              Drag your logo here or<u> select a file</u>
                            </p>
                          </div>
                        )}
                      </label>
                      {file ? (
                        <button className="imagecrossbtn" onClick={removeimage}>
                          <ImCross />
                        </button>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-md-6 d-flex flex-column w-100 mb-4">
                    <TextareaAutosize
                      aria-label="empty textarea"
                      placeholder="Who is this Invoice from *? (required)"
                      style={{
                        resize: "none",
                        // fontWeight: `${font.fontWeight} : 900px`,
                        fontSize: "12px",
                      }}
                      className="containerOne_input"
                      value={invoiceGenerator?.companyName}
                      onChange={(e) => invoiceChangeHandler(e, "companyName")}
                      // onKeyPress={handleKeyPress}
                    />
                  </div>
                  <div className="col-md-6 d-flex flex-column w-100 mb-3">
                    <div className="containerOne_info">
                      <input
                        id="bill"
                        type="text"
                        name="key"
                        value={invoiceGenerator?.billTo?.key}
                        onChange={(e) => invoiceChangeHandler(e, "billTo")}
                        style={{
                          color: `${selectedTextColor?.light}`,
                          fontSize: "14px",
                        }}
                      />
                    </div>
                    <TextareaAutosize
                      aria-label="empty textarea"
                      id="bill"
                      type="text"
                      name="value"
                      style={{
                        resize: "none",
                        // fontWeight: `${font.fontWeight} : 900px`,
                        fontSize: "12px",
                      }}
                      placeholder="Who is this Invoice to *? (required)"
                      className="containerTwo_input"
                      value={invoiceGenerator?.billTo?.value}
                      onChange={(e) => invoiceChangeHandler(e, "billTo")}
                    />
                  </div>

                  {/* ..... */}
                  <div className="col-md-6 d-flex flex-column w-100 mb-3">
                    <div className="containerOne_info">
                      <input
                        type="text"
                        name="key"
                        value={invoiceGenerator?.shipTo?.key}
                        onChange={(e) => invoiceChangeHandler(e, "shipTo")}
                        style={{
                          color: `${selectedTextColor?.light}`,
                          fontSize: "14px",
                        }}
                      />
                    </div>
                    <TextareaAutosize
                      aria-label="empty textarea"
                      id="bill"
                      type="text"
                      name="value"
                      style={{
                        resize: "none",
                        // fontWeight: `${font.fontWeight} : 900px`,
                        fontSize: "12px",
                      }}
                      className="containerTwo_input"
                      placeholder="Optional"
                      value={invoiceGenerator?.shipTo?.value}
                      onChange={(e) => invoiceChangeHandler(e, "shipTo")}
                    />
                  </div>

                  {/* ...... */}
                </div>
                <div className=" col-md-6 d-flex flex-column ">
                  <div className="invoice_title">
                    <input
                      // style={{ color: `${selectedColor?.light}` }}
                      style={{ color: `${selectedTextColor?.light}` }}
                      type="text"
                      name="title"
                      value={invoiceGenerator?.title}
                      onChange={(e) => invoiceChangeHandler(e, "title")}
                    />
                    {/* </h3> */}
                  </div>
                  <div className="containerTwo">
                    <div className="containerTwo_inputOne invoice-id-key">
                      <input
                        type="text "
                        name="key"
                        value={invoiceGenerator?.invoiceId?.key}
                        onChange={(e) => invoiceChangeHandler(e, "invoiceId")}
                        min="0"
                        style={{ color: `${selectedTextColor?.light}` }}
                      />
                    </div>
                    <div className="containerTwo_inputTwo">
                      <span
                        style={{
                          backgroundColor: `${selectedColor?.dark}`,
                          color: `${selectedTextColor?.light}`,
                          fontSize: "12px",
                        }}
                      >
                        #
                      </span>
                      <input
                        style={{ fontSize: "12px" }}
                        // disabled
                        type="number"
                        name="value"
                        value={invoiceGenerator?.invoiceId?.value}
                        onChange={(e) => invoiceChangeHandler(e, "invoiceId")}
                        min="0"
                      />
                    </div>
                  </div>

                  <div className="containerTwo">
                    <div className="containerTwo_inputOne invoice-date-key">
                      <input
                        type="text"
                        name="key"
                        value={invoiceGenerator?.date?.key}
                        onChange={(e) => invoiceChangeHandler(e, "date")}
                        style={{ color: `${selectedTextColor?.light}` }}
                      />
                    </div>

                    <div className="containerTwo_inputTwodate d-flex align-items-center justify-content-center">
                      <input
                        className="date_input_first calender-date"
                        type="text"
                        onClick={openDatePicker}
                        // name="value"
                        // onChange={(e) => invoiceChangeHandler(e, "date")}
                        // value={invoiceGenerator.date.value}
                        onChange={handle}
                        value={state}
                      ></input>

                      <DatePicker
                        className="invisibleInput"
                        selected={startDate}
                        onChange={handleChange}
                        onClickOutside={openDatePicker}
                        open={datePickerIsOpen}
                      />
                    </div>
                  </div>
                  <div className="containerTwo">
                    <div className="containerTwo_inputOne due-date-key">
                      <input
                        type="text"
                        name="key"
                        value={invoiceGenerator?.dueDate?.key}
                        onChange={(e) => invoiceChangeHandler(e, "dueDate")}
                        style={{ color: `${selectedTextColor?.light}` }}
                      />
                    </div>
                    <div className="containerTwo_inputTwodate d-flex align-items-center justify-content-center">
                      <input
                        className="date_input_first due-date"
                        type="text"
                        onClick={dueopenDatePicker}
                        onChange={duehandle}
                        value={duedateState}
                      ></input>

                      <DatePicker
                        className="invisibleInput"
                        selected={duestartDate}
                        onChange={duehandlechange}
                        onClickOutside={dueopenDatePicker}
                        open={duedatePickerIsOpen}
                        dateFormat="yyyy/MM/dd"
                      />
                    </div>
                  </div>
                  <div className="containerTwo">
                    <div className="containerTwo_inputOne payment-mode-key">
                      <input
                        type="text"
                        name="key"
                        value={invoiceGenerator?.paymentTerms?.key}
                        style={{ color: `${selectedTextColor?.light}` }}
                        onChange={(e) =>
                          invoiceChangeHandler(e, "paymentTerms")
                        }
                      />
                    </div>
                    <div className="containerTwo_inputTwo payment-mode">
                      <input
                        type="text"
                        name="value"
                        value={invoiceGenerator?.paymentTerms?.value}
                        onChange={(e) =>
                          invoiceChangeHandler(e, "paymentTerms")
                        }
                      />
                    </div>
                  </div>

                  <div className="containerTwo">
                    <div className="containerTwo_inputOne po-number-key">
                      <input
                        type="text"
                        name="key"
                        value={invoiceGenerator?.poNumber?.key}
                        style={{ color: `${selectedTextColor?.light}` }}
                        onChange={(e) => invoiceChangeHandler(e, "poNumber")}
                      />
                    </div>
                    <div className="containerTwo_inputTwo po-number">
                      <input
                        type="text"
                        name="value"
                        value={invoiceGenerator?.poNumber?.value}
                        onChange={(e) => invoiceChangeHandler(e, "poNumber")}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* TABLE START */}
              <div className="table-container mb-2  ">
                <div className="wrapper-container p-2 ">
                  <div className="row">
                    <div className="col-12  ">
                      {/* <div className={classes.inputDecoration}> */}
                      <div
                        className="row"
                        style={{
                          backgroundColor: `${selectedColor?.dark}`,
                          color: `${selectedTextColor?.light}`,
                          borderRadius: "4px",
                        }}
                      >
                        <div className=" col-2  col-md-5 col-lg-7 p-2">
                          <input
                            type="text"
                            name="item"
                            value={invoiceGenerator?.invoiceItemHeader?.item}
                            // className="bg-secondary "

                            onChange={(e) =>
                              invoiceChangeHandler(e, "invoiceItemHeader")
                            }
                            style={{
                              border: "none",
                              outline: "none",
                              width: "100%",
                              borderRadius: "4px",
                              // backgroundColor: "#F2F2F2",
                              backgroundColor: `${selectedColor?.dark}`,
                              color: `${selectedTextColor?.light}`,
                              fontSize: "14px",
                            }}
                          />
                        </div>
                        <div className="col-10  col-md-7 col-lg-5   p-2 ">
                          <div className="row  d-flex  justify-content-evenly ">
                            <div className="col-4 col-md-4  ">
                              <input
                                type="text"
                                name="quantity"
                                value={
                                  invoiceGenerator?.invoiceItemHeader?.quantity
                                }
                                onChange={(e) =>
                                  invoiceChangeHandler(e, "invoiceItemHeader")
                                }
                                // className="  bg-secondary"
                                style={{
                                  border: "none",
                                  outline: "none",
                                  width: "100%",
                                  borderRadius: "4px",
                                  // backgroundColor: "#F2F2F2",
                                  backgroundColor: `${selectedColor?.dark}`,
                                  color: `${selectedTextColor?.light}`,
                                  fontSize: "14px",
                                }}
                              />
                            </div>
                            <div className="col-4 col-md-3 ">
                              <input
                                type="text"
                                // className=" bg-secondary "
                                name="rate"
                                value={
                                  invoiceGenerator?.invoiceItemHeader?.rate
                                }
                                onChange={(e) =>
                                  invoiceChangeHandler(e, "invoiceItemHeader")
                                }
                                style={{
                                  border: "none",
                                  outline: "none",
                                  // backgroundColor: "#F2F2F2",
                                  width: "100%",
                                  backgroundColor: `${selectedColor?.dark}`,
                                  color: `${selectedTextColor?.light}`,
                                  fontSize: "14px",
                                }}
                              />
                            </div>

                            <div className="col-4   col-md-5 ">
                              <input
                                type="text"
                                // className="  bg-secondary"
                                name="amount"
                                value={
                                  invoiceGenerator?.invoiceItemHeader?.amount
                                }
                                onChange={(e) =>
                                  invoiceChangeHandler(e, "invoiceItemHeader")
                                }
                                style={{
                                  border: "none",
                                  outline: "none",
                                  width: "100%",
                                  marginRight: "10px",
                                  borderRadius: "4px",
                                  // backgroundColor: "#F2F2F2",
                                  backgroundColor: `${selectedColor?.dark}`,
                                  color: `${selectedTextColor?.light}`,
                                  fontSize: "14px",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* </div> */}
                    </div>

                    {selector?.invoiceItems?.map((data, index) => (
                      <div className="col-12 px-1">
                        <div className="row">
                          <div className="col-md-12  col-lg-7 col-sm-12 pt-0 px-2 order-md-1 order-sm-last pb-0 mt-1 description-textbox">
                            <TextareaAutosize
                              aria-label="empty textarea"
                              id="bill"
                              type="text"
                              minRows={1}
                              style={{
                                padding: "4px",
                                resize: "none",
                                width: "100%",
                                borderRadius: "4px",
                                outline: "none",
                                border: "1px solid #dddddd",
                                fontSize: "12px",
                                height: "36px",
                                paddingTop: "9px",
                              }}
                              className="description-textbox"
                              placeholder="Description of service or product..."
                              key={data?.id}
                              value={data?.item}
                              name="item"
                              onChange={(e) => dataUpdate(e, data.id)}
                            />
                            {/* ..... */}
                          </div>
                          <div className="col-md-12  col-lg-5  col-sm-12 px-2   order-md-2 order-sm-last py-0 mt-1">
                            <div className="row ps-1 pt-0">
                              <div className="col-3  col-lg-3  col-md-3 p-0 px-0 ms-2 ">
                                <input
                                  type="number"
                                  placeholder="Quantity"
                                  className="py-2  quantity-textbox "
                                  style={{
                                    border: "1px solid #dddddd",
                                    textAlign: "center",
                                    // width: "130%",
                                    width: "100%",
                                    borderRadius: "4px",
                                    fontSize: "12px",
                                  }}
                                  min="1"
                                  key={data.id}
                                  value={data?.quantity}
                                  name="quantity"
                                  onChange={(e) => dataUpdate(e, data.id)}
                                />
                              </div>

                              <div
                                className="col-3  col-md-3 col-lg-3 p-0 mx-2"
                                style={{ position: "relative" }}
                              >
                                <div
                                  className="rate-wrapper-container "
                                  style={{
                                    border: "1px solid #dddddd",
                                    // width: "130%",
                                    width: "100%",
                                    borderRadius: "4px",
                                    paddingTop: "4px",
                                  }}
                                >
                                  <div
                                    className="label-rate  ms-2"
                                    style={{
                                      position: "absolute",
                                      top: "10px",
                                      // left: "14px",
                                      fontSize: "10px",
                                      // marginRight:"10px"
                                      fontSize: "12px",
                                    }}
                                  >
                                    {" "}
                                    {`${countrySymbol}`}
                                  </div>
                                  <div
                                    className="rate-container"
                                    style={{
                                      width: "80%",
                                      marginLeft: "25px",
                                      paddingRight: "4px",
                                    }}
                                  >
                                    <input
                                      name="rate"
                                      type="number"
                                      placeholder="Rate"
                                      className="ms-1 px-0 pb-2 pt-1 rate-textbox"
                                      style={{
                                        // border: "1px solid #dddddd",
                                        width: "76%",
                                        borderRadius: "4px",
                                        border: "none",
                                        outline: "none",
                                        fontSize: "12px",
                                        // textAlign: "left",
                                        textAlign: "center",
                                        display: "flex",
                                        justifyContent: "flex-end",
                                      }}
                                      min="0"
                                      key={data?.id}
                                      value={data?.rate}
                                      onChange={(e) => dataUpdate(e, data.id)}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div
                                className="amount-container  col-3  col-md-3 col-lg-3  "
                                style={{
                                  height: "34px",
                                  maxWidth: "100%",
                                  border: "1px solid #dddddd",
                                  borderRadius: "4px",
                                  fontSize: "10px",
                                  height: "36px",
                                }}
                              >
                                <div
                                  style={{ height: "100%" }}
                                  className="  row gap-1 d-flex  align-items-center"
                                >
                                  <div
                                    className="col-2  me-0"
                                    style={{ fontSize: "12px" }}
                                  >{`${countrySymbol} `}</div>
                                  <div
                                    className="col-6 me-2"
                                    style={{ fontSize: "12px" }}
                                  >
                                    {data?.amount}
                                  </div>
                                </div>
                              </div>

                              {selector?.invoiceItems?.length > 1 ? ( // 0 changes to 1
                                <div className="col-1  px-4">
                                  <span>
                                    <img
                                      src={delte}
                                      alt="delete"
                                      onClick={() => deleteHandler(data.id)}
                                      style={{ cursor: "pointer" }}
                                    />
                                  </span>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}

                    {/* {Object?.keys(data)?.length === 0 ? (
                <div className="col-12 px-1">
                  <div className="row  ">
                    <div className="col-md-12  col-lg-7 col-sm-12 p-2 order-md-1 order-sm-last">
                     
                      <TextareaAutosize
                        aria-label="empty textarea"
                        id="bill"
                        type="text"
                        minRows={1}
                        style={{
                          width: "100%",
                          borderRadius: "4px",
                          outline: "none",
                          border: "1px solid #dddddd",
                          padding: "4px",
                          resize: "none",
                        }}
                        placeholder="Description of service or product..."
                        key={data.id}
                        value={data.items}
                        name="items"
                        onChange={datahandler}
                      />
                     
                    </div>
                    <div className="col-md-12  col-lg-5  col-sm-12 px-2  py-2 order-md-2 order-sm-last">
                      <div className="row ">
                        <div className="col-3  col-lg-3  col-md-3 p-0 px-0 ms-2 ">
                          <input
                            type="number"
                            placeholder="Quantity"
                            className=" py-2 "
                            style={{
                              border: "1px solid #dddddd",
                              textAlign: "center",

                              width: "100%",
                              borderRadius: "4px",
                              fontSize: "10px",
                            }}
                            name="quantity"
                            value={data?.quantity}
                            onChange={datahandler}
                            min="1"
                          />
                        </div>

                        <div
                          className="col-3  col-md-3 col-lg-3 p-0 mx-2"
                          style={{ position: "relative" }}
                        >
                          <div
                            className="rate-wrapper-container "
                            style={{
                              border: "1px solid #dddddd",
                             
                              width: "100%",
                              borderRadius: "4px",
                            }}
                          >
                            <div
                              className="label-rate  ms-2"
                              style={{
                                position: "absolute",
                                top: "10px",
                              
                                fontSize: "10px",
                               
                              }}
                            >
                              {" "}
                              {`${countrySymbol}`}
                            </div>
                            <div
                              className="rate-container"
                              style={{
                                width: "80%",
                                marginLeft: "25px",
                              }}
                            >
                              <input
                                name="rate"
                                type="number"
                                placeholder="Rate"
                                className="ms-1 p-2"
                                style={{
                                  border: "1px solid #dddddd",
                                  width: "80%",
                                  borderRadius: "4px",
                                  border: "none",
                                  outline: "none",
                                  fontSize: "10px",
                                  textAlign: "left",
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                                value={data?.rate}
                                onChange={datahandler}
                                min="0"
                              />
                            </div>
                          </div>
                        </div>

                        <div
                          className="amount-container   col-3  col-md-3 col-lg-3  "
                          style={{
                            height: "34px",
                            maxWidth: "100%",
                            border: "1px solid #dddddd",
                            borderRadius: "4px",
                            fontSize: "10px",
                          }}
                        >
                          <div
                            style={{ height: "100%" }}
                            className="  row gap-1 d-flex  align-items-center"
                          >
                            <div className="col-2  me-0">{`${countrySymbol} `}</div>
                            <div className="col-6 me-2">{`${amount} `}</div>
                          </div>
                        </div>

                        {selector?.invoiceItems?.length > 1 ? ( // 0 changes to 1
                          <div className="col-1  px-4">
                            <span>
                              <img
                                src={delte}
                                alt="delete"
                                onClick={() => setData({})}
                                style={{ cursor: "pointer" }}
                              />
                            </span>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              ) : null} */}
                  </div>
                </div>
              </div>

              {/* TABLE END */}
              <div className="row ">
                <div className="btnOne">
                  {
                    <button onClick={() => add()}>
                      <img
                        src={addNewInvoiceButton}
                        alt="addNewInvoiceButton"
                      />
                      <span>Line Item</span>
                    </button>
                  }
                </div>
              </div>
              <div className="row pt-2 ">
                <div className="col-md-6 d-flex flex-column justify-content-end align-item-center">
                  <div className="col-md-12 d-flex flex-column justify-content-end align-item-center  mb-3">
                    <div className="containerOne_info">
                      <input
                        id="notes"
                        type="text"
                        name="key"
                        value={invoiceGenerator?.notes?.key}
                        style={{
                          color: `${selectedTextColor?.light}`,
                          fontSize: "14px",
                        }}
                        onChange={(e) => invoiceChangeHandler(e, "notes")}
                      />
                    </div>
                    <TextareaAutosize
                      aria-label="empty textarea"
                      id="notes"
                      type="text"
                      name="value"
                      value={invoiceGenerator?.notes?.value}
                      onChange={(e) => invoiceChangeHandler(e, "notes")}
                      minRows={3}
                      style={{
                        maxWidth: "23.375rem",
                        width: "100%",
                        border: "1px dashed #dddddd",
                        borderTop: "none",
                        outline: "none",
                        resize: "none",
                        paddingLeft: "15px",
                        fontSize: "12px",
                      }}
                    />
                  </div>

                  {/* desc */}
                  <div className="col-md-12 d-flex flex-column justify-content-end align-item-center  mb-1">
                    <div className="containerOne_info">
                      <input
                        type="text"
                        name="key"
                        value={invoiceGenerator?.termsAndDescription?.key}
                        style={{
                          color: `${selectedTextColor?.light}`,
                          fontSize: "14px",
                        }}
                        onChange={(e) =>
                          invoiceChangeHandler(e, "termsAndDescription")
                        }
                      />
                    </div>
                    <TextareaAutosize
                      aria-label="empty textarea"
                      id="termAndCondition"
                      type="text"
                      name="value"
                      value={invoiceGenerator?.termsAndDescription?.value}
                      onChange={(e) =>
                        invoiceChangeHandler(e, "termsAndDescription")
                      }
                      minRows={3}
                      style={{
                        maxWidth: "23.375rem",
                        width: "100%",
                        border: "1px dashed #dddddd",
                        borderTop: "none",
                        outline: "none",
                        resize: "none",
                        paddingLeft: "15px",
                        fontSize: "12px",
                      }}
                    />
                  </div>
                </div>

                <div className="col-md-6 d-flex flex-column ">
                  <div
                    className="invoiceSummary"
                    style={{
                      borderRadius: "4px",
                      backgroundColor: `${selectedColor?.dark}`,
                      color: `${selectedTextColor?.light}`,
                    }}
                  >
                    <h5>Invoice Summary</h5>
                  </div>

                  <div className="invoiceSummaryOne">
                    <div className="subtotal_left_inputOne">
                      <input
                        type="text"
                        name="key"
                        value={invoiceGenerator?.subtotal?.key}
                        style={{
                          color: `${selectedTextColor?.light}`,
                          fontSize: "14px",
                        }}
                        onChange={(e) => invoiceChangeHandler(e, "subtotal")}
                      />
                    </div>

                    <div className="subtotal_left_inputTwo">
                      <span style={{ fontSize: "12px" }}>
                        {`${countrySymbol} ${invoiceGenerator?.subtotal?.value}`}
                      </span>
                    </div>
                  </div>

                  <div className="showdisplay">
                    {!hideDiscountBox && (
                      <div className="discount_left">
                        <div className="discount_left_inputWrap">
                          <div className="discount_left_inputOne">
                            <input
                              name="key"
                              type="text"
                              value={invoiceGenerator?.discount?.key}
                              style={{
                                color: `${selectedTextColor?.light}`,
                                fontSize: "14px",
                              }}
                              onChange={(e) =>
                                invoiceChangeHandler(e, "discount")
                              }
                            />
                          </div>

                          <div className="discount_left_inputTwo">
                            <span
                              className={
                                discountState ? "taxPercent" : "symbol"
                              }
                            >
                              {discountState ? "%" : countrySymbol}
                            </span>
                            <input
                              style={{
                                marginTop: "0px",
                                textAlign: "end",
                                fontSize: "12px",
                              }}
                              onChange={(e) =>
                                invoiceChangeHandler(e, "discount")
                              }
                              min="0"
                              type="number"
                              name="value"
                              value={invoiceGenerator?.discount?.value}
                            />
                          </div>
                        </div>

                        <div>
                          <ImCross
                            className="displaynone"
                            style={{
                              cursor: "pointer",
                              marginBottom: "15px",
                              // marginRight: "15px",
                              fontSize: "10px",
                            }}
                            onClick={DiscountBox}
                          />
                        </div>
                      </div>
                    )}
                    {!hideDiscountBox && (
                      <span>
                        <BiRefresh
                          className="refresh"
                          onClick={discountAmountHandler}
                        />
                      </span>
                    )}
                  </div>

                  <div className="showdisplay">
                    {!hideTaxBox && (
                      <div className="discount_left">
                        <div className="discount_left_inputWrap">
                          <div className="discount_left_inputOne">
                            <input
                              name="key"
                              type="text"
                              value={invoiceGenerator?.tax?.key}
                              style={{
                                color: `${selectedTextColor?.light}`,
                                fontSize: "14px",
                              }}
                              onChange={(e) => invoiceChangeHandler(e, "tax")}
                            />
                          </div>
                          <div
                            className="discount_left_inputTwo"
                            style={{ position: "relative" }}
                          >
                            <span
                              className={taxState ? "taxPercent" : "symbol"}
                            >
                              {taxState ? "%" : countrySymbol}
                            </span>
                            <input
                              style={{ textAlign: "end", fontSize: "12px" }}
                              name="value"
                              type="number"
                              value={invoiceGenerator?.tax?.value}
                              onChange={(e) => invoiceChangeHandler(e, "tax")}
                              min="0"
                            />
                          </div>
                        </div>

                        <div>
                          <ImCross
                            id="cross"
                            className="displaynone"
                            style={{
                              cursor: "pointer",
                              marginBottom: "15px",
                              fontSize: "10px",
                            }}
                            onClick={TaxBox}
                          />
                          <BiPlusCircle
                            className="displaynone"
                            style={{
                              cursor: "pointer",
                              marginBottom: "10px",
                            }}
                            onClick={addNewInputField}
                          />
                        </div>
                      </div>
                    )}
                    {!hideTaxBox && (
                      <span style={{}}>
                        <BiRefresh
                          className="refresh"
                          onClick={taxAmountHandler}
                        />
                      </span>
                    )}
                  </div>

                  <div className="showdisplay">
                    {newInputList?.map((item) => {
                      return (
                        <div className="discount_left">
                          <div className="discount_left_inputWrap">
                            <div className="discount_left_inputOne">
                              <input
                                name="key"
                                type="text"
                                //   value={invoiceGenerator?.discount?.key}
                                value={item?.key}
                                style={{
                                  color: `${selectedTextColor?.light}`,
                                  fontSize: "14px",
                                }}
                                onChange={(e) =>
                                  newInputChangeHandler(e, item.id)
                                }
                              />
                            </div>
                            <div className="discount_left_inputTwo">
                              <span className="taxPercent">{"%"}</span>
                              <input
                                style={{
                                  marginTop: "0px",
                                  textAlign: "end",
                                  fontSize: "12px",
                                }}
                                // onChange={(e) =>
                                //   invoiceChangeHandler(e, "discount")
                                // }
                                onChange={(e) =>
                                  newInputChangeHandler(e, item.id)
                                }
                                // min='0'
                                type="number"
                                name="value"
                                value={item?.value}
                                //   value={invoiceGenerator?.discount?.value}
                              />
                            </div>
                          </div>
                          <div>
                            <ImCross
                              className="displaynone"
                              style={{
                                cursor: "pointer",
                                marginBottom: "15px",
                                // marginRight: "15px",
                                fontSize: "10px",
                              }}
                              onClick={() => deleteInputField(item?.id)}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>

                  <div className="showdisplay">
                    {!hideShipBox && (
                      <div className="discount_left">
                        <>
                          <div className="discount_left_inputWrap">
                            <div className="discount_left_inputOne">
                              <input
                                name="key"
                                type="text"
                                value={invoiceGenerator?.shipping?.key}
                                style={{
                                  color: `${selectedTextColor?.light}`,
                                  fontSize: "14px",
                                }}
                                onChange={(e) =>
                                  invoiceChangeHandler(e, "shipping")
                                }
                              />
                            </div>
                            <div className="discount_left_inputTwo">
                              <input
                                style={{ textAlign: "end", fontSize: "12px" }}
                                type="number"
                                name="value"
                                onChange={(e) =>
                                  invoiceChangeHandler(e, "shipping")
                                }
                                placeholder={countrySymbol}
                                value={invoiceGenerator?.shipping?.value}
                              />
                            </div>
                          </div>
                          <div>
                            <ImCross
                              id="cross"
                              className="displaynone"
                              style={{
                                cursor: "pointer",
                                marginBottom: "15px",
                                fontSize: "10px",
                                resize: "none",
                              }}
                              onClick={ShippedBox}
                            />
                          </div>
                        </>
                      </div>
                    )}
                  </div>
                  {/*  */}

                  <div className="discount_shipping">
                    {hideDiscountBox && (
                      <span
                        onClick={discountHandler}
                        style={{ cursor: "pointer", color: "blue" }}
                      >
                        + Discount
                      </span>
                    )}

                    {/*  */}
                    {hideTaxBox && (
                      <span
                        onClick={TaxHandler}
                        style={{ cursor: "pointer", color: "blue" }}
                      >
                        + Tax
                      </span>
                    )}

                    {/*  */}
                    {hideShipBox && (
                      <span
                        onClick={shippingHandler}
                        style={{ cursor: "pointer", color: "blue" }}
                      >
                        + Shipping
                      </span>
                    )}
                  </div>

                  <div className="invoiceSummaryOne">
                    <div className="subtotal_left_inputOne">
                      <input
                        type="text"
                        name="key"
                        value={invoiceGenerator?.total?.key}
                        style={{
                          color: `${selectedTextColor?.light}`,
                          fontSize: "14px",
                        }}
                        onChange={(e) => invoiceChangeHandler(e, "total")}
                      />
                    </div>
                    <div className="subtotal_left_inputTwo">
                      <span style={{ fontSize: "12px" }}>
                        {/* {`${countrySymbol} ${selector?.subtotal > 0 ? total : 0}`} */}
                        {`${countrySymbol} ${invoiceGenerator?.total?.value}`}
                      </span>
                    </div>
                  </div>

                  <div className="amountPaid">
                    <div className="amountPaid_inputOne">
                      <input
                        type="text"
                        name="key"
                        value={invoiceGenerator?.amountPaid?.key}
                        style={{
                          color: `${selectedTextColor?.light}`,
                          fontSize: "14px",
                        }}
                        min="0"
                        onChange={(e) => invoiceChangeHandler(e, "amountPaid")}
                      />
                    </div>

                    <div
                      className="amountPaid_inputTwo"
                      style={{ position: "relative" }}
                    >
                      <span
                        style={{
                          position: "absolute",
                          left: "10px",
                          top: "16px",
                          color: "#888888",
                          fontSize: "12px",
                        }}
                      >
                        {countrySymbol}
                      </span>
                      <input
                        type="number"
                        name="value"
                        value={invoiceGenerator?.amountPaid?.value}
                        min="0"
                        onChange={(e) => invoiceChangeHandler(e, "amountPaid")}
                        style={{ fontSize: "12px" }}
                      />
                    </div>
                  </div>

                  <div
                    className="invoiceSummaryOne"
                    style={{
                      backgroundColor: `${selectedColor?.dark}`,
                      borderRadius: "4px",
                      color: `${selectedTextColor?.light}`,
                    }}
                  >
                    <div className="subtotal_left_inputOne">
                      <input
                        style={{
                          backgroundColor: `${selectedColor?.dark}`,
                          borderRadius: "4px",
                          color: `${selectedTextColor?.light}`,
                          fontSize: "14px",
                        }}
                        type="text"
                        name="key"
                        value={invoiceGenerator?.balanceDue?.key}
                        min="0"
                        onChange={(e) => invoiceChangeHandler(e, "balanceDue")}
                      />
                    </div>
                    <div className="subtotal_left_inputTwo">
                      <span style={{ fontSize: "12px" }}>{`${countrySymbol} ${
                        invoiceGenerator?.balanceDue?.value
                        // selector?.subtotal > 0 ? balance : 0
                      }`}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              {/* <RightSide /> */}
              <div className="buttonContainer">
                {/* <div className="mb-3 ">
                  <button onClick={SendInvoice} className="buttonOne">
                    <img src={SendIcon} alt="" /> Send Invoice
                  </button>
                </div> */}
                {/* <div
                  className={
                    invoiceGenerator?.billTo?.value?.length === 0 ||
                    invoiceGenerator?.companyName?.length === 0
                      ? // selector?.invoiceItems?.length === 0
                        "disable"
                      : "buttonTwo"
                  }
                >
                  <button
                    onClick={
                      // saveInvoiceHandler
                      // selector?.invoiceItems?.[0]?.item?.length === 0
                      //   ? showtoast
                      //   : showModal
                      showModal
                    }
                    disabled={
                      invoiceGenerator?.billTo?.value?.length === 0 ||
                      invoiceGenerator?.companyName?.length === 0
                      // selector?.invoiceItems?.length === 0
                    }
                  >
                    <img src={DownloadIcon} alt="" />
                    Download Invoice
                  </button>
                </div> */}
                <div className="buttonTwo">
                  <button onClick={saveInvoiceHandler}>Save</button>
                </div>

                {/* <Modal
                    show={isOpen}
                    onHide={hideModal}
                    backdrop="static"
                    keyboard={false}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title className="fileFormatHeading">
                        <h4>What file format do you want ?</h4>
                      </Modal.Title>
                    </Modal.Header>
                   
                    <Modal.Footer>
                      <div className="d-flex w-100 justify-content-between align-items-center ">
                        <div
                          className="invoicePdfButton"
                          onClick={() => handlePrint()}
                        >
                          <div className="iconAndTextContainer">
                            <div className="myIconContainer">
                              <img
                                src={IconFeather}
                                alt=""
                                className="download-icon-img"
                                color="blue"
                              />
                            </div>

                            <div className="pdfTextContainer">
                              <p className="">PDF</p>
                              <p className="">(Recommended)</p>
                            </div>
                          </div>
                        </div>

                        <div
                          className="invoicePdfButton"
                          onClick={() => eInvoice()}
                        >
                          <div className="iconAndTextContainer">
                            <div className="myIconContainer">
                              <img
                                src={IconFeather}
                                alt=""
                                className="download-icon-img"
                                color="blue"
                              />
                            </div>
                            <div className="pdfTextContainer1">
                              <p className=""> E-Invoice</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal.Footer>
                  </Modal> */}

                <div className="select_container">
                  <p>Currency Symbol</p>
                  <select onChange={handleSelectChange} className="px-3">
                    <option
                      value={
                        currencyinput?.data?.currency
                          ? currencyinput?.data?.currency
                          : "$"
                      }
                      selected
                      disabled
                      hidden
                    >
                      {countrySymbol}
                      {/* {currencyCode}{" "} */}
                      {/* {currencyinput?.data?.currency
                        ? currencyinput?.data?.currency
                        : "$"} */}
                    </option>
                    {CurrencyData.currency.map((eachCountry, index, name) => {
                      return (
                        <>
                          <option
                            key={index.toString()}
                            value={eachCountry.symbol}
                          >
                            {`${eachCountry.code} ${eachCountry.symbol}`}
                          </option>
                        </>
                      );
                    })}
                  </select>
                </div>
                <hr />
                {/* <button
                    className=" history-btn border-0 "
                    onClick={historyHandler}
                  >
                    History{" "}
                    <span className="history-notification-design">
                      {invoicenumber}
                    </span>
                  </button> */}

                <ColorBox
                  colorChangeHandler={colorChangeHandler}
                  selectedColor={selectedColor}
                />
                <TextColorBox
                  textColorChangeHandler={textColorChangeHandler}
                  selectedTextColor={selectedTextColor}
                />
                <button
                  onClick={resetColor}
                  className="btn btn-primary"
                  style={{ marginLeft: "5px", marginTop: "8px" }}
                >
                  Reset Color
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer2 />
    </>
  );
};

export default EditInvoiceModal;
