import React from "react";
import "./faq.css";
const FaqSection = () => {
  return (
    <div className="faq-section-outer-container  mt-2 mb-2">
      <div className="faq-wrapper">
        <div class="accordion" id="accordionExample">
          {/* <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button accordion-question-key" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                Fastest way to get paid?
                            </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <p className='accordion-answer'>tool there is an option that you can add your company logo, select colors that represent your brand, and customize the layout and font styles to create a cohesive and visually appealing invoice.</p>
                            </div>
                        </div>
                    </div> */}
          {/* <div class="accordion-item">
            <h2 class="accordion-header">
              <button
                className="accordion-button collapsed  accordion-question-key"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="false"
                aria-controls="collapseOne"
              >
                Fastest way to get paid?
              </button>
            </h2>
            <div
              id="collapseOne"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <p className="accordion-answer">
                  tool there is an option that you can add your company logo,
                  select colors that represent your brand, and customize the
                  layout and font styles to create a cohesive and visually
                  appealing invoice.
                </p>
              </div>
            </div>
          </div> */}

          <div class="accordion-item">
            <h2 class="accordion-header">
              <button
                className="accordion-button collapsed  accordion-question-key"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                What is an online invoice generator tool?
              </button>
            </h2>
            <div
              id="collapseThree"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <p className="accordion-answer">
                  Easy Invoice is an online web application. It is very useful
                  for business and individual people to create professional
                  invoices very quickly and easily. It completely removes the
                  manual invoicing because it's time consuming. So Easy Invoice
                  provides the pre-designed templates and also provides
                  automatic functionality of adding item details, calculating
                  total, add discount and taxes and then download or mail the
                  online generated invoice in pdf format or have a printable
                  option.
                </p>
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header">
              <button
                className="accordion-button collapsed  accordion-question-key"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseFour"
              >
                How does an online invoice generator tool work?
              </button>
            </h2>
            <div
              id="collapseFour"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <p className="accordion-answer">
                  Easy Invoice is an online invoice generator tool, it has the
                  best user-friendly interface. So the process to generate an
                  online invoice is first login the application, then update the
                  relevant information like client details, product items,
                  rates, discount, taxes. After that the application processes
                  all calculations and info. Then you can download the invoice
                  in the selected invoice template.
                </p>
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header">
              <button
                className="accordion-button collapsed  accordion-question-key"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFive"
                aria-expanded="false"
                aria-controls="collapseFive"
              >
                Are online invoice generator tools secure?
              </button>
            </h2>
            <div
              id="collapseFive"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <p className="accordion-answer">
                  <a
                    className="text-decoration-none"
                    href="https://ezinvoicegenerator.com/"
                  >
                    ezinvoicegenerator.com
                  </a>{" "}
                  main aim is to focus on data security and protect or secure
                  user data. Using (HTTPS) secure connections and using data
                  encryption to protect your information.
                </p>
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header">
              <button
                className="accordion-button collapsed  accordion-question-key"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSix"
                aria-expanded="false"
                aria-controls="collapseSix"
              >
                Can I customize the invoices generated by an online invoice
                generator tool? 
              </button>
            </h2>
            <div
              id="collapseSix"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <p className="accordion-answer">
                  In Online bill makers you can easily customise your invoice.
                  In easy invoice the customisation is possible at certain
                  levels like change business logo, fonts, colour and some info
                  you have to mention.
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header">
              <button
                className="accordion-button collapsed  accordion-question-key"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSeven"
                aria-expanded="false"
                aria-controls="collapseSeven"
              >
                Are online invoice generator tools free? 
              </button>
            </h2>
            <div
              id="collapseSeven"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <p className="accordion-answer">
                  In the free version you get the limited features to create
                  bills. So to get advanced features or get customized
                  applications as per your business requirement you have to
                  apply for premium paid plans. Our developer can develop the
                  invoice as per your requirement.
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header">
              <button
                className="accordion-button collapsed  accordion-question-key"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseEight"
                aria-expanded="false"
                aria-controls="collapseEight"
              >
                Can I generate an invoice in PDF format? 
              </button>
            </h2>
            <div
              id="collapseEight"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <p className="accordion-answer">
                  Yes, In this online billing software you can download the
                  invoice in pdf format and also have the mail facility to your
                  customer.
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header">
              <button
                className="accordion-button collapsed  accordion-question-key"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseNine"
                aria-expanded="false"
                aria-controls="collapseNine"
              >
               Is it possible to generate invoices in languages other than English as well?
              </button>
            </h2>
            <div
              id="collapseNine"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <p className="accordion-answer">
                Yes, in an easy invoice you can choose multiple languages to create online bills but there are some limited languages. So if you need to add some other language, then just connect with us for customization.
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header">
              <button
                className="accordion-button collapsed  accordion-question-key"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTen"
                aria-expanded="false"
                aria-controls="collapseTen"
              >
                Is it possible to modify the currency?
              </button>
            </h2>
            <div
              id="collapseTen"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <p className="accordion-answer">
                Yes, of course you can change the currency. By Default there you get one currency but if you want to change the currency then select the option “Currency Symbol” at top-right side from there you can change.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqSection;
