import React, { useState, useEffect } from "react";
import "./ResetPassword.css";
import { toast } from "react-toastify";
import { useLocation, useParams, Link } from "react-router-dom";
import Logo from "../../assets/logo.svg";
import { Helmet } from "react-helmet";

const ResetPassword = () => {
  // const [btn, setBtn] = useState(false);
  const [password, setpassword] = useState("");
  const [cPassword, setcPassword] = useState("");
  const location = useLocation();
  // const { token } = useParams();

  // const btntext = () => {
  //   setBtn(true);
  // };
  const urlParams = new URLSearchParams(location.search);

  const token = urlParams.get("token");

  const handleSubmit = async (e) => {
    e.preventDefault();

    await fetch(
      `${process.env.REACT_APP_BASE_URL}api/auth/forget-pass?token=${token}`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },

        body: JSON.stringify({ cPassword, password, token }),
      }
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.success === true) {
          toast.success(response?.msg);
          // setBtn(false);
        } else if (response.success === false) {
          throw Error(response.message);
        }
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  return (
    <>
      <div className="cont d-flex justify-content-center align-items-center ">
        <Helmet>
          <title>Reset Password | Easy Invoice</title>
          <meta name="description" content="" />
        </Helmet>
        <div className="row">
          <div className="col-md-12 main-container">
            <div className=" text-center m-2">
              <Link className="" to="/">
                <img className="logo" src={Logo} alt="logo-img" />
              </Link>
            </div>
            <form className="resetform" onSubmit={handleSubmit}>
              <div className="row mb-4 ">
                <label
                  htmlFor="inputText"
                  className="col-md-12 col-form-label py-3 text-center"
                >
                  Reset Your Password
                </label>
                <div className="d-flex flex-column ">
                  <div className="col-sm-12 ">
                    <label>New Password</label>
                    <input
                      type="password"
                      className="form-control py-3"
                      id="form1Example2"
                      name="cPassword"
                      placeholder="cPassword"
                      value={password}
                      onChange={(e) => setpassword(e.target.value)}
                    />
                  </div>
                  <div className="col-sm-12 me-2 mt-3">
                    <label>Confirm Password</label>
                    <input
                      type="password"
                      className="form-control py-3"
                      id="form1Example2"
                      placeholder="Password"
                      name="password"
                      value={cPassword}
                      onChange={(e) => setcPassword(e.target.value)}
                    />
                  </div>

                  <div className="submitbtn mt-3">
                    <button
                      type="submit"
                      className="btn btn-primary primarybutton px-5 py-2 btn-sm"
                      // onClick={btntext}
                    >
                      Reset Password
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default ResetPassword;
