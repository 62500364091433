import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Header from "../Header/Header";
import Footer2 from "../Footer/Footer1";
import {TextareaAutosize} from "@mui/base";
import Curve from "../../assets/curve2.svg";
import "./Contact.css";
import { ToastContainer, toast } from "react-toastify";

const Contact = () => {
  const [seo, setSeo] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [response, setResponse] = useState("");

  useEffect(() => {
    const seoApi = async () => {
      await fetch(`${process.env.REACT_APP_BASE_URL}api/util/get-meta`, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: JSON.parse(localStorage.getItem("token")),
        },
      })
        .then((res) => res.json())
        .then((res) => {
          setSeo(res?.data);
        });
    };
    seoApi();
  }, []);
  async function Contactform(e) {
    e.preventDefault()
    await fetch(`${process.env.REACT_APP_BASE_URL}api/util/contact`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        // Authorization: localStorage.getItem(`token`).replaceAll('"', ""),
      },
      body: JSON.stringify({
        name: name,
        email: email,
        subject: subject,
        desc: message,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        setResponse(response);
        setName("");
        setEmail("");
        setSubject("");
        setMessage("");
        if (!response?.success) {
          throw Error(response.message);
        }
        toast.success(response?.message);
      })
      .catch((err) => {
        console.log(err);
        toast.error("message not sent successfully");
      });
  }

  // useEffect(() => {
  //   Contactform()
  // } ,[])
  return (
    <>
      <div className="flex-wrapper-dashboard">
        {seo?.map((item) => {
          if (item?.pagename === "Contact") {
            return (
              <Helmet>
                <title>Contact Us | Easy Invoice</title>
                <meta name="description" content={item?.content} />
              </Helmet>
            );
          }
        })}
        {/* <Helmet>
          <title>Dashboard | Easy Invoice</title>
          <meta name="description" content={seo[6]?.content} />
        </Helmet> */}
        <Header></Header>
        <div className="container">
          <div className="row col-sm-12" style={{ marginTop: "0rem" }}>
            <section className="invoice-guide" style={{ marginTop: "0rem" }}>
              <div className="container px-0">
                <div className="row gx-0 pt-5 mt-5">
                  <div className="col-12 col-md-10 col-lg-9 col-xl-8 mx-md-auto box-shadow mt-xxl-5 pt-xxl-5">
                    <div className="heading mt-2 mt-md-0 pt-0">
                      <img className="w-100" src={Curve} alt="..." />
                      <div className="d-flex text-center justify-content-center align-items-center pt-0">
                        <h1>
                          <span className="heading-text">
                            Contact <span style={{ color: "black" }}>Us</span>
                          </span>
                          <h4
                            className="extra-font mt-4 px-lg-4 mx-lg-5"
                            style={{ fontSize: "16px" }}
                          >
                            Please use this form to contact us and we willget
                            back to you as soon as possible
                          </h4>
                        </h1>
                      </div>
                    </div>
                    <form onSubmit={Contactform}>
                       <div className="row justify-content-center">
                      <div className="col-md-9 col-lg-8 col-xl-9 d-flex contact-form justify-content-center flex-column mt-2 px-0 mx-0">
                        <div className="lables">
                          <input
                            type="text"
                            class="form-control py-3 mt-3"
                            placeholder="Name"
                            name="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                            minLength={4}

                            
                          />
                          <input
                            type="email"
                            class="form-control py-3 mt-3"
                            placeholder="Email"
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                          />
                          <input
                            type="text"
                            class="form-control py-3 mt-3"
                            placeholder="Subject"
                            name="subject"
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                            required
                          />
                          <TextareaAutosize
                            aria-label="empty textarea"
                            placeholder="Enter your message"
                            style={{
                              resize: "none",
                              outline: "none",
                            }}
                            name = "message"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            className="message_input mt-3 form-control"
                          />
                          <div className="d-flex justify-content-center">
                            <button
                              
                              className="contact-submit-form"
                              type = "submit"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    </form>
                    
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        <Footer2></Footer2>
      </div>
    </>
  );
};
export default Contact;
