import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Signin.css";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Footer from "../Footer/Footer";
import Footer2 from "../Footer/Footer1";
import Header from "../Header/Header";
import { Helmet } from "react-helmet";
import Dashboard from '../Dashboard/Dashboard'
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff"
import {Routes , Route} from "react-router-dom"

function Signin() {
  const token = JSON.parse(localStorage.getItem("token"))
  const [getresponse, setGetresponse] = useState();
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(true);

  const [field, setField] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState("");
  let name, val;

  const store = (e) => {
    name = e.target.name;
    val = e.target.value;

    setField({ ...field, [name]: val });
    setError("");
  };

  const navigate = useNavigate();
  const save = async (e) => {
    e.preventDefault();
    loader();

    const { email, password } = field;
    console.log(email, password);

    await fetch(`${process.env.REACT_APP_BASE_URL}api/auth/signin`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },

      body: JSON.stringify({
        email,
        password,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        setGetresponse(response);

        if (!response?.success) {
          throw Error(response.message);
        }
        console.log(response, "this is response");
        localStorage.setItem("auth", JSON.stringify(response.data[0]));
        localStorage.setItem("token", JSON.stringify(response.data[0].token));
        localStorage.setItem("Admin", JSON.stringify(response.data[0].Admin));
        toast.success(response?.message);
        navigate("/");

        // console.log(response);
      })
      .catch((err) => {
        // setError(err.message);
        toast.error(err?.message);
      });
  };

  const loader = () => {
    // alert("jai")
    setLoading(true);
  };

  const change = () => {
    if (show === true) {
      setShow(false);
    } else {
      setShow(true);
    }
  };


  const [seo, setSeo] = useState([]);
  useEffect(() => {
    const seoApi = async () => {
      await fetch(`${process.env.REACT_APP_BASE_URL}api/util/get-meta`, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: JSON.parse(localStorage.getItem("token")),
        },
      })
        .then((res) => res.json())
        .then((res) => {
          setSeo(res?.data);
        });
    };
    seoApi();
  }, []);

  return (
    <>
    {!token ?  <div className="flex-wrapper-signin">
        {seo?.map((item) => {
          if (item?.pagename === "login ") {
            return (
            <Helmet>
              <title>Log In | Easy Invoice</title>
              <meta name="twitter:description" content={item?.description} />
              <meta name="twitter:title" content={item?.title} />
              <meta name="twitter:image" content={item?.image} />
              <script
                crossorigin="anonymous"
                async
              >
                {`${item?.headerScript}`}
              </script>
              <script
               
                crossorigin="anonymous"
                async
              >
                {`${item?.footerScript}`}
              </script>
            </Helmet>)
          }
        })}
        {/* <Helmet>
          <title>Log In | Easy Invoice</title>
          <meta name="description" content={seo[0]?.content} />
        </Helmet> */}
        <Header></Header>
        <div className="container signIn" style={{ marginTop: "5rem" }}>
          <div className="row">
            <div className="col-md-4 col-sm-12 offset-md-4  mb-3">
              <h1 className="text-center mb-4 mt-4">Log In</h1>

              {loading === true && !getresponse && (
                <Backdrop
                  sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
              )}

              <form onSubmit={save}>
                <div class="form-outline mb-2">
                  <input
                    type="email"
                    id="form1Example1"
                    class="form-control py-3"
                    placeholder="Email"
                    name="email"
                    onChange={store}
                    value={field.email}
                    required
                  />
                </div>
                <div
                  class="form-outline mb-2 visibleIcon "
                  style={{ position: "relative" }}
                >
                  {field?.password?.length === 0 ? null : show ? (
                    <i onClick={change}>
                      {" "}
                      <VisibilityOffIcon />{" "}
                    </i>
                  ) : (
                    <i onClick={change}>
                      {" "}
                      <VisibilityIcon />{" "}
                    </i>
                  )}
                  <input
                    type={show ? "password" : "text"}
                    id="form1Example2"
                    class="form-control py-3"
                    placeholder="Password"
                    name="password"
                    onChange={store}
                    value={field.password}
                    minLength={4}
                    required
                  />

                  {/* </input> */}
                </div>
                <span>{error && <p className="seed">{error}!</p>}</span>
                <div class="d-flex justify-content-between align-items-center mb-4">
                  <div className="forgotpassword">
                    <Link
                      to="/forget-password"
                      className="text-decoration-none"
                      style={{ color: "#888888" }}
                    >
                      Forgot password?
                    </Link>
                  </div>
                  <div className="submitbtn">
                    {/* {/ {{/ <Link to="/home"> /}  /} */}
                    <button
                      type="submit"
                      // onClick={loader}
                      class="btn btn-primary primarybutton py-2 btn-sm"
                    >
                      Log In
                    </button>{" "}
                    {/* {/ {/ </Link> /} /} */}
                  </div>
                </div>
              </form>
            </div>
            <div className="col-md-4 col-sm-12 offset-md-4 d-flex flex-column align-items-center">
              <hr className="w-100 mb-4" />
              <p>Don't have an account?</p>
              <Link to="/signup">
                <button
                  type="submit"
                  class="btn btn-primary primarybutton px-5 py-2 btn-sm"
                >
                  Sign Up
                </button>
              </Link>
            </div>
          </div>
        </div>
        <Footer2 />
      </div> :<Dashboard />}
     
     
    </>
  );
}
export default Signin;
