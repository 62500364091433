import React, { useEffect, useState } from "react";
import "./UserList.css";
import { CgToggleOff, CgToggleOn } from "react-icons/cg";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { ToastContainer, toast } from "react-toastify";
import { Link, Navigate, useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Countries from "../Signup/Countries";
import { IoCloseOutline } from "react-icons/io5";
import { Modal } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { TablePagination } from "@mui/material";
import Notfound from "../Notfound/Notfound";
import ModaluserList from "react-bootstrap/Modal";
import DeleteIcon from "../../assets/DeleteIcon.svg";

const UserList = () => {
  const Admin = JSON.parse(localStorage.getItem("auth"));

  const [buttonToggle, setButtonToggle] = useState(false);
  const [getresponse, setGetresponse] = useState();
  const [modalclose, setModalClose] = useState("");
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [input, setInput] = useState({
    name: "",
    organisationName: "",
    email: "",
    country: "India",
    password: "",
  });

  const [pagination, setPagination] = useState({
    currentpage: 0,
    rowsPerPage: 10,
  });
  const [totalCount, setTotalCount] = useState(0);

  const [error, setError] = useState("");
  const [erro, setErro] = useState("");
  const [show, setShow] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showuserList, setShowUserlist] = useState();
  const [loaderState, setLoaderState] = useState(false);
  const [updateState, setUpdateState] = useState(0);

  const navigate = useNavigate();

  const handlePageChange = (event, newPage) => {
    setPagination({
      ...pagination,
      currentpage: newPage,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setPagination({
      ...pagination,
      rowsPerPage: parseInt(event.target.value, 10),
      currentpage: 0,
    });
  };

  const buttonToggler = async (id) => {
    let updateValue = "";

    showuserList.map((val) => {
      if (id === val?._id) {
        console.log(val?.status, "status");
        if (val?.status === true) {
          return (updateValue = false);
        } else {
          return (updateValue = true);
        }
      }
    });

    await fetch(
      `${process.env.REACT_APP_BASE_URL}api/admin/edit-user?_id=${id}`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: JSON.parse(localStorage.getItem("token")),
        },
        body: JSON.stringify({
          status: updateValue,
        }),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        const refreshedUserList = showuserList.map((value) => {
          if (id === value?._id) {
            return { ...value, status: updateValue };
          } else {
            return value;
          }
        });

        res?.success === true && setShowUserlist(refreshedUserList);
      });

    // setButtonToggle(!buttonToggle)
  };

  let name1, val;
  const save = (e) => {
    // e.preventDefault();
    name1 = e.target.name;
    val = e.target.value;
    setErro(null);
    setError(null);
    setInput({ ...input, [name1]: val });
  };

  const change = () => {
    if (show === true) {
      setShow(false);
    } else {
      setShow(true);
    }
  };

  useEffect(() => {
    const userListApi = async () => {
      await fetch(
        `${process.env.REACT_APP_BASE_URL}api/admin/user?limit=${pagination?.rowsPerPage}&offset=${pagination?.currentpage}`,
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      )
        .then((res) => res.json())
        .then((res) => {
          res && setLoaderState(true);
          setShowUserlist(res?.data?.[0]);
          setTotalCount(res?.totalUsers ? res?.totalUsers : 0);
        });
    };

    userListApi();
  }, [pagination, updateState]);

  const datasave = async (e) => {
    e.preventDefault();
    handleClose();
    // loader();
    const { name, organisationName, email, country, password } = input;

    await fetch(`${process.env.REACT_APP_BASE_URL}api/auth/signup`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },

      body: JSON.stringify({
        name,
        organisationName,
        email,
        country,
        password,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        // loader();
        setGetresponse(response);

        if (response?.success === true) {
          toast.success("New User Added Successfully");
          setUpdateState(updateState + 1);
        } else {
          throw Error(response.message);
        }
        // setMessage(response);
      })
      .catch((error) => {
        toast.error(error?.message);
      });
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleShow = (id) => {
    setShowModal(true);
  };

  const deleteUserApi = async (id) => {
    const confirm = window.confirm(
      "Are you sure you want to delete this User?"
    );
    confirm && setDeleteLoader(true);

    confirm &&
      (await fetch(`${process.env.REACT_APP_BASE_URL}api/admin/delete-user`, {
        method: "DELETE",
        headers: {
          "content-type": "application/json",
        },

        body: JSON.stringify({
          userId: id,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          // loader();
          // setGetresponse(response);

          if (response?.status === true) {
            toast.success(response?.message);
            setUpdateState(updateState + 1);
            setDeleteLoader(false);
          } else {
            throw Error(response.message);
          }
          // setMessage(response);
        })
        .catch((error) => {
          toast.error(error?.message);
          setDeleteLoader(false);
        }));
  };

  return (
    <>
      {Admin.Admin === 1 ? (
        <>
          {!loaderState && (
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}

          {deleteLoader && (
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}

          {/* <div className="flex-wrapper-user-list"> */}
          <Header></Header>

          <section className="user-list" style={{ marginTop: "6rem" }}>
            <div className="container mt-4 ">
              <div className="row gx-0">
                <div className="col-12 mt-4">
                  <h1 className="text-center">
                    <span className="heading-text1">User</span>{" "}
                    <span className="heading-text2">List</span>
                  </h1>

                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleShow}
                    style={{ background: "#2155CD" }}
                  >
                    Add New User
                  </button>
                  {/* <button type="button" class="btn btn-primary ms-2 px-4" style={{ background: "#2155CD" }}>SEO</button> */}

                  <div className="">
                    <ModaluserList
                      show={showModal}
                      onHide={handleClose}
                      scrollable={true}
                    >
                      <div className="modal-content modal-radius">
                        <div className="text-center mt-4 close-popup">
                          <h2 className="modal-send-document-text">New User</h2>
                          {/* <IoCloseOutline
                          className="close-popupbtn"
                          data-bs-dismiss="modal"
                        ></IoCloseOutline> */}

                          <p className="modal-green-text ">
                            Create a new user account by filling these details.
                          </p>
                        </div>

                        <div className="modal-body">
                          <form onSubmit={datasave}>
                            <div class="form-outline mb-3 password_input_class">
                              {/* {loading === true && !getresponse && (
                      <Backdrop
                        sx={{
                          color: "#fff",
                          zIndex: (theme) => theme.zIndex.drawer + 1,
                        }}
                        open
                      >
                        <CircularProgress color="inherit" />
                      </Backdrop>
                    )} */}

                              <input
                                type="text"
                                id="form2Example1"
                                className="form-control input-box "
                                placeholder="Name"
                                name="name"
                                value={input.name}
                                onChange={save}
                                minLength={4}
                                maxLength={20}
                                required
                              />
                            </div>
                            <div className="form-outline mb-3">
                              <input
                                type="text"
                                id="form2Example1"
                                className="form-control input-box "
                                placeholder="Company Name"
                                name="organisationName"
                                value={input.organisationName}
                                onChange={save}
                                minLength={4}
                                maxLength={20}
                                required
                              />
                            </div>
                            {/* <span>{error && <p className="apple">{error.length > 0 ? error : ""}</p>}</span> */}

                            <div className="form-outline mb-3">
                              <input
                                type="email"
                                id="form2Example1"
                                className="form-control input-box "
                                placeholder="Email address"
                                name="email"
                                value={input.email}
                                onChange={save}
                                required
                              />
                            </div>
                            {/* <span>{erro && <p className="apple">{erro.length > 0 ? erro : null}</p>}</span> */}

                            <div
                              className="form-outline mb-3 password_input_class"
                              // style={{ position: "relative" }}
                            >
                              {/* {input?.password?.length === 0 ? null : show ? (
                              <i
                                onClick={change}
                                className="eye_icon"
                                // style={{ position: "absolute" }}
                              >
                                {" "}
                                <VisibilityOffIcon />{" "}
                              </i>
                            ) : (
                              <i
                                onClick={change}
                                className="eye_icon"
                                // style={{ position: "absolute" }}
                              >
                                {" "}
                                <VisibilityIcon />{" "}
                              </i>
                            )} */}

                              <input
                                type={show ? "password" : "text"}
                                id="form3Example4cg"
                                className="form-control input-box "
                                placeholder="Password"
                                name="password"
                                value={input.password}
                                onChange={save}
                                minLength={3}
                                required
                              />
                            </div>

                            <div className="form-outline mb-3">
                              <label
                                className="ms-2 position-absolute"
                                style={{ marginTop: "-0.12rem!important" }}
                              >
                                <span
                                  className="h6 small bg-white text-muted position-absolute "
                                  style={{
                                    marginTop: "-8px",
                                    marginLeft: "5px",
                                    fontSize: "165",
                                  }}
                                >
                                  Country
                                </span>
                              </label>

                              <select
                                className="form-select form-control input-box mb-3 select-tag-input "
                                aria-label=".form-select-lg example"
                                name="country"
                                value={input.country}
                                onChange={save}
                              >
                                <Countries></Countries>
                              </select>
                            </div>

                            <div class="form-check d-flex  mb-3">
                              <input
                                className="form-check-input me-2 check-box-color"
                                type="checkbox"
                                value=""
                                id="form2Example3cg"
                                required
                              />

                              <label
                                className="form-check-label text-muted input-text"
                                for="form2Example3g"
                              >
                                I agree to the Terms of Service
                              </label>
                            </div>

                            <div className="row gx-0">
                              <div className="d-grid gap-2 col-sm-12 mb-3">
                                <button
                                  className="btn btn-primary button-effect"
                                  type="submit"
                                >
                                  Create User
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </ModaluserList>
                  </div>

                  <table class="table table-striped mt-4">
                    <thead
                      style={{
                        borderBottom: "solid 2px #dee2e6",
                        borderTop: "solid 2px #dee2e6",
                      }}
                    >
                      <tr>
                        {/* <th scope="col">#</th> */}
                        <th scope="col">Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Status</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {showuserList
                        ? showuserList?.map((val, index) => {
                            return (
                              <tr>
                                {/* <th scope="row">{index+1}</th> */}
                                <td>{val?.name}</td>
                                <td>{val?.email}</td>
                                <td>
                                  {" "}
                                  <span className="d-flex">
                                    {" "}
                                    Active
                                    <span class="form-check form-switch ms-1">
                                      <input
                                        class="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        id="flexSwitchCheckDefault"
                                        checked={val?.status}
                                        onClick={() =>
                                          buttonToggler(val?._id, index)
                                        }
                                      />
                                    </span>
                                  </span>
                                </td>
                                <td onClick={() => deleteUserApi(val?.id)}>
                                  {" "}
                                  <img
                                    src={DeleteIcon}
                                    alt="delete"
                                    style={{ cursor: "pointer" }}
                                  ></img>{" "}
                                </td>
                              </tr>
                            );
                          })
                        : loaderState && (
                            <tr className="text-center">
                              <td>{""}</td>

                              <td> No User Found</td>
                              <td>{""}</td>
                              <td>{""}</td>
                            </tr>
                          )}
                    </tbody>
                  </table>
                  <TablePagination
                    component="div"
                    onPageChange={handlePageChange}
                    page={pagination?.currentpage}
                    count={totalCount}
                    rowsPerPage={pagination?.rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <Notfound />
      )}
      {/* </div> */}
    </>
  );
};
export default UserList;
