import {
  ADD_INVOICE_DATA,
  EDIT_INVOICE_DATA,
  DELETE_INVOICE_DATA,
  DOWNLOAD_PDF_ACTION,
  HANDLE_INVOICE_DATA,
  HANDLE_INVOICE_CALCULATION,
  UPLOAD_LOGO,
  TABLE_DATA,
  HANDLE_IMAGE_DATA,
  HANDLE_INVOICE,
  NEW_INVOICE,
  SETTING_LOGO_UPLOAD,
  DELETE_EDIT_INVOICE_MODAL_DATA,
  EDIT_INVOICE_MODAL_DATA,
	POP_UP_ACTION,
	ADD_EDIT_INVOICE_MODAL_DATA
} from "../actionType/actionType";
import { ToastContainer, toast } from "react-toastify";
import InvoiceDownload from "../../API/Manager/InvoiceDownload";
import Logo from "../../API/Manager/LogoApi";

export function logoUpload(logo) {
	localStorage.removeItem("document_type");
	return async (dispatch) => {
		const data = await Logo(logo);
		dispatch(logoUploadConfirm(data[0]));
	};
}

export function settingLogoUrl(logo) {
	return async (dispatch) => {
		const data = await Logo(logo);
		// localStorage.setItem( "settingLogo", JSON.stringify( data[0]?.path))
		dispatch(settingLogoUpload(data[0]));
	};
}

export function settingLogoUpload(data) {
	return {
		type: SETTING_LOGO_UPLOAD,
		payload: data,
	};
}

export function invoiceDataAction(data) {
	const params = { ...data };
	return async (dispatch) => {
		const data = await InvoiceDownload(params);
		console.log(data[0].status, "newdaresponse");
		if (data[0]?.status === 401) {
			// toast.error("invoice id already in use");
			dispatch(popAction(true));
		} else {
			dispatch(downloadpPdfAction(data));
			localStorage.getItem("token");
		}
	};
}

// export function handleInvoice(data){
//   // debugger
//   return {
//     type: HANDLE_INVOICE,
//     payload:data,
//   }
// }

export const imageData = (data) => {
	return {
		type: HANDLE_IMAGE_DATA,
		payload: data,
	};
};

export const handleInvoiceCalculation = (data) => {
	return {
		type: HANDLE_INVOICE_CALCULATION,
		payload: data,
	};
};

export const handleInvoiceData = (datas) => {
  // debugger
  // console.log(name, value, "name value");
  // console.log(datas,"123456789");
  return {
    type: HANDLE_INVOICE_DATA,
    payload: datas,
  };
};

export const invoiceData = (data) => {
	// console.log(data, "sfdsfdsfsdf++++");
	return {
		type: ADD_INVOICE_DATA,
		payload: data,
	};
};

export const addEditInvoiceData = (data) => {
	return {
		type: ADD_EDIT_INVOICE_MODAL_DATA,
		payload: data,
	};
};

export const deleteInvoiceData = (data) => {
	// console.log(data, "datadeleid diidiid");
	return {
		type: DELETE_INVOICE_DATA,
		payload: data,
	};
};

export const deleteInvoiceModalData = (data) => {
  // console.log(data, "datadeleid diidiid");
  return {
    type: DELETE_EDIT_INVOICE_MODAL_DATA,
    payload: data,
  };
};

export const editInvoiceData = (name, value, index, datas) => {
	return {
		type: EDIT_INVOICE_DATA,
		payload: { name, value, index, datas },
	};
};

export const editInvoiceModalData = (nameEdit, valueEdit, indexs, datas) => {
  return {
    type: EDIT_INVOICE_MODAL_DATA,
    payload: { nameEdit, valueEdit, indexs, datas },
  };
};

export const downloadpPdfAction = (data) => {
	return {
		type: DOWNLOAD_PDF_ACTION,
		payload: data[0]?.message,
	};
};

export const popAction = (data) => {
	return {
		type: POP_UP_ACTION,
		payload: data,
	};
};

export function logoUploadConfirm(data) {
	return {
		type: UPLOAD_LOGO,
		payload: data,
	};
}

export function tableData(names, values, amounts) {
	return {
		type: TABLE_DATA,
		payload: { names, values, amounts },
	};
}

export function newInvoiceTableData(data) {
	return {
		type: NEW_INVOICE,
		payload: data,
	};
}
