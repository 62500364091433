import React from "react";
import "./ColorBox.css";

const ColorBox = ({ colorChangeHandler, selectedColor }) => {

  const arr = [
    "black",
    "#CAD8EB",
    "#9BEDF3",
    "#4285f4",
    "#CAF1DE",
    "#fbbc05",
    "#ea4335",
    "#34a853",
  ];

  return (
    <>
      <div className="color_main_container">
        <p>Theme Color</p>
        <div className="colorBoxContainer">
       
        {arr.map((col) => {
          console.log(col, "ajtyus")
          return (
              <div className="colorContainer">
                <div
                  className="colorBox"
                  onClick={() => colorChangeHandler(col)}
                  style={{
                    backgroundColor: col,
                 
                  }}
                ></div>
              </div>
          );
        })}
        <input type="color" style={{marginLeft:"3px", marginRight:"2px" , marginTop:"2px", marginBottom:"2px"}} value={selectedColor?.light} onChange={(e)=> colorChangeHandler(e.target.value)} /> 
        </div>
    
      </div>


      {/* <div className="color_main_container">
        <p>Color</p>
        <div className="colorBoxContainer">
          <div className="colorContainer">
            <div
              className="colorBox"
              style={{
                backgroundColor: "black",
              }}
              onClick={() => colorChangeHandler("black", "#f4efef")}
            ></div>
          </div>
          <div className="colorContainer">
            <div
              className="colorBox"
              style={{
                backgroundColor: "#CAD8EB",
              }}
              onClick={() => colorChangeHandler("#CAD8EB", "#CAD8EB")}
            ></div>
          </div>
          <div className="colorContainer">
            <div
              className="colorBox"
              style={{
                backgroundColor: "#CAF1DE",
              }}
              onClick={() => colorChangeHandler("#CAF1DE", "#CAF1DE")}
            ></div>
          </div>
          <div className="colorContainer">
            <div
              className="colorBox"
              style={{
                backgroundColor: "#9BEDF3",
              }}
              onClick={() => colorChangeHandler("#9BEDF3", "#9BEDF3")}
            ></div>
          </div>
          <div className="colorContainer">
            <div
              className="colorBox"
              style={{
                backgroundColor: "#ea4335",
              }}
              onClick={() => colorChangeHandler("#ea4335", "#ea4335")}
            ></div>
          </div>
          <div className="colorContainer">
            <div
              className="colorBox"
              style={{
                backgroundColor: "#4285f4",
              }}
              onClick={() => colorChangeHandler("#4285f4", "#4285f4")}
            ></div>
          </div>
          <div className="colorContainer">
            <div
              className="colorBox"
              style={{
                backgroundColor: "#34a853",
              }}
              onClick={() => colorChangeHandler("#34a853", "#34a853")}
            ></div>
          </div>
          <div className="colorContainer">
            <div
              className="colorBox"
              style={{
                backgroundColor: "#fbbc05",
              }}
              onClick={() => colorChangeHandler("#fbbc05", "#fbbc05")}
            ></div>
          </div>
          <div>
            <div>
            <input type="color" value={selectedColor?.light} onChange={(e)=> colorChangeHandler(e.target.value)} /> 
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default ColorBox;
