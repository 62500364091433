import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Setting.css";
import { ToastContainer, toast } from "react-toastify";
import { ImCross } from "react-icons/im";
// import { MdEdit } from "react-icons/md";
import LogoImgSelect from "../../assets/logo image.svg";
import Close from "../../assets/cross.png";
import CurrencyData from "../../InvoiceDownloader/CurrencyData";
import Header from "../Header/Header";
import { Helmet } from "react-helmet";
import Footer2 from "../Footer/Footer1";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Countries from "../Signup/Countries";
import { useDispatch, useSelector } from "react-redux";
import { logoUpload, settingLogoUrl } from "../../InvoiceRedux/action/action";
import { flexbox } from "@mui/system";

function Setting() {
  // const selector = useSelector((state) => state?.invoice?.invoiceList);
  // const dispatch = useDispatch();
  const [logo, setLogo] = useState("");
  // const [file, setFile] = useState("");
  const [submit, setSubmit] = useState(false);
  const [fields, setfields] = useState({
    organisationDesc: "",
    organisationName: "",
    panNumber: "",
    supEmail: "",
    phoneNumber: "",
    website: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    taxId: "",
    currency: "",
    country: "",
    postalCode: "",
    // logo: "",
  });

  // useEffect(() => {
  //   if (selector?.logoLink?.length > 0) {
  //     setLogo(selector?.logoLink);
  //   }
  // }, [selector?.logoLink]);

  // const formDataGenerator = (data) => {
  //   // debugger
  //   let formDataValue = new FormData();
  //   let keys = Object.keys(data);
  //   keys.map((key) => {
  //     formDataValue.append(key, data[key]);
  //   });

  //   return formDataValue;
  // };

  const [submitResponse, setSubmitResponse] = useState(false);
  const [input, setInput] = useState();
  const [inputdata, setInputData] = useState();
  const [loading , setLoading] = useState(false)
  // const [cancelled,setCancelled] = useState(false);
  // const [pathName ,setPathName] = useState

  useEffect(() => {
    const datasave = async () => {
      setLoading(true)
      await fetch(`${process.env.REACT_APP_BASE_URL}api/auth/user-profile`, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: localStorage.getItem(`token`).replaceAll('"', ""),
        },
      })
        .then((response) => response.json())
        .then((response) => {
          if (!response?.success) {
            throw Error(response.message);
          }

          if(response?.success === true){
            setLoading(false)
            setInput(response);
            setInputData(response?.data);
            setLogo(response?.data?.logo);
          }
        })
        .catch((error) => {
          setLoading(false)
        });
    };
    datasave();
  }, [submitResponse]);

  const handleSubmit = async (e) => {
setLoading(true)
    e.preventDefault();
    const {
      organisationDesc,
      organisationName,
      panNumber,
      supEmai,
      phoneNumber,
      website,
      addressLine1,
      addressLine2,
      city,
      state,
      taxId,
      country,
      currency,
      postalCode,
      // logo,
    } = fields;

    await fetch(
      `${process.env.REACT_APP_BASE_URL}api/auth/setting-update-profile`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: localStorage.getItem(`token`).replaceAll('"', ""),
        },

        body: JSON.stringify({
          organisationDesc,
          organisationName,
          panNumber,
          supEmai,
          phoneNumber,
          website,
          addressLine1,
          addressLine2,
          city,
          state,
          taxId,
          country,
          currency,
          postalCode,
          logo,
        }),
      }
    )
      .then((response) => response.json())
      .then((response) => {

        if (!response?.success) {
          throw Error(response.message);
        }

        if (response?.success === true) {
          setLoading(false)
          setSubmit(false)
          setSubmitResponse(!submitResponse);
          toast.success(response?.message);
        }
        // localStorage.setItem('auth', JSON.stringify(response.data[0]))
        // navigate("/invoice-guide");
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.message);
        setLoading(false)
      });
  };
  const onChange = (setmodal) => {
    setSubmit(true);
    const value = setmodal.target.value;
    const name = setmodal.target.name;
    setfields({ ...fields, ...inputdata, [name]: value });
    setInputData({ ...input, ...inputdata, [name]: value });
  };

  // const onChangeLogoUrl =async (e) => {
  // e.preventDefault();
  // setSubmit(true);
  // const value = e.target.value;
  // const name = e.target.name;
  // setfields({ ...fields, ...inputdata, [name]: value });
  // setInputData({ ...input, ...inputdata, [name]: value });
  // let selectedFile = e.target.files[0];
  // setLogo(selectedFile);
  //  settingLogoApi(formDataGenerator({ logo: selectedFile }))
  // console.log(xyz(), "opopop");

  // dispatch(logoUpload(formDataGenerator({ logo: selectedFile })));

  // dispatch(settingLogoUrl(formDataGenerator({ logo: selectedFile })));
  // };

  // const settingLogoApi  = async(logo) =>{
  //   // debugger
  //   await fetch(
  //     `${process.env.REACT_APP_BASE_URL}api/util/upload`,
  //     {
  //       method: "POST",
  //       headers: {
  //         "content-type": "application/json",
  //         Authorization: localStorage.getItem(`token`).replaceAll('"', ""),
  //       },

  //       body: {
  //         logo:JSON.stringify(logo),
  //       }
  //     }
  //   )
  //     .then((response) => response.json())
  //     .then((response) => {
  //       console.log(response, "setting Logo url");
  //       // localStorage.setItem('auth', JSON.stringify(response.data[0]))
  //       // navigate("/invoice-guide");
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }

  const [seo, setSeo] = useState([]);
  useEffect(() => {
    const seoApi = async () => {
      await fetch(`${process.env.REACT_APP_BASE_URL}api/util/get-meta`, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: JSON.parse(localStorage.getItem("token")),
        },
      })
        .then((res) => res.json())
        .then((res) => {
          setSeo(res?.data);
        });
    };
    seoApi();
  }, []);

  const settingLogoChangeHandler = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setfields({ ...fields, ...inputdata, [name]: value });
    setInputData({ ...input, ...inputdata, [name]: value });
    let logo = e.target.files[0];
    const data = new FormData();
    data.append("file", logo);

    await fetch(`${process.env.REACT_APP_BASE_URL}api/util/upload`, {
      method: "POST",
      body: data,
      body:data !== undefined ? data : ''
    })
      .then((res) => res.json())
      .then((res) => {
        if (res?.success === true) {
          if(res?.path == "undefined"){
            setLogo("")
         
          }else{
            setSubmit(true);
            // setfields({ ...fields, logo: res?.path });
            setLogo(res?.path);
          }
         
        }
        // if(res?.path === "undefined"){
        //   setLogo("")
        //   console.log(logo)
        // }
      });
  };

  
  const removeimage = (e) => {
    // setfields({ logo: "" });
    const { name, value } = e.target;
    setLogo("");
    setSubmit(true);
    setfields({ ...fields, ...inputdata, [name]: value });
    setInputData({ ...input, ...inputdata, [name]: value });
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };
  const handleDrop = async (e) => {
    e.preventDefault();
    let selectedFile = e?.dataTransfer?.files[0];
    const { name, value } = e.target;
    setfields({ ...fields, ...inputdata, [name]: value });
    setInputData({ ...input, ...inputdata, [name]: value });
    let fileType = ["image/jpeg", "image/jpg", "image/png", "image/gif"];

    if (fileType.includes(selectedFile?.type)) {
      const data = new FormData();
      data.append("file", selectedFile);
      await fetch(`${process.env.REACT_APP_BASE_URL}api/util/upload`, {
        method: "POST",
        body: data,
      })
        .then((res) => res.json())
        .then((res) => {
          if (res?.success === true) {
            setSubmit(true);
            // setfields({ ...fields, logo: res?.path });
            setLogo(res?.path);
          }
        });
      // setFile(URL.createObjectURL(e?.dataTransfer.files[0]));
      // dispatch(logoUpload(formDataGenerator({ logo: selectedFile })));
      // debugger;
      // setUpdateLocalStorage(updateLocalStorage + 1);
    } else {
      alert(
        "The image you uploaded is not in a file type we accept. Please use a JPEG, PNG, or GIF"
      );
    }
  };

  return (
    <>
      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      <div className="flex-wrapper-myaccount">
        {seo?.map((item) => {
          if (item?.pagename === "Settings") {
            return (
              <Helmet>
                {/* {item?.content} */}
                <title>Settings | Easy Invoice</title>
                <meta name="twitter:description" content={item?.description} />
                <meta name="twitter:title" content={item?.title} />
                <meta name="twitter:image" content={item?.image} />
                <script crossorigin="anonymous" async>
                  {`${item?.headerScript}`}
                </script>
                <script crossorigin="anonymous" async>
                  {`${item?.footerScript}`}
                </script>
              </Helmet>
            );
          }
        })}

        {/* <Helmet>
          <title>Settings | Easy Invoice</title>
              <meta name="description" content={seo[5]?.content} />
        </Helmet> */}
        <Header></Header>
        <div className="container mt-4">
          <div className="row" style={{ marginTop: "4rem" }}>
            <div className="col-md-8 col-sm-12 offset-md-2 mb-3">
              <h1 className="text-center mb-2 header"> Settings</h1>
              <p className="text-center mb-4 headertext">
                Edit your organization's settings
              </p>
              <p className="org-name home mt-5 mb-0 pb-0">
                Contact Information
              </p>
              <form className="row g-3 mt-2 form" onSubmit={handleSubmit}>
                <div className="col-lg-6 ">
                  <h6 htmlFor="validationCustom01" className="info-lables mb-0">
                    ORGANIZATION NAME
                  </h6>
                  <input
                  disabled
                    type="text"
                    className="form-control py-2"
                    id="validationCustom01"
                    // placeholder="Oraganization Name"
                    // onChange={onChange}
                    value={input?.data?.organisationName}
                    name="organisationName"
                    minLength={2}
                  />
                </div>
                <div className="col-lg-6">
                  <h6 htmlFor="text" className="info-lables mb-0">
                    ORGANIZATION DESCRIPTION
                  </h6>
                  <input
                    type="text"
                    className="form-control py-2"
                    id="inputAddress"
                    // placeholder="Organization Description"
                    value={inputdata?.organisationDesc}
                    onChange={onChange}
                    name="organisationDesc"
                  />
                </div>
                <div className="col-lg-6 mt-3">
                  <h6
                    htmlFor="inputEmail4"
                    className="info-lables form-label mb-0"
                  >
                    EMAIL
                  </h6>
                  <input
                   disabled
                    type="email"
                    className="form-control py-2"
                    id="inputEmail4"
                    // placeholder="Enter Email"
                    onChange={onChange}
                    name="supEmai"
                    value={input?.data?.email}
                  />
                  <h6
                    htmlFor="text"
                    className="info-lables form-label mb-0 mt-3"
                  >
                    PHONE NUMBER
                  </h6>
                  <input
                    type="number"
                    className="form-control py-2"
                    id="inputNumber"
                    // placeholder="Phone Number"
                    onChange={onChange}
                    name="phoneNumber"
                    minLength={10}
                    // required
                    value={inputdata?.phoneNumber}
                  />
                </div>
                <div className="col-lg-6 logo-section">
                  <label
                    htmlFor="text"
                    className="info-lables form-label mt-0 pt-0 mb-0 pb-0"
                  >
                    LOGO
                  </label>
                  <div
                    className="logo_container mt-0 pt-0 w-full"
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                  >
                    <img src={LogoImgSelect} alt="logoImgSelect" />

                    <label style={{width:"100%", height:"7.5rem" ,display:"flex", alignItems:"center"}} >
                      <input
                        accept="image/*"
                        type="file"
                        name=" dragLogo"
                        // value={invoiceGenerator?.dragLogo}
                        onChange={(e) => settingLogoChangeHandler(e)}
                      />
                      {logo ? (
                        <div style={{width:"auto" ,minHeight:"6rem"  ,maxHeight:"7rem" , }}>
                          <img
                            src={logo}
                            // crossorigin="anonymous"
                            alt="logo"
                            style={{ height: "100%",objectFit:"cover", borderRadius:"5px" }}
                          />
                        </div>
                      ) : (
                        <div>
                          <p>
                            Drag your logo here or<u> select a file</u>
                          </p>
                        </div>
                      )}
                    </label>
                    {logo ? (
                      <button className="imagecrossbtn" onClick={removeimage}>
                        <ImCross />
                      </button>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 mt-4 pt-1 mt-lg-3">
                  <h6 htmlFor="text" className="info-lables form-label mb-0 ">
                    WEBSITE
                  </h6>
                  <input
                    type="text"
                    className="form-control py-2"
                    id="inputName"
                    // placeholder="Website"
                    onChange={onChange}
                    name="website"
                    value={inputdata?.website}
                    minLength={6}
                  />
                </div>
                {/* <div className="col-12 mt-3 ">
                  <label htmlFor="text" className="info-lables form-label mb-0">
                    LOGO
                  </label>
                  <div className="setting_logoURl">
                    <label>
                      <input
                        type="file"
                        accept="image/*"
                        className="form-control py-2"
                        id="inputNumber"
                        onChange={(e) => onChangeLogoUrl(e)}
                        // value={file}
                        // onChange={onChange}
                        // name="logo"
                      />
                      {logo?.length > 0 ? (
                        <div
                          className="logo-url-link"
                          style={{
                            // border: "2px solid red",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "8px",
                            flexWrap: "wrap",
                            // fontSize: "10.5px",
                            height: "100%",
                          }}
                        >
                          <span
                            style={{
                              wordBreak: "break-word",
                              wordWrap: "break-word",
                            }}
                          >
                            {logo}
                          </span>
                        </div>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "8px",
                            flexWrap: "wrap",
                            fontSize: "14px",
                            height: "100%",
                            color: "#888888",
                          }}
                        >
                          <span>select your Logo</span>
                        </div>
                      )}
                    </label>
                  </div>
                </div> */}

                <p className="org-name home mt-4"> Address</p>
                <div className="col-md-6 mt-3">
                  <h6 htmlFor="inputAddress" className="info-lables mb-0">
                    ADDRESS LINE 1
                  </h6>
                  <input
                    type="text"
                    className="form-control py-2"
                    id="inputAddress"
                    // placeholder="Address"
                    onChange={onChange}
                    name="addressLine1"
                    value={inputdata?.addressLine1}
                  />
                </div>
                <div className="col-md-6 mt-3">
                  <h6 htmlFor="inputAddress" className="info-lables mb-0">
                    ADDRESS LINE 2
                  </h6>
                  <input
                    type="text"
                    className="form-control py-2"
                    id="inputNumber"
                    // placeholder="Address"
                    onChange={onChange}
                    name="addressLine2"
                    value={inputdata?.addressLine2}
                  />
                </div>
                <div className="col-md-6 mt-3">
                  <h6
                    htmlFor="inputCity"
                    className="info-lables form-label mb-0"
                  >
                    City
                  </h6>
                  <input
                    type="text"
                    className="form-control py-2"
                    id="inputCity"
                    // placeholder="City"
                    onChange={onChange}
                    name="city"
                    value={inputdata?.city}
                  />
                </div>
                <div className="col-md-6 mt-3">
                  <h6
                    htmlFor="inputState"
                    className="info-lables form-label mb-0 "
                  >
                    STATE
                  </h6>
                  <input
                    type="text"
                    className="form-control py-2"
                    id="inputState"
                    // placeholder="State"
                    onChange={onChange}
                    name="state"
                    value={inputdata?.state}
                  />
                </div>
                <div className="col-md-6 mt-3">
                  <h6
                    htmlFor="inputZipCode"
                    className="info-lables form-label mb-0 "
                  >
                    ZIP CODE
                  </h6>
                  <input
                    type="text"
                    className="form-control py-2"
                    id="inputZipCode"
                    // placeholder="Zip Code"
                    onChange={onChange}
                    name="postalCode"
                    value={inputdata?.postalCode}
                  />
                </div>
                <div className="col-md-6 mt-3">
                  <h6 htmlFor="text" className="info-lables form-label mb-0">
                    COUNTRY
                  </h6>

                  <select
                    class="form-select form-control py-2"
                    aria-label="Default select example"
                    name="country"
                    value={inputdata?.country}
                    onChange={onChange}
                  >
                    {/* <option selected hidden>Select</option> */}
                    <Countries></Countries>
                  </select>
                </div>

                <p className="org-name home mt-4"> Business Details</p>
                <div className="col-md-6 mt-3">
                  <h6
                    htmlFor="inputZipCode"
                    className="info-lables form-label mb-0 "
                  >
                    VAT/GST NUMBER
                  </h6>
                  <input
                    type="text"
                    className="form-control py-2"
                    id="inputZipCode"
                    // placeholder="WAIT & GST Number"
                    name="taxId"
                    value={inputdata?.taxId}
                    onChange={onChange}
                  />
                </div>
                <div className="col-md-6 mt-3">
                  <h6 htmlFor="text" className="info-lables form-label mb-0">
                    BASE CURRENCY
                  </h6>
                  <select
                    class="form-select form-control py-2"
                    aria-label="Default select example"
                    name="currency"
                    onChange={onChange}
                    value={inputdata?.currency}
                  >
                    {/* {CurrencyData.currency.map((val) => {
                      {
                        return <option value={val.code}>{val.code}</option>;
                      }
                    })} */}
                    {CurrencyData.currency.map((eachCountry, index, name) => {
                      return (
                        <>
                          <option
                            key={index.toString()}
                            value={eachCountry.symbol}
                          >
                            {`${eachCountry.code} ${eachCountry.symbol}`}
                          </option>
                        </>
                      );
                    })}
                  </select>
                </div>
                <div className="d-flex flex-row-reverse">
                  <button
                    className="btn btn-primary mt-4 col-sm-2 "
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop"
                    type="submit"
                    disabled={!submit}
                  >
                    Save
                  </button>
                </div>
              </form>
              {/* <div className="text-end">
                <div
                  className="modal fade"
                  id="staticBackdrop"
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  tabIndex="-1"
                  aria-labelledby="staticBackdropLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content rounded-4">
                      <div className="heading text-center d-flex justify-content-between align-items-center my-4">
                        <span className="text-center flop">
                          {inputdata?.organisationName}
                      
                        </span>{" "}
                        <span
                          className="flo d-flex justify-content-center align-items-center"
                          data-bs-dismiss="modal"
                        >
                          
                          <img
                            src={Close}
                            alt="close"
                            style={{ height: "24px", width: "24px" }}
                          />
                        </span>
                      </div>
                     
                      <div className="modal-body border d-flex flex-column text-start">
                        <div className="org-description">
                          <label htmlFor="">
                            <small>ORGANIZATION DETAILS</small>
                          </label>
                          <div>
                            <span style={{ fontSize: "12px" }}>
                              {fields?.organisationDesc}
                            </span>
                          </div>
                        </div>
                        <div className="org-description">
                          <label htmlFor="">
                            <small>CONTACT INFORMATION</small>
                          </label>
                          <div className="d-flex flex-column">
                            <span style={{ fontSize: "12px" }}>
                              {fields?.phoneNumber}
                            </span>
                         
                            <span style={{ fontSize: "12px" }}>
                              {fields?.website}
                            </span>
                          </div>
                        </div>
                        <div className="org-description">
                          <label htmlFor="">
                            <small>ADDRESS DETAILS</small>
                          </label>
                          <div className="d-flex flex-column">
                            <span style={{ fontSize: "12px" }}>
                              {fields?.addressLine1}
                            </span>
                            <span style={{ fontSize: "12px" }}>
                              {fields?.addressLine2}
                            </span>
                            <span style={{ fontSize: "12px" }}>
                              {fields?.city} {fields?.postalCode}
                            </span>
                            <span style={{ fontSize: "12px" }}>
                              {fields?.state}
                            </span>
                            <span style={{ fontSize: "12px" }}>
                              {fields?.country}
                            </span>
                          </div>
                        </div>
                        <div className="org-description">
                          <label htmlFor="">Business Details</label>
                          <div className="d-flex flex-column">
                            <span style={{ fontSize: "12px" }}>
                              {fields?.panNumber}
                            </span>
                            <span style={{ fontSize: "12px" }}>
                              {fields?.taxId}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="Modal-close-btn px-5"
                          data-bs-dismiss="modal"
                        >
                          Edit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <Footer2></Footer2>
      </div>
    </>
  );
}

export default Setting;
