export const ADD_INVOICE_DATA = "ADD_INVOICE_DATA";
export const DELETE_INVOICE_DATA = "DELETE_INVOICE_DATA";
export const EDIT_INVOICE_DATA = "EDIT_INVOICE_DATA";
export const DOWNLOAD_PDF_ACTION = "DOWNLOAD_PDF_ACTION";
export const HANDLE_INVOICE_DATA = "HANDLE_INVOICE_DATA";
export const HANDLE_INVOICE_CALCULATION = "HANDLE_INVOICE_CALCULATION";
export const UPLOAD_LOGO = "UPLOAD_LOGO";
export const HANDLE_INVOICE = "HANDLE_INVOICE";
export const HANDLE_IMAGE_DATA = "HANDLE_IMAGE_DATA";
export const SETTING_LOGO_UPLOAD = "SETTING_LOGO_UPLOAD";
export const TABLE_DATA = "TABLE_DATA";

// signIn

export const SIGN_IN = "SIGN_IN";
export const SIGN_UP = "SIGN_UP";
export const LOGOUT_ACTION = "LOGOUT_ACTION";

// History page
export const NEW_INVOICE = "NEW_INVOICE";
export const POP_UP_ACTION = "POP_UP_ACTION";
// Edit page 
export const DELETE_EDIT_INVOICE_MODAL_DATA ="DELETE_EDIT_INVOICE_MODAL_DATA"
export const EDIT_INVOICE_MODAL_DATA = "EDIT_INVOICE_MODAL_DATA"
export const ADD_EDIT_INVOICE_MODAL_DATA ="ADD_EDIT_INVOICE_MODAL_DATA"
